import {
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Modal,
  Spinner,
  Select,
  useToast,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Image,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Button,
  Input,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import EmptyState from "components/EmptyState";
import { amountConverter, capitalize, mapBadgeColor } from "utils/strings";
import BlurOverlay from "layouts/components/BlurOverlay";
import { useUpdateLoanStatusMutation } from "store/services/business";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loan_status } from "utils/strings";
import { useState } from "react";
import { EditIcon, } from "@chakra-ui/icons";
import MenuBtn from "../../../image/Button.png"
import { ReactComponent as ViewIcon } from "icons/view.svg";

const LoanListTable = ({ loanDetails, setFetch }) => {
  const loans = loanDetails?.loans ?? [];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [updateLoanStatus, { isLoading: updating }] =
    useUpdateLoanStatusMutation();
  const toast = useToast();
  const [activeId, setActive] = useState(null);

  const handleSubmit = async (values) => {
    await updateLoanStatus({
      ...values,
      loan_id: activeId,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setFetch(false);
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      status: "",
      // end_date: "",
      // interest_rate: ""
    },
    validationSchema: LoanSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const EditLoanSchema = Yup.object().shape({
    end_date: Yup.date().required("End Date is required"),
    interest_rate: Yup.number()
      .required("Interest Rate is required")
      .min(0.1, "Interest Rate must be at least 0.1%"),
  });


  const editFormik = useFormik({
    initialValues: {
      end_date: "",
      interest_rate: "",
    },
    validationSchema: EditLoanSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  // const handleEditSubmit = async (values) => {
  //   await updateLoanStatus({
  //     ...values,
  //     loan_id: activeId,
  //   })
  //     .unwrap()
  //     .then((data) => {
  //       toast({
  //         title: data?.message,
  //         description: data?.detail,
  //         status: "success",
  //         duration: 9000,
  //         isClosable: true,
  //         position: "top",
  //       });
  //       setFetch(false);
  //       onEditClose();
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       toast({
  //         title: "Error Occurred",
  //         description: error?.data?.message,
  //         status: "error",
  //         duration: 9000,
  //         isClosable: true,
  //         position: "top",
  //       });
  //     });
  // };




  return (
    <Stack mt="24px">
      {loans?.length > 0 ? (
        <Table variant="simple" bgColor={Colors.white} mt="2rem">
          <Thead className="dashboard__table__header">
            <Tr>
              <Th className="!text-12">Loan ID</Th>
              <Th className="!text-12">Loan Amount</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th>Plan</Th>
              <Th>Loan Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody className="dashboard__table__body">
            {loans?.map((_) => {
              return (
                <Tr key={_?.id} className="!text-12">
                  <Td>
                    {" "}
                    <h6 className="!text-12">{_?.id?.substr(1, 4)}</h6>
                  </Td>
                  <Td>
                    <h6 className="!text-12">
                      ₦{amountConverter(_?.loan_amount)}
                    </h6>
                  </Td>
                  <Td>
                    <h6 className="!text-12">{_?.start_date}</h6>
                  </Td>
                  <Td>
                    <h6 className="!text-12">{_?.end_date}</h6>
                  </Td>
                  <Td>
                    <h6 className="!text-12">{capitalize(_?.plan)}</h6>
                  </Td>
                  <Td>
                    {/* <Badge variant="solid" colorScheme={mapBadge(_?.status)}>
                      {capitalize(_?.status)}
                    </Badge> */}

                    <div className={`badge-container ${mapBadgeColor(_?.status)} `}>
                      <span></span>
                      <p>{capitalize(_?.status)}</p>
                    </div>
                  </Td>

                  <Td>
                    {/* <Menu>
                      <MenuButton
                        as={IconButton}
                        variant="outline"
                        onClick={() => setActive(_?.id)}
                        icon={<Image src={MenuBtn} boxSize="20px" border="none" />}
                      />
                      <MenuList>
                        <MenuItem icon={<EditIcon />} onClick={onEditOpen}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={onOpen}>Update</MenuItem>
                      </MenuList>
                    </Menu> */}
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<Image src={MenuBtn} />}
                        variant="unstyled"
                        onClick={() => setActive(_?.id)}
                      />
                      <MenuList minW="150px" p="2">
                        <MenuItem fontSize="sm" onClick={onEditOpen}>
                          Edit
                        </MenuItem>
                        <MenuItem fontSize="sm" onClick={onOpen}>
                          Update
                        </MenuItem>
                      </MenuList>
                    </Menu>





                    {/* Update Modal */}
                    <Modal isOpen={isOpen} onClose={onClose} isCentered={false}>
                      <BlurOverlay />
                      <ModalContent w="100%">
                        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
                          <div className="flex flex-col py-22 px-30">
                            <h3 className="font-inter font-medium text-black text-2xl">
                              Update Loan Status
                            </h3>
                          </div>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody padding="0">
                          <Stack padding="20px">
                            <form
                              onSubmit={formik.handleSubmit}
                              className={`form w-full mt-24 ${updating ? "disabled" : ""}`}
                            >
                              <div className="form-row-full auth-form-group !mb-16">
                                <label htmlFor="status">Status</label>
                                <Select
                                  placeholder="Select option"
                                  variant="filled"
                                  bg="#EDEDED"
                                  {...formik.getFieldProps("status")}
                                >
                                  {loan_status?.map((status) => (
                                    <option key={status?.label} value={status?.value}>
                                      {status?.label}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                              <button type="submit" className="btn btn-primary w-full">
                                {updating ? <Spinner color={Colors.white} /> : "Update"}
                              </button>
                            </form>
                          </Stack>
                        </ModalBody>
                      </ModalContent>
                    </Modal>

                    {/* Edit Modal */}
                    {/* Edit Modal */}
                    <Modal isOpen={isEditOpen} onClose={onEditClose}>
                      <BlurOverlay />
                      <ModalContent w="100%">
                        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
                          <div className="flex flex-col py-22 px-30">
                            <h3 className="font-inter font-medium text-black text-2xl">
                              Edit Loan Details
                            </h3>
                          </div>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody padding="0">
                          <Stack padding="20px">
                            <form onSubmit={editFormik.handleSubmit} className={`form w-full mt-24 ${updating ? "disabled" : ""}`}>
                              <FormControl id="end_date" isRequired mb="4">
                                <FormLabel>End Date</FormLabel>
                                <Input
                                  type="date"
                                  {...editFormik.getFieldProps("end_date")}
                                  isInvalid={editFormik.touched.end_date && !!editFormik.errors.end_date}
                                />
                                {editFormik.touched.end_date && editFormik.errors.end_date ? (
                                  <div className="error">{editFormik.errors.end_date}</div>
                                ) : null}
                              </FormControl>

                              <FormControl id="interest_rate" isRequired mb="4">
                                <FormLabel>Interest Rate (%)</FormLabel>
                                <NumberInput min={0.1} step={0.1}>
                                  <NumberInputField
                                    {...editFormik.getFieldProps("interest_rate")}
                                    isInvalid={editFormik.touched.interest_rate && !!editFormik.errors.interest_rate}
                                  />
                                </NumberInput>
                                {editFormik.touched.interest_rate && editFormik.errors.interest_rate ? (
                                  <div className="error">{editFormik.errors.interest_rate}</div>
                                ) : null}
                              </FormControl>

                              <button type="submit" className="btn btn-primary w-full">
                                {updating ? <Spinner color={Colors.white} /> : "Save Changes"}
                              </button>
                            </form>
                          </Stack>
                        </ModalBody>
                      </ModalContent>
                    </Modal>

                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <EmptyState />
      )}
    </Stack>
  );
};

export default LoanListTable;

const LoanSchema = Yup.object().shape({
  status: Yup.string().required("Loan Status is required"),
});
