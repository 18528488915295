import {
  Stack,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { useGetAllBankAccountQuery } from "store/services/business";
import EmptyState from "components/EmptyState";
import { capitalize } from "utils/strings";
import { Colors } from "utils/constant.data";

const BankAccount = () => {
  const { data, isLoading } = useGetAllBankAccountQuery();

  return (
    <Stack>
      <section className="dashboard__accounts__container">
        <div className="flex items-center justify-between  px-24 py-34 bg-white rounded-t-6">
          <h2 className="font-inter font-medium text-dark400 text-xl">
            Bank accounts
          </h2>
          {/* <button className="btn btn-primary !px-20">Download CSV</button> */}
        </div>
        <Stack>
          {isLoading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : data?.results?.length > 0 ? (
            <div className="px-24 pb-24 bg-white ">
              <TableContainer>
                <Table variant="simple" bgColor={Colors.white}>
                  <Thead className="dashboard__table__header">
                    <Tr>
                      <Th>Bank</Th>
                      <Th>Account Number</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="dashboard__table__body">
                    {data?.results?.map((_) => {
                      return (
                        <Tr key={_?.id}>
                          <Td>
                            <h6>{capitalize(_?.bank)}</h6>
                          </Td>
                          <Td>
                            {" "}
                            <h6>{_?.account_number}</h6>
                          </Td>
                          <Td>
                            <h6>{_?.is_active ? "Active" : "inactive"} </h6>
                          </Td>

                          <Td>
                            <button className="cursor-pointer text-primary font-bold">
                              Update
                            </button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <section className="flex items-center justify-center min-h-300 bg-white">
              <EmptyState title="No Credit Checks" />
            </section>
          )}
        </Stack>
      </section>
    </Stack>
  );
};

export default BankAccount;
