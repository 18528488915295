/** @format */

import { Path } from "utils/constant.data";
import LoginPage from "pages/auth/Login";
import RegisterPage from "pages/auth/Register";
import ConfirmAccountPage from "pages/auth/ConfirmAccount";
// import SetPackage from "pages/auth/Package";
import ForgotPasswordPage from "pages/auth/ForgotPassword";
import ConfirmResetPage from "pages/auth/ConfirmReset";
import DashboardOverview from "pages/dashbaord";
import DashboardOnboarding from "pages/dashbaord/onboarding";
import DashboardCreditCheck from "pages/dashbaord/credit";
import DashboardSettings from "pages/dashbaord/settings";
import DashboardLoanBeneficiary from "pages/dashbaord/loans";
import LoanDetails from "pages/dashbaord/loans/LoanDetails";
import LoanList from "pages/dashbaord/loans/LoanList";
import DashboardTransactions from "pages/dashbaord/transactions";
import DashboardAuditLogs from "pages/dashbaord/audit";
import DashboardBlacklist from "pages/dashbaord/blacklist";
import DashboardSweep from "pages/dashbaord/sweep";
import DashboardWallet from "pages/dashbaord/wallet";
import DashboardWalletSuccess from "pages/dashbaord/wallet/Success";
import DashboardTeam from "pages/dashbaord/team";
import DashboardCreditCheckDetails from "pages/dashbaord/credit/details";

const PublicRoutes = [
  {
    path: Path.LOGIN,
    element: <LoginPage />,
    title: Path.LOGIN,
  },
  {
    path: Path.REGISTER,
    element: <RegisterPage />,
    title: Path.REGISTER,
  },

  {
    path: Path.CONFIRM_ACCOUNT,
    element: <ConfirmAccountPage />,
    title: Path.CONFIRM_ACCOUNT,
  },
  {
    path: Path.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
    title: Path.FORGOT_PASSWORD,
  },
  {
    path: Path.RESET_PASSWORD,
    element: <ConfirmResetPage />,
    title: Path.RESET_PASSWORD,
  },
  // {
  //   path: Path.SET_PACKAGE,
  //   element: <SetPackage />,
  //   title: Path.SET_PACKAGE,
  // },
];

const AuthUserRoutes = [
  {
    path: Path.DASHBOARD_OVERVIEW,
    element: <DashboardOverview />,
    title: Path.DASHBOARD_OVERVIEW,
  },
  {
    path: Path.DASHBOARD_ONBOARDING,
    element: <DashboardOnboarding />,
    title: Path.DASHBOARD_ONBOARDING,
  },
  {
    path: Path.DASHBOARD_CREDIT_CHECK,
    element: <DashboardCreditCheck />,
    title: Path.DASHBOARD_CREDIT_CHECK,
  },
  {
    path: Path.DASHBOARD_CREDIT_CHECK_DETAILS,
    element: <DashboardCreditCheckDetails />,
    title: Path.DASHBOARD_CREDIT_CHECK_DETAILS,
  },
  {
    path: Path.DASHBOARD_SETTINGS,
    element: <DashboardSettings />,
    title: Path.DASHBOARD_SETTINGS,
  },
  {
    path: Path.DASHBOARD_LOAN_BENEFICIARY,
    element: <DashboardLoanBeneficiary />,
    title: Path.DASHBOARD_LOAN_BENEFICIARY,
  },
  {
    path: Path.DASHBOARD_LOAN_DETAILS,
    element: <LoanDetails />,
    title: Path.DASHBOARD_LOAN_DETAILS,
  },
  {
    path: Path.DASHBOARD_LOAN_LIST,
    element: <LoanList />,
    title: Path.DASHBOARD_LOAN_LIST,
  },
  {
    path: Path.DASHBOARD_TRANSACTIONS,
    element: <DashboardTransactions />,
    title: Path.DASHBOARD_TRANSACTIONS,
  },
  {
    path: Path.DASHBOARD_AUDIT_LOGS,
    element: <DashboardAuditLogs />,
    title: Path.DASHBOARD_AUDIT_LOGS,
  },
  {
    path: Path.DASHBOARD_BLACKLIST,
    element: <DashboardBlacklist />,
    title: Path.DASHBOARD_BLACKLIST,
  },
  {
    path: Path.DASHBOARD_SWEEP,
    element: <DashboardSweep />,
    title: Path.DASHBOARD_SWEEP,
  },
  {
    path: Path.DASHBOARD_WALLET,
    element: <DashboardWallet />,
    title: Path.DASHBOARD_WALLET,
  },
  {
    path: Path.DASHBOARD_WALLET_SUCCESS,
    element: <DashboardWalletSuccess />,
    title: Path.DASHBOARD_WALLET_SUCCESS,
  },
  {
    path: Path.DASHBOARD_TEAM_MANAGEMENT,
    element: <DashboardTeam />,
    title: Path.DASHBOARD_TEAM_MANAGEMENT,
  },
];

export { PublicRoutes, AuthUserRoutes };
