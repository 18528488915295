import DashboardLayout from "layouts/dashboard";
import { useState } from "react";
import { ReactComponent as BuildingIcon } from "icons/building.svg";
import { ReactComponent as BankIcon } from "icons/bank.svg";
// import { ReactComponent as LoanIcon } from "icons/loanRecipient.svg";
import { ReactComponent as SweepIcon } from "icons/sweep.svg";
import { ReactComponent as CaretDown } from "icons/down.svg";
import { ReactComponent as SDK } from "icons/sdk.svg";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";

const DashboardOnboarding = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const steps = [
    {
      step: 1,
      title: "Organization Info",
      text: "We will send a code to your registered phone number to verify your account.",
      btnText: "Continue",
      icon: <BuildingIcon />,
    },
    {
      step: 2,
      title: "Organization Bank Account",
      text: "We will send a code to your registered phone number to verify your account.",
      btnText: "Continue",
      icon: <BankIcon />,
    },
    // {
    //   step: 3,
    //   title: "Upload Loan Recipients",
    //   text: "We will send a code to your registered phone number to verify your account.",
    //   btnText: "Upload",
    //   icon: <LoanIcon />,
    // },
    {
      step: 3,
      title: "Auto Sweeping functionality",
      text: "We will send a code to your registered phone number to verify your account.",
      btnText: "Enable",
      icon: <SweepIcon />,
    },
    {
      step: 4,
      title: "SDK",
      text: "Effortlessly integrate our SDK to streamline your consumer lending operations and automate repayment processes.",
      btnText: "Get Started Now",
      icon: <SDK />,
    },
  ];

  return (
    <DashboardLayout>
      <section className="flex flex-col justify-start items-center py-78 px-100">
        <section className="flex flex-col justify-start items-center py-44">
          <h2 className="text-2xl font-bold font-graphik">
            Welcome onboard to CLARS
          </h2>
          <p className="text-base font-normal font-inter">
            Kindly complete the registration by creating relevant profile
          </p>
        </section>

        <section className="flex flex-col bg-white w-[90%]">
          <div className="flex items-center justify-between p-32">
            <h4 className="text-18 font-bold font-graphik ">
              {step === 0 ? "Get Started" : steps[step - 1]?.title}
            </h4>
            <button className="flex items-center">
              <span className="text-dark200 mr-16 ">
                {3 - step} Step{3 - step > 1 ? "s" : ""} left
              </span>
              <CaretDown />
            </button>
          </div>
          <div className="bg-purple100 w-[70%] h-1"></div>
          <div className="p-32">
            {step === 0 &&
              steps?.map((_) => {
                return (
                  <div className="bg-grey300 flex items-center justify-between p-20 mb-24 ">
                    <figure className="basis-[8%] h-70 bg-white flex items-center justify-center mr32">
                      {_?.icon}
                    </figure>
                    <div className="flex items-center  basis-[88%]">
                      <div className="basis-[70%] flex-shrink-0 flex-grow-0">
                        <h4 className="text-18 font-bold font-graphik">
                          {_?.title}
                        </h4>

                        <p className="text-14 font-normal font-graphik">
                          {_?.text}
                        </p>
                      </div>
                      <div className="basis-[30%] flex-shrink-0 flex-grow-0 ">
                        <button
                          onClick={() => {
                            if (_?.step === 3) {
                              navigate(Path.DASHBOARD_SWEEP);
                            } else if (_?.step === 4) {
                              window.location.href = "https://docs.useclars.com/";
                            } else {
                              setStep(_?.step);
                            }
                          }}
                          className="btn btn-primary w-173 ml-32"
                        >
                          {_?.btnText}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}

            {step === 1 && <StepOne setStep={setStep} />}
            {step === 2 && <StepTwo setStep={setStep} />}
            {step === 9 && <StepThree setStep={setStep} />}
            {step === 3 && <StepFour setStep={setStep} />}
          </div>
        </section>
      </section>
    </DashboardLayout>
  );
};

export default DashboardOnboarding;
