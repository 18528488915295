import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import LoanCard from "components/LoanCard";
import GraphCard from "components/GraphCard";
import TableCard from "components/TableCard";
import { useGetDashboardStatsQuery } from "store/services/business";
import { Spinner } from "@chakra-ui/react";
import { amountConverter, capitalize } from "utils/strings";
import { format } from "date-fns";

const DashboardOverview = () => {
  const { data, isLoading } = useGetDashboardStatsQuery();

  const details = data?.data ?? null;

  const loanData = [
    {
      title: "Total Loan Portfolio",
      number: `₦${amountConverter(details?.total_loan_portfolio)}`,
      percent: "0",
      showFilter: false,
    },
    {
      title: "Total Recovered Loans",
      number: `₦${amountConverter(details?.sum_recovered_loans)}`,
      percent: "0",
      showFilter: false,
    },
    {
      title: "Totl Amount Swept",
      number: `₦${amountConverter(details?.sum_amount_swept)}`,
      percent: "0",
      showFilter: false,
    },
    {
      title: "Loan Beneficiary Count",
      number: details?.loan_beneficiary_count,
      percent: "0",
      showFilter: false,
    },
  ];

  const pending = details?.last_10_pending_loans ?? [];
  const approved = details?.last_10_aproved_loans ?? [];
  const graphData = details?.chart_data ?? null;

  // id: "2c1f0f9f-aa0c-4cb8-a181-e35314541f9d
  // loan_amount: "4500.00"
  // loan_purpose: "House"
  // loan_term: "1 month"
  // plan: "monthly"
  // start_date: "2024-07-28"
  // end_date: "2024-08-31"
  // status: "pending"

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">
          Dashboard{" "}
        </h2>
        {isLoading ? (
          <section className="my-16">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </section>
        ) : (
          <section className="grid grid-cols-4 gap-10 my-16">
            {loanData?.map((_) => {
              return <LoanCard data={_} key={_?.title} />;
            })}
          </section>
        )}

        <section className="grid grid-cols-3 gap-16 my-16">
          <GraphCard
            graphData={graphData?.outstanding_loans}
            type="bar"
            title="Outstanding Loans"
          />
          <GraphCard
            graphData={graphData?.overdue_loans}
            title="Overdue Loans"
          />
          <GraphCard graphData={graphData?.repayment_rate} title="Recovery" />

        </section>

        <section className="grid grid-cols-2 gap-16 my-16">
          <TableCard title="Recently Approved Loans" showFilter={false}>
            {approved?.length > 0 ? (
              <section className="flex flex-col min-h-300">
                <table className="table">
                  <tbody>
                    <tr className="head" role="row">
                      <td role="cell">
                        <h6 className="mb-0">Benefeciary</h6>
                      </td>
                      <td role="cell">
                        <h6> Loan Amount</h6>
                      </td>
                      <td role="cell">
                        <h6>Start Date</h6>
                      </td>
                      <td role="cell">
                        <h6>End Date</h6>
                      </td>
                      <td role="cell">
                        <h6>Loan Status</h6>
                      </td>
                      <td role="cell">
                        <h6>Loan Term</h6>
                      </td>
                    </tr>

                    {approved?.map((_) => {
                      return (
                        <tr key={_?.id} role="row">
                          <td role="cell">
                            {" "}
                            <div className="flex items-center ">
                              <h6 className="mb-0 font-600">
                                {capitalize(_?.beneficiary?.substring(0, 5))}
                              </h6>
                            </div>
                          </td>
                          <td role="cell">
                            <h5>₦{amountConverter(_?.loan_amount)}</h5>
                          </td>
                          <td role="cell">
                            <h5 className="text-dark">
                              {format(_?.start_date, "MMM d, yyyy")}
                            </h5>
                          </td>
                          <td role="cell">
                            <h5 className="text-dark">
                              {format(_?.end_date, "MMM d, yyyy")}
                            </h5>
                          </td>
                          <td role="cell">
                            <div className="badge-container success">
                              <span></span>
                              <p>Active</p>
                            </div>
                          </td>
                          <td role="cell">
                            <h5 className="text-dark">
                              {capitalize(_?.loan_term)}
                            </h5>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            ) : (
              <section className="flex items-center justify-center min-h-300">
                <EmptyState />
              </section>
            )}
          </TableCard>
          <TableCard title="Pending Approvals" showFilter={false}>
            {pending?.length > 0 ? (
              <section className="flex flex-col min-h-300">
                <table className="table">
                  <tbody>
                    <tr className="head" role="row">
                      <td role="cell">
                        <h6 className="mb-0">Benefeciary</h6>
                      </td>
                      <td role="cell">
                        <h6> Loan Amount</h6>
                      </td>
                      <td role="cell">
                        <h6>Start Date</h6>
                      </td>
                      <td role="cell">
                        <h6>Loan Status</h6>
                      </td>
                      <td role="cell">
                        <h6>Loan Term</h6>
                      </td>
                    </tr>

                    {pending?.map((_) => {
                      return (
                        <tr key={_?.id} role="row">
                          <td role="cell">
                            {" "}
                            <div className="flex items-center ">
                              <h6 className="mb-0 font-600">
                                {capitalize(_?.beneficiary?.substring(0, 5))}
                              </h6>
                            </div>
                          </td>
                          <td role="cell">
                            <h5>₦{amountConverter(_?.loan_amount)}</h5>
                          </td>
                          <td role="cell">
                            <h5 className="text-dark">
                              {format(_?.start_date, "MMM d, yyyy")}
                            </h5>
                          </td>
                          <td role="cell">
                            <div className="badge-container success">
                              <span></span>
                              <p>{capitalize(_?.status)}</p>
                            </div>
                          </td>
                          <td role="cell">
                            <h5 className="text-dark">
                              {capitalize(_?.loan_term)}
                            </h5>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            ) : (
              <section className="flex items-center justify-center min-h-300">
                <EmptyState />
              </section>
            )}
          </TableCard>
        </section>
      </section>
    </DashboardLayout>
  );
};

export default DashboardOverview;
