import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { capitalize } from "utils/strings";

const BlacklistDetails = ({
  isBlacklistOpen,
  onBlacklistClose,
  data,
}) => {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );


  const details = [
    {
      label: "Email",
      value: capitalize(data?.email),
    },
    {
      label: "Account Number",
      value: data?.account_number,
    },
    {
      label: "BVN",
      value: data?.bvn,
    },
    {
      label: "Description",
      value: capitalize(data?.description),
    },
    {
      label: "Drivers License",
      value: data?.drivers_license,
    },
    {
      label: "International Passport",
      value: data?.international_passport,
    },
    {
      label: "NIN",
      value: data?.nin,
    },
    {
      label: "Social Handle",
      value: capitalize(data?.social_handle),
    },
    {
      label: "Category",
      value: capitalize(data?.category),
    },
    {
      label: "Country",
      value: data?.country,
    },
    {
      label: "Phone Number",
      value: data?.phone,
    },
  ];

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isBlacklistOpen}
      onClose={onBlacklistClose}
      isCentered={true}
    >
      <OverlayOne />
      <ModalContent w="100%" maxW="1282px">
        <ModalCloseButton />
        <ModalBody padding="0">
          {data && (
            <Stack padding="0">
              <Stack
                padding="20px 34px 30px 34px"
                borderBottom="1px solid #E0E0E0"
              >
                <h2 className="font-inter font-semibold text-h6">
                  Blacklist’s Detail
                </h2>
              </Stack>

              <Stack direction="row" padding="48px" gap="45px">
                <Stack
                  w="100%"
                  border={`1px solid ${Colors.grey500}`}
                  bg={Colors.white}
                  padding="28px"
                >
                  <Stack>
                    {details?.map((_) => {
                      return (
                        <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                          <h6 className="font-inter font-normal text-sm text-grey950">
                            {_?.label}
                          </h6>

                          <h6 className="font-inter font-normal text-sm text-[#181830]">
                            {_?.value}
                          </h6>
                        </div>
                      );
                    })}

                    <button className="btn btn-primary mt-32">Update</button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BlacklistDetails;
