import {
  Stack,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import BlurOverlay from "layouts/components/BlurOverlay";
import { useState } from "react";
import { ReactComponent as FileIcon } from "icons/file.svg";
import { useUpdateMerchantProfileMutation } from "store/services/business";

const UploadLogo = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const [base, setBase] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"];

    if (file) {
      const fileType = file.type;
      const fileSize = file.size;

      if (fileSize > 2 * 1024 * 1024) {
        toast({
          title: "Error Occurred",
          description: "❌ File size exceeds the 2MB limit!",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setSelectedFile(null);
      } else if (allowedFileTypes.includes(fileType)) {
        setSelectedFile(file);

        const _handleReaderLoaded = (readerEvt) => {
          let binaryString = readerEvt.target.result;
          setBase(btoa(binaryString));
        };

        const reader = new FileReader();
        reader.onload = _handleReaderLoaded;
        reader.readAsBinaryString(file);
      } else {
        toast({
          title: "Error Occurred",
          description: "❌ Only JPEG and PNG files are allowed!",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setSelectedFile(null);
      }
    }
  };

  const [updateMerchantProfile, { isLoading: updating }] =
    useUpdateMerchantProfileMutation();

  const handleSubmit = async (values) => {
    await updateMerchantProfile({
      company_logo: base,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={false}
    >
      <BlurOverlay />
      <ModalContent w="100%">
        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
          <div className="flex flex-col py-22 px-30">
            <h3 className="font-inter font-medium text-black text-2xl">
              Update Company Logo
            </h3>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0">
          <Stack padding="20px">
            <div className="flex flex-col mb-24">
              <div className="form-row-full flex my-8">
                <div className="upload__area">
                  <input
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    onChange={handleFileChange}
                  ></input>

                  <div
                    className={`pointer-events-none absolute  w-full h-full left-0 top-0 flex flex-col items-center justify-center ${
                      updating ? "disabled" : ""
                    } `}
                  >
                    {selectedFile ? (
                      <figure className="w-[80%] h-[80%] ">
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="preview"
                          className="w-full h-full object-contain"
                        />
                      </figure>
                    ) : (
                      <>
                        <FileIcon />
                        <p className="text-12 text-center font-inter w-[50%]">
                          <span className="text-blue100 underline cursor-pointer font-bold">
                            Click to upload
                          </span>{" "}
                          or drag and drop PNG,JPEG Max. 2MB
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row-half flex my-8">
                <button
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className={`btn btn-primary w-full ${
                    selectedFile && base ? "" : "btn-disabled"
                  }`}
                >
                  {updating ? <Spinner /> : "Upload Logo"}
                </button>
              </div>
            </div>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadLogo;
