import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { ReactComponent as CircleIcon } from "icons/tick-circle.svg";
import { Colors } from "utils/constant.data";
import { amountConverter, capitalize } from "utils/strings";
import { format } from "date-fns";

const DetailsModal = ({
  isBeneficiaryOpen,
  onBeneficiaryOpen,
  onBeneficiaryClose,
  transaction,
}) => {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );


  const details = [
    {
      label: "Amount",
      value: `₦${amountConverter(transaction?.amount)}`,
    },
    {
      label: "Transaction Date",
      value: `${format(transaction?.created_at, "yyyy-MM-dd")}`,
    },
    {
      label: "Transaction Type",
      value: `${capitalize(transaction?.type)}`,
    },
    {
      label: "Transaction Source",
      value: `${capitalize(transaction?.source)}`,
    },
    {
      label: "Customer Name",
      value: ` ${capitalize(transaction?.beneficiary?.first_name)} 
                ${capitalize(transaction?.beneficiary?.last_name)}`,
    },
    {
      label: "Bank Name",
      value: `${capitalize(transaction?.bank_name)}`,
    },
    {
      label: "Account ID/User ID",
      value: `${capitalize(transaction?.id)}`,
    },
    {
      label: "Payment Reference",
      value: `${capitalize(transaction?.payment_reference)}`,
    },
  ];

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isBeneficiaryOpen}
      onClose={onBeneficiaryClose}
      isCentered={true}
    >
      <OverlayOne />
      <ModalContent w="100%" maxW="1282px">
        <ModalCloseButton />
        <ModalBody padding="0">
          <Stack padding="0">
            <Stack
              padding="20px 34px 30px 34px"
              borderBottom="1px solid #E0E0E0"
            >
              <button
                onClick={() => onBeneficiaryClose()}
                className="w-250 flex items-center  text-grey700"
              >
                {" "}
                <BackIcon />
                <span className="ml-8">Back to Overview</span>
              </button>
              <h2 className="font-inter font-semibold text-h6">
                {capitalize(transaction?.beneficiary?.first_name)}{" "}
                {capitalize(transaction?.beneficiary?.last_name)}’s Profile
              </h2>
            </Stack>

            <Stack direction="row" padding="48px" gap="45px">
              <Stack w="35%" padding="28px" gap="0">
                <Stack
                  bg="#FAFAFA"
                  padding="28px"
                  borderBottom="1px solid #DFE3E8"
                >
                  <h5 className="mb-16 font-semibold text-lg">
                    Beneficiary Detail
                  </h5>
                  <Stack>
                    <h6 className="text-purple200 text-lg font-medium">
                      {capitalize(transaction?.beneficiary?.first_name)}{" "}
                      {capitalize(transaction?.beneficiary?.last_name)}
                    </h6>
                    {/* <p className="text-grey70 text-lg">
                      Oluwaseun.adesanya@gmail.com
                    </p> */}
                    {/* <p className="text-grey700 text-lg">070898367282</p> */}
                  </Stack>
                </Stack>
                <Stack bg="#FAFAFA" padding="28px">
                  <div className="flex flex-row justify-between items-center pt-4 pb-12">
                    <h6 className="font-inter font-normal text-sm text-grey700">
                      BVN
                    </h6>
                    <h6 className="font-inter font-normal text-lg text-[#007ACE]">
                      {transaction?.beneficiary?.bvn}
                    </h6>
                  </div>
                  <div className="flex flex-row justify-between items-center pt-4 pb-12 text-">
                    <h6 className="font-inter font-normal text-sm text-grey700">
                      Total Transaction
                    </h6>
                    <h6 className="font-inter font-normal text-lg text-[#181830]">
                      {`₦${amountConverter(transaction?.amount)}`}
                    </h6>
                  </div>
                  <div className="flex flex-row justify-between items-center pt-4 pb-12">
                    <h6 className="font-inter font-normal text-sm text-grey700">
                      Wallet Balance
                    </h6>
                    <h6 className="font-inter font-normal text-lg text-[#181830]">
                      ₦{amountConverter(0)}
                    </h6>
                  </div>
                </Stack>
              </Stack>
              <Stack
                w="65%"
                border={`1px solid ${Colors.grey500}`}
                bg={Colors.white}
                padding="28px"
              >
                <HStack borderBottom={`1px solid ${Colors.grey500}`} pb="32px">
                  <CircleIcon className="mr-32" />
                  <div className="flex flex-col pt-4 pb-12 text-[#75818D]">
                    <h6 className="font-inter font-semibold text-2xl text-blue200 mb-6">
                      Payment successful!
                    </h6>
                    <p className="font-inter font-normal text-sm mb-4">
                      {`₦${amountConverter(transaction?.amount)}`} was swept
                      from {capitalize(transaction?.beneficiary?.first_name)}{" "}
                      {capitalize(transaction?.beneficiary?.last_name)}
                    </p>
                    <p className="font-inter font-normal text-sm">
                      {capitalize(transaction?.bank_name)}
                    </p>
                  </div>
                </HStack>

                <Stack mt="24px">
                  {details?.map((_) => {
                    return (
                      <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                        <h6 className="font-inter font-normal text-sm text-grey950">
                          {_?.label}
                        </h6>

                        <h6 className="font-inter font-normal text-sm text-[#181830]">
                          {_?.value}
                        </h6>
                      </div>
                    );
                  })}

                  {/* <button className="btn btn-primary mt-32">
                    Download Report
                  </button> */}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DetailsModal;
