import { ReactComponent as EmptyIcon } from "icons/empty.svg";

const EmptyState = ({title="Empty State",description="No data"}) => {
  return (
    <section className="flex flex-col items-center justify-center">
      <EmptyIcon className="mb-32" /> 
      <div className="flex flex-col items-center px-64 ">
        <h1 className="text-center font-medium font-inter text-sm text-dark100">{title}</h1>
        <p className="text-center  font-normal font-inter text-12 text-dark-00">
         {description}
        </p>
      </div>
    </section>
  );
};

export default EmptyState;
