import DashboardLayout from "layouts/dashboard";
import { useDisclosure } from "@chakra-ui/react";
import { ReactComponent as SuccessIcon } from "icons/succes.svg";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
} from "@chakra-ui/react";
import BlurOverlay from "layouts/components/BlurOverlay";
import { useEffect } from "react";

const DashboardWalletSuccess = () => {
  const { isOpen, onOpen } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <Modal
        closeOnOverlayClick={false}
        size="xl"
        isOpen={isOpen}
        onClose={() => {}}
        isCentered={true}
      >
        <BlurOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody padding="0">
            <Stack padding="20px" borderBottom="1px solid #E0E0E0">
              <h2 className="font-inter font-semibold text-h6">
                Fund Wallet Success
              </h2>
            </Stack>
            <Stack padding="20px">
              <div className="flex items-center mb-24">
                <div className="flex flex-col !items-center !justify-center">
                  <figure className="bg-grey500 w-180 h-180 rounded-full flex items-center justify-center">
                    <SuccessIcon />
                  </figure>
                  <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-24">
                    Wallet Funded Successfully!
                  </h1>
                  <h1 className="text-p text-dark200 text-center font-graphik">
                    Congratulations! Your wallet has been funded successfully.
                    Start exploring and make the most of your funds with our
                    platform.
                  </h1>
                  <div className="flex my-32">
                    <button
                      type="button"
                      onClick={() => {
                        navigate(Path.DASHBOARD_WALLET);
                      }}
                      className="btn btn-primary w-full !py-20 !px-32"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </DashboardLayout>
  );
};

export default DashboardWalletSuccess;
