/* eslint-disable no-unused-vars */
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Badge,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { useMemo } from "react";
import EmptyState from "components/EmptyState";
import { capitalize } from "utils/strings";
// import { format } from "date-fns";
import { mapBadge } from "utils/strings";
import BlacklistDetails from "pages/dashbaord/blacklist/componenents/BlacklistDetails";
import { useState, useRef } from "react";
import { useDeleteBlacklistMutation } from "store/services/business";

const BlacklistTable = ({ blacklist }) => {
  const COLUMNS = () => [
    {
      Header: "Email",
      accessor: "email",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        const { email } = cell?.row?.original;
        return (
          <Td width="15%" padding="0" border="0">
            <Stack direction="row" alignItems="center">
              <figure className="w-50 h-50 rounded-full bg-slate-500"></figure>
              <h6>{capitalize(email)}</h6>
            </Stack>
          </Td>
        );
      },
    },
    {
      Header: "Category",
      accessor: "category",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            {/* <h6>{format(new Date(value), "dd MMM YYY")}</h6> */}
            <h6>{capitalize(value)}</h6>
          </Td>
        );
      },
    },
    {
      Header: "Country",
      accessor: "country",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            {/* <h6>{amountConverter(value)} </h6> */}
            <h6>{capitalize(value)} </h6>
          </Td>
        );
      },
    },
    {
      Header: "Phone",
      accessor: "phone",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            {/* <h6>{amountConverter(value)} </h6> */}
            <h6>{capitalize(value)} </h6>
          </Td>
        );
      },
    },
    {
      Header: "Status",
      accessor: "is_active",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return (
          <Td border="0" pl="0">
            <Badge variant="solid" colorScheme={mapBadge(value)}>
              {value ? "Active" : "Inactive"}
            </Badge>
          </Td>
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        const {
          isOpen: isBlacklistOpen,
          onOpen: onBlacklistOpen,
          onClose: onBlacklistClose,
        } = useDisclosure();

        const { isOpen, onOpen, onClose } = useDisclosure();
        const cancelRef = useRef();
        const [deleteBlacklist, { isLoading: deleting }] =
          useDeleteBlacklistMutation();
        const toast = useToast();

        const [active, setActive] = useState(null);

        return (
          <>
            <BlacklistDetails
              isBlacklistOpen={isBlacklistOpen}
              onBlacklistOpen={onBlacklistOpen}
              onBlacklistClose={onBlacklistClose}
              data={active}
            />
            <Td border="0">
              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete Account
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          setActive(cell?.row.original);
                          deleteBlacklist({ id: cell?.row.original?.id })
                            .unwrap()
                            .then((data) => {
                              toast({
                                title: data?.message,
                                description: data?.detail,
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                                position: "top",
                              });
                              onClose();
                            })
                            .catch((error) => {
                              toast({
                                title: "Error Occured",
                                description: error?.data?.detail,
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                                position: "top",
                              });
                            });
                        }}
                        ml={3}
                      >
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
              <button
                onClick={() => {
                  onOpen();
                }}
                className="cursor-pointer text-red-500 font-bold mr-16"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setActive(cell?.row.original);
                  onBlacklistOpen();
                }}
                className="cursor-pointer text-primary font-bold"
              >
                View
              </button>
            </Td>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => COLUMNS("ee"), []);

  const tableInstance = useTable(
    { columns, data: blacklist, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <section className="h-[30rem] overflow-y-scroll">
      {blacklist && blacklist?.length > 0 ? (
        <Table
          variant="simple"
          bgColor={Colors.white}
          mt="2rem"
          {...getTableProps()}
        >
          <Thead className="dashboard__table__header">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    scope="col"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}> {cell.render("Cell")} </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <EmptyState />
      )}
    </section>
  );
};

export default BlacklistTable;
