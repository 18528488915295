/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import AuthLayout from "layouts/auth";
import { Path } from "utils/constant.data";
import { useNavigate } from "react-router-dom";
import { useToast, Spinner } from "@chakra-ui/react";
import * as Yup from "yup";
import { useRegisterUserEmailMutation } from "store/services/authAPI";
import { Colors } from "utils/constant.data";

const RegisterPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [registerUser, { isLoading: authenticating }] =
    useRegisterUserEmailMutation();

  const handleSubmit = async (values) => {
    await registerUser({
      email: values?.email,
    })
      .unwrap()
      .then((data) => {
        navigate(Path.CONFIRM_ACCOUNT, {
          state: {
            initializedUser: data?.data,
          },
        });
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <AuthLayout>
      <section className="w-fullflex justify-center  flex-col h-full p-178">
        <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-4">
          Start your CLARS Journey
        </h1>
        <h1 className="text-p text-dark200 font-graphik">
          Join the growing network of loan institutions on CLARS and experience
          the benefits of a seamless loan recovery process.
        </h1>

        <form
          onSubmit={formik.handleSubmit}
          className="auth__form w-full mt-24"
        >
          <div className="auth-form-group">
            <label htmlFor="email">Your official email</label>
            <input
              type="email"
              id="email"
              aria-describedby="Email"
              placeholder="Enter your Email"
              {...formik.getFieldProps("email")}
            ></input>
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="flex my-12">
            <button type="submit" className="btn btn-primary w-full">
              {authenticating ? (
                <Spinner color={Colors.white} />
              ) : (
                "Get Started"
              )}
            </button>
          </div>

          <p className="text-center  font-display text-[#696F79] font-normal text-base mt-40">
            Do you have account?
            <Link
              to={Path.LOGIN}
              className="text-primary pl-6 font-medium"
              href="ff"
            >
              Sign In
            </Link>
          </p>
        </form>
      </section>
    </AuthLayout>
  );
};

export default RegisterPage;

const freeDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "zoho.com",
  "yandex.com",
  "protonmail.com",
];

Yup.addMethod(Yup.string, "officialEmail", function () {
  return this.test(
    "is-official-email",
    "Please enter a valid official email address",
    function (value) {
      if (!value) return true;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) return false;
      const domain = value.split("@")[1].toLowerCase();
      return !freeDomains.includes(domain);
    }
  );
});

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required").officialEmail(),
});
