import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import LoanCard from "components/LoanCard";
import { useState } from "react";
import TableContainer from "components/TableContainer";
import { Skeleton, Stack, Spinner } from "@chakra-ui/react";
import WalletTable from "components/tables/WalletTable";
import { amountConverter } from "utils/strings";
import { useGetMerchantProfileQuery } from "store/services/business";
import FundWallet from "./componenents/FundWallet";
import { useDisclosure } from "@chakra-ui/react";

import {
  // useFundWalletMutation,
  useGetWalletHistoryQuery,
} from "store/services/loan";

const DashboardWallet = () => {
  const { data: stats, isLoading } = useGetMerchantProfileQuery();

  const statData = [
    {
      title: "Wallet Balance",
      number: `₦${amountConverter(stats?.data?.wallet_balance)}`,
      percent: "0",
      showFilter: false,
    },
  ];

  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetWalletHistoryQuery(filter, {
    skip: skip,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">Wallet</h2>

        {isLoading ? (
          <section className="my-16">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </section>
        ) : (
          <section className="grid grid-cols-3 gap-16 my-16">
            {statData?.map((_) => {
              return <LoanCard data={_} key={_?.title} />;
            })}
          </section>
        )}

        <section className="grid grid-cols-1 gap-16 my-16">
          {fetching || isFetching ? (
            <Stack>
              <Skeleton height="40px" />
              <Skeleton height="80px" />
              <Skeleton height="140px" />
            </Stack>
          ) : (
            <TableContainer
              title="Wallet History"
              count={data?.results?.length}
              handleRoute={() => {
                onOpen();
              }}
              showBadge={true}
              showNew={true}
              btnText="Fund Wallet"
            >
              {data?.results?.length > 0 ? (
                <WalletTable transactions={data?.results} />
              ) : (
                <section className="flex items-center justify-center min-h-300">
                  <EmptyState />
                </section>
              )}
            </TableContainer>
          )}
        </section>
      </section>
      <FundWallet isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </DashboardLayout>
  );
};

export default DashboardWallet;
