import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import EmptyState from "components/EmptyState";

const Blacklist = ({ data }) => {
  return (
    <section className="flex flex-wrap justify-between">
      <Stack mb="26px" className="basis-full flex-grow-0 flex-shrink-0">
        <h2 className="font-inter text-grey800 text-2xl ">Blacklist</h2>
        <Table variant="simple" bgColor="#F9FAFB">
          <Thead className="dashboard__table__header">
            <Tr>
              <Th>Institution</Th>
              <Th>Amount</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody className="dashboard__table__body">
            {data?.length > 0 ? (
              data?.map((_) => {
                return (
                  <Tr>
                    <Td>
                      <h6>{_?.institution}</h6>
                    </Td>
                    <Td>
                      <h6>{_?.amount}</h6>
                    </Td>
                    <Td>
                      <h6>{_?.date}</h6>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <section className="flex items-center justify-center w-full min-h-300 translate-x-[20%]">
                <EmptyState title="No Data" />
              </section>
            )}
          </Tbody>
        </Table>{" "}
      </Stack>
    </section>
  );
};

export default Blacklist;
