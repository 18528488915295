/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import AuthLayout from "layouts/auth";
import { Path } from "utils/constant.data";
import { useNavigate } from "react-router-dom";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const state = window.history?.state ?? null;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <AuthLayout>
      <section className="w-fullflex justify-center  flex-col h-full p-178">
        <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-16">
          Forgot Password?
        </h1>
        <h1 className="text-p text-dark200 font-graphik">
          No worries we’ll send you reset instruction via your registered email.
        </h1>

        <form className="auth__form w-full mt-24">
          <div className="auth-form-group">
            <label htmlFor="email">Your email</label>
            <input
              type="email"
              id="email"
              placeholder="mail@robinjobs.com"
              name="email"
            ></input>
            {/* <p className="error">Error</p> */}
          </div>

          <div className="flex my-12">
            <button
              type="button"
              onClick={() => {
                navigate(Path.RESET_PASSWORD);
              }}
              className="btn btn-primary w-full"
            >
              Continue
            </button>
          </div>

          <p className="font-normal font-graphik text-dark200 text-sm mt-24">
            <Link to={Path.LOGIN} className="text-blue" href="ff">
              Back To Login
            </Link>
          </p>
        </form>
      </section>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
