import DashboardLayout from "layouts/dashboard";
import { useState, useEffect } from "react";
import { Stack, HStack, Text, useToast, Spinner } from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { ReactComponent as RefreshIcon } from "icons/reload.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { Path } from "utils/constant.data";
import { ReactComponent as BackIcon } from "icons/back.svg";
import CreditCheckTabOne from "./components/Tab1";
import CreditCheckTabTwo from "./components/Tab2";
import CreditCheckTabThree from "./components/Tab3";
import { capitalize, getCountryByCode } from "utils/strings";
import { useRerunCreditCheckMutation } from "store/services/business";

const DashboardCreditCheckDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [active, setActive] = useState("one");
  const toast = useToast();

  useEffect(() => {
    if (!state?.checkDetails) {
      navigate(`${Path.DASHBOARD_CREDIT_CHECK}`);
    }
  }, [navigate, state]);

  const checkData = state?.checkDetails ?? null;
  const creditNum = checkData?.response_data?.credit_data?.length ?? 0;

  const [rerunCreditCheck, { isLoading: rerunning }] =
    useRerunCreditCheckMutation();

  const handleRerun = async (analysis_id) => {
    await rerunCreditCheck({
      analysis_id,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: "Success",
          description: "Check Requested Again",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <Stack>
          <div className="flex flex-col my-20">
            <h2 className="font-inter font-medium text-black text-2xl">
              Credit Analysis Details
            </h2>
            <div className="flex items-center justify-between">
              <p className="text-grey550 font-inter text-10">
                Dashboard Overview / Credit Analysis
              </p>
              <button
                onClick={() => {
                  handleRerun(checkData?.id);
                }}
                className="btn btn-primary flex items-center border-1 border-[#E1E1E1] !px-16"
              >
                <span className="mr-8 px-8">
                  {rerunning ? (
                    <Spinner color={Colors.white} />
                  ) : (
                    "Redo Analysis"
                  )}
                </span>{" "}
                <span
                  className={`transition-all ${
                    rerunning ? "animate-spin-run" : ""
                  }`}
                >
                  <RefreshIcon />
                </span>
              </button>
            </div>
          </div>
        </Stack>

        <Stack
          bgColor="white"
          border={`1px solid ${Colors.grey650}`}
          rounded="8"
          py="8"
          px="8"
          className="d"
        >
          <button
            onClick={() => navigate(Path.DASHBOARD_CREDIT_CHECK)}
            className="w-250 flex items-center  text-grey700"
          >
            {" "}
            <BackIcon />
            <span className="ml-8">Back to Overview</span>
          </button>

          <Stack
            bgColor="white"
            border={`1px solid ${Colors.grey650}`}
            borderWidth="1"
            rounded="8"
            direction="row"
            my="4"
            padding="32px"
          >
            <HStack gap="0">
              <figure className="w-78 h-78 rounded-full bg-grey550 mr-8"></figure>
              <Stack>
                <Text className="font-inter font-semibold text-xl  text-blue200">
                  Credit Check
                </Text>
                {checkData?.request_data?.country && (
                  <Text className="font-inter font-semibold text-12 text-[#A0AEC0]">
                    {getCountryByCode(checkData?.request_data?.country)}{" "}
                    {checkData?.request_data?.country} |{" "}
                    {capitalize(checkData?.request_data?.check_type)} Account
                  </Text>
                )}
              </Stack>
            </HStack>
            <HStack
              gap="0"
              borderLeft={`1px solid ${Colors.grey650}`}
              mx="16"
              px="16"
            >
              <Stack>
                <Text className="font-inter font-semibold text-xl  text-blue200">
                  BVN{checkData?.request_data?.idnumber}
                </Text>
                <Text className="font-inter font-semibold text-12 text-[#A0AEC0]">
                  Bank Verification Number
                </Text>
              </Stack>
            </HStack>
          </Stack>

          <HStack
            borderBottom={`1px solid ${Colors.grey650}`}
            className="credit-tab"
          >
            <button
              onClick={() => setActive("one")}
              className={active === "one" ? "active" : ""}
            >
              Credit Analysis
            </button>
            <button
              onClick={() => setActive("two")}
              className={active === "two" ? "active" : ""}
            >
              Credit Data{" "}
              <span className="w-24 h-24 bg-[#F2F4F7] rounded-full px-8 py-4 ml-4">
                {creditNum}
              </span>
            </button>
            <button
              onClick={() => setActive("three")}
              className={active === "three" ? "active" : ""}
            >
              Blacklist
            </button>
          </HStack>

          {active === "one" && <CreditCheckTabOne checkData={checkData} />}
          {active === "two" && checkData && (
            <CreditCheckTabTwo
              active={{
                request_data: { ...checkData?.request_data },
                response_data: { ...checkData?.response_data },
              }}
            />
          )}
          {active === "three" && (
            <CreditCheckTabThree
              blacklist={checkData?.response_data?.blacklist}
            />
          )}
        </Stack>
      </section>
    </DashboardLayout>
  );
};

export default DashboardCreditCheckDetails;
