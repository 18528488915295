import { ReactComponent as LogoIcon } from "icons/logo.svg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive } from "utils/carousel";

const AuthCarousel = () => {
  const CustomDot = ({ onClick, ...rest }) => {
    const { active } = rest;
    return (
      <button
        className={`${active ? "active" : "inactive"}`}
        onClick={() => onClick()}
      ></button>
    );
  };

  const SlideContent = ({ imageSrc, altText, description }) => (
    <div className="overflow-hidden w-full h-full relative">
      <figure className="py-82 px-58 absolute top-0 left-0 z-10">
        <LogoIcon />
      </figure>
      <div className="py-82 px-58 absolute bottom-130 w-[70%] left-0 z-[12]">
        <p className="font-graphik font-normal text-base leading-5 text-grey200">
          {description}
        </p>
      </div>
      <figure className="absolute top-0 left-0 z-[1]">
        <img src={imageSrc} alt={altText} />
      </figure>
    </div>
  );

  const slides = [
    {
      imageSrc: "https://clars-bucket-test.s3.amazonaws.com/assets/frame1.jpg",
      altText: "Image one",
      description:
        "Streamline your loan recovery processes and boost your collection rates with our cutting-edge CLARS platform.",
    },
    {
      imageSrc:"https://clars-bucket-test.s3.amazonaws.com/assets/frame2.jpg",
      altText: "Image two",
      description:
        "Enhance your loan recovery capabilities and regain control over your outstanding loans with our auto-sweep feature.",
    },
    {
      imageSrc:"https://clars-bucket-test.s3.amazonaws.com/assets/frame3.jpg",
      altText: "Image three",
      description:
        "Maximize your loan recovery efforts and minimize your losses with our innovative loan recovery solution tailored for loan givers.",
    },
  ];

  return (
    <section className="overflow-hidden w-full h-full">
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={5000}
        keyBoardControl={false}
        customTransition="all .5"
        containerClass="carousel-container"
        arrows={false}
        showDots={true}
        customDot={<CustomDot />}
      >
        {slides.map((slide, index) => (
          <SlideContent key={index} {...slide} />
        ))}
      </Carousel>
    </section>
  );
};

export default AuthCarousel;
