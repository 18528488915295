/** @format */
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getLocalStorage } from "utils/secure";
import { Path } from "utils/constant.data";
import toast from "react-hot-toast";


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const user = localStorage.getItem("UTMUSER")
      ? getLocalStorage("UTMUSER")
      : null;
    const accessToken = user?.access;
    headers.set("Content-Type", "application/json");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);


  if (result.error?.status === 401) {
    toast.error(result.error?.data?.detail, {
      className: "",
      duration: 5000,
      style: {
        background: "#DB353A",
        color: "white",
      },
    });

    window.localStorage.removeItem("UTAT");
    window.localStorage.removeItem("UTMUSER");
    const redirectState = { from: window.location.pathname };
    window.history.replaceState(redirectState, '', Path.LOGIN);
    window.location.reload();
  }
  return result;
};

export default baseQueryWithReauth;
