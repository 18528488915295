import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import { useState } from "react";
import TableContainer from "components/TableContainer";
import { Skeleton, Stack } from "@chakra-ui/react";
import TeamTable from "components/tables/TeamTable";
import FundWallet from "./componenents/AddMember";
import { useDisclosure } from "@chakra-ui/react";
import { useGetAllUsersQuery } from "store/services/team";

const DashboardTeam = () => {
  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetAllUsersQuery(filter, {
    skip: skip,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">
          Team Management
        </h2>

        <section className="grid grid-cols-1 gap-16 my-16">
          {fetching || isFetching ? (
            <Stack>
              <Skeleton height="40px" />
              <Skeleton height="80px" />
              <Skeleton height="140px" />
            </Stack>
          ) : (
            <TableContainer
              title="Team Members"
              count={data?.results?.length}
              handleRoute={() => {
                onOpen();
              }}
              showBadge={false}
              showNew={true}
              btnText="Add Team Member"
            >
              {data?.data?.length > 0 ? (
                <TeamTable
                  transactions={data?.data?.filter(
                    (_) => _?.permission !== "business_owner"
                  )}
                />
              ) : (
                <section className="flex items-center justify-center min-h-300">
                  <EmptyState />
                </section>
              )}
            </TableContainer>
          )}
        </section>
      </section>
      <FundWallet isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </DashboardLayout>
  );
};

export default DashboardTeam;
