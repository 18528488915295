import {
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { amountConverter, capitalize } from "utils/strings";
import { format } from "date-fns";
import EmptyState from "components/EmptyState";

const CreditCheckTabThree = ({ blacklist }) => {
  return blacklist?.length > 10 ? (
    <Stack direction="row" justifyContent="space-between" mt="4">
      <Table variant="simple" bgColor={Colors.white}>
        <Thead className="dashboard__table__header">
          <Tr>
            <Th>Institutuon</Th>
            <Th>Amount</Th>
            <Th>Date</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody className="dashboard__table__body">
          {blacklist?.map((_) => {
            return (
              <Tr key={_?.id}>
                <Td>
                  <h6>{capitalize(_?.institution)}</h6>
                </Td>

                <Td>
                  {" "}
                  <h6>₦{amountConverter(_?.amount)}</h6>
                </Td>
                <Td>
                  {" "}
                  <h6>{format(new Date(_?.date), "dd MMM YYY")}</h6>
                </Td>
                <Td>
                  <div
                    className={`badge-container ${
                      _?.status ? "success" : "danger"
                    }`}
                  >
                    <span></span>
                    <p>{_?.status ? "Active" : "Inactive"}</p>
                  </div>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Stack>
  ) : (
    <Center>
      <section className="flex items-center justify-center w-full min-h-300 ">
        <EmptyState title="No Data" />
      </section>
    </Center>
  );
};

export default CreditCheckTabThree;
