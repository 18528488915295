import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { Gender } from "utils/strings";
import OtpInput from "react-otp-input";
import { useCreateBenefeciaryMutation } from "store/services/loan";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";

const AddBeneficiaryModal = ({
  isBeneficiaryOpen,
  onBeneficiaryOpen,
  onBeneficiaryClose,
}) => {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );

  const [step] = useState(1);
  const toast = useToast();
  const navigate = useNavigate();
  const authenticating = false;

  const [imgs, setImgs] = useState({
    witnesssignature: null,
    payersignature: null,
    witness: null,
    payer: null,
  });
  const [touched, setTouched] = useState({
    witnesssignature: false,
    payersignature: false,
  });

  const BenefeciaryForm = () => {
    const [createBenefeciary, { isLoading: creating }] = useCreateBenefeciaryMutation();
    const [imgs, setImgs] = useState({});
    const [touched, setTouched] = useState({});


    // const handleFileUpload = (fieldName, file) => {
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       setImgs((prevImgs) => ({
    //         ...prevImgs,
    //         [fieldName]: file,
    //         [`${fieldName}signature`]: reader.result,
    //       }));

    //       // Update Formik values directly
    //       formik.setFieldValue(fieldName, reader.result);
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // };


    const handleSubmit = async (values, { resetForm }) => {
      const submitData = {
        ...values,
        payersignature: imgs?.payersignature,
        witnesssignature: imgs?.witnesssignature,
      };

      try {
        const response = await createBenefeciary(submitData).unwrap();
        toast({
          title: response?.message,
          description: response?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        resetForm();
        navigate(`${Path.LOAN_DETAILS}/${response?.data?.id}`);
      } catch (error) {
        toast({
          title: "Error Occurred",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    };

    const formik = useFormik({
      initialValues: {
        bvn: "",
        first_name: "",
        last_name: "",
        gender: "",
        address: "",
        phone: "",
        email: "",
        witnessname: "",
        witnessaddress: "",
        witnessoccupation: "",
        payersignature: "",
        witnesssignature: "",
        // dob: ""
      },
      validationSchema: LoanSchema,
      onSubmit: handleSubmit,
      enableReinitialize: false,
    });

    return (
      <form onSubmit={formik.handleSubmit} className="form !w-full mt-24">
        <Stack>
          {/* <p>Erros:: {JSON.stringify(formik.errors)}</p> */}
          {/* <p>Imgs:: {JSON.stringify(imgs)}</p>
          <p>Payer Values:: {JSON.stringify(formik.values)}</p> */}
        </Stack>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            placeholder="Enter your Firstname"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <div className="error">{formik.errors.first_name}</div>
          )}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Last Name</label>
          <input
            type="text"
            id="last_name"
            aria-describedby="Lastname"
            placeholder="Enter your Lastname"
            {...formik.getFieldProps("last_name")}
          ></input>
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="error">{formik.errors.last_name}</div>
          ) : null}
        </div>

        <div className="form-row-half auth-form-group !mb-16">
          <label htmlFor="email">Gender</label>

          <Select
            placeholder="Choose Gender"
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps("gender")}
          >
            {Gender?.map((_) => {
              return <option value={_?.value}>{_?.label}</option>;
            })}
          </Select>

          {formik.touched.gender && formik.errors.gender ? (
            <div className="error">{formik.errors.gender}</div>
          ) : null}
        </div>

        <div className="form-row-half auth-form-group !mb-16">
          <label htmlFor="email">BVN</label>

          <NumberInput>
            <NumberInputField
              variant="filled"
              bg="#EDEDED"
              borderColor="transparent"
              borderRadius="0"
              color="#424242"
              placeholder="BVN"
              height="60px"
              {...formik.getFieldProps("bvn")}
            // onChange={() => {
            //   formik.setFieldTouched("bvn", true);
            // }}
            />
          </NumberInput>

          {formik.touched.bvn && formik.errors.bvn ? (
            <div className="error">{formik.errors.bvn}</div>
          ) : null}
        </div>

        {/* New */}
        <div className="form-row-half auth-form-group !mb-16">
          <label htmlFor="email">Phone</label>

          <NumberInput>
            <NumberInputField
              variant="filled"
              bg="#EDEDED"
              borderColor="transparent"
              borderRadius="0"
              color="#424242"
              placeholder="Phone"
              height="60px"
              {...formik.getFieldProps("phone")}
            // onChange={() => {
            //   formik.setFieldTouched("phone", true);
            // }}
            />
          </NumberInput>

          {formik.touched.phone && formik.errors.phone ? (
            <div className="error">{formik.errors.phone}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Address</label>
          <input
            type="text"
            id="address"
            aria-describedby="Address"
            placeholder="Enter your Address"
            {...formik.getFieldProps("address")}
          ></input>
          {formik.touched.address && formik.errors.address ? (
            <div className="error">{formik.errors.address}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            aria-describedby="Email"
            placeholder="Enter  email"
            {...formik.getFieldProps("email")}
          ></input>
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Witness Name</label>
          <input
            type="text"
            id="witnessname"
            aria-describedby="Lastname"
            placeholder="Enter your Witness Name"
            {...formik.getFieldProps("witnessname")}
          ></input>
          {formik.touched.witnessname && formik.errors.witnessname ? (
            <div className="error">{formik.errors.witnessname}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Witness Address</label>
          <input
            type="text"
            id="witnessaddres"
            aria-describedby="Lastname"
            placeholder="Enterwitness Address"
            {...formik.getFieldProps("witnessaddress")}
          ></input>
          {formik.touched.witnessaddress && formik.errors.witnessaddress ? (
            <div className="error">{formik.errors.witnessaddress}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Witness Occupation</label>
          <input
            type="text"
            id="witnessoccupation"
            aria-describedby="Lastname"
            placeholder="Enter your witness occupation"
            {...formik.getFieldProps("witnessoccupation")}
          ></input>
          {formik.touched.witnessoccupation &&
            formik.errors.witnessoccupation ? (
            <div className="error">{formik.errors.witnessoccupation}</div>
          ) : null}
        </div>
        {/* <div className="auth-form-group basis-[48%]">
          <label htmlFor="dob">Date of Birth</label>
          <input
            type="date"
            id="dob"
            aria-describedby="Date of Birth"
            placeholder="Enter your Date of Birth"
            {...formik.getFieldProps("dob")}
          ></input>
          {formik.touched.dob && formik.errors.dob ? (
            <div className="error">{formik.errors.dob}</div>
          ) : null}
        </div> */}

        {/* <div className="auth-form-group basis-[48%]">
          <label htmlFor="payersignature">Payer's Signature</label>
          <input
            type="file"
            id="payersignature"
            name="payersignature"
            onChange={(e) => {
              const file = e.target.files[0];
              setTouched((prev) => ({
                ...prev,
                payersignature: true,
              }));
              handleFileUpload('payersignature', file);
            }}
          />
          {touched.payersignature && !imgs?.payersignature && (
            <div className="error">Payer's Signature required</div>
          )}
          {imgs?.payersignature && (
            <img
              src={imgs.payersignature}
              alt="Payer's signature"
              className="mt-2 max-h-24"
            />
          )}
        </div> */}
        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Payer's Signature</label>
          <input
            type="file"
            id="payersignature"
            // value={imgs?.payer}
            aria-describedby="payersignature"
            placeholder="Payers Signature"
            onChange={(e) => {
              const file = e.target.files;
              // console.log("file payers name", file);
              setTouched({
                ...touched,
                payersignature: true,
              });

              if (file) {
                // console.log(e.target.files);
                const data = new FileReader();
                data.addEventListener("load", () => {
                  setImgs({
                    ...imgs,
                    payer: file,
                    payersignature: data.result,
                  });
                  formik.setFieldValue("payersignature", String(data.result));
                });
                data.readAsDataURL(e.target.files[0]);
              }
            }}
          ></input>
          {touched.payersignature &&
            !imgs?.payersignature && (
              <div className="error">Payer's Signature required</div>
            )}
        </div>
        {/* <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Witness's Signature</label>
          <input
            type="file"
            id="witnesssignature"
            aria-describedby="Lastname"
            placeholder="Witness Signature"
            // value={imgs?.witness}
            onChange={(e) => {
              const file = e.target.files;
              // console.log("file witness", file);

              setTouched({
                ...touched,
                witnesssignature: true,
              });

              if (file) {
                const data = new FileReader();
                data.addEventListener("load", () => {
                  setImgs({
                    ...imgs,
                    witness: file,
                    witnesssignature: data.result,
                  });
                  formik.setFieldValue("witnesssignature", String(data.result));
                });
                data.readAsDataURL(e.target.files[0]);
              }
            }}
          ></input>

          {touched.witnesssignature &&
            !imgs?.witnesssignature && (
              <div className="error">Witness's Signature required</div>
            )}
        </div> */}
        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Witness's Signature</label>
          <input
            type="file"
            id="witnesssignature"
            aria-describedby="Lastname"
            placeholder="Witness Signature"
            // value={imgs?.witness}
            onChange={(e) => {
              const file = e.target.files;
              // console.log("file witness", file);

              setTouched({
                ...touched,
                witnesssignature: true,
              });

              if (file) {
                const data = new FileReader();
                data.addEventListener("load", () => {
                  setImgs({
                    ...imgs,
                    witness: file,
                    witnesssignature: data.result,
                  });
                  formik.setFieldValue("witnesssignature", String(data.result));
                });
                data.readAsDataURL(e.target.files[0]);
              }
            }}
          ></input>

          {touched.witnesssignature &&
            !imgs?.witnesssignature && (
              <div className="error">Witness's Signature required</div>
            )}
        </div>


        <div className="flex my-12 basis-[100%]">
          <button
            type="submit"
            className={`btn btn-primary w-full ${!imgs?.payersignature || !imgs?.witnesssignature
              ? "btn-disabled"
              : ""
              }`}
          >
            {creating ? <Spinner color={Colors.white} /> : "Add"}
          </button>
        </div>
      </form>
    );
  };

  const BvnForm = () => {
    const handleSubmit = async (values, { resetForm }) => { };

    const formik = useFormik({
      initialValues: {
        country: "",
        check_type: "",
        idnumber: "",
      },
      validationSchema: LoanSchema,
      onSubmit: handleSubmit,
      enableReinitialize: true,
    });
    return (
      <form onSubmit={formik.handleSubmit} className="form !w-full mt-24">
        <div className="form-row-full auth-form-group !mb-16">
          <label htmlFor="email">BVN</label>

          <NumberInput>
            <NumberInputField
              {...formik.getFieldProps("idnumber")}
              variant="filled"
              bg="#EDEDED"
              borderColor="transparent"
              borderRadius="0"
              color="#424242"
              placeholder="Your BVN"
              height="60px"
            />
          </NumberInput>

          {formik.touched.idnumber && formik.errors.idnumber ? (
            <div className="error">{formik.errors.idnumber}</div>
          ) : null}
        </div>

        <div className="flex my-12 basis-[100%]">
          <button type="button" className="btn btn-primary w-full">
            {authenticating ? <Spinner color={Colors.white} /> : "Verify BVN"}
          </button>
        </div>
      </form>
    );
  };

  const OTPForm = () => {
    const [otp, setOtp] = useState(344);

    const handleSubmit = async (values, { resetForm }) => { };

    const formik = useFormik({
      initialValues: {
        country: "",
        check_type: "",
        idnumber: "",
      },
      validationSchema: LoanSchema,
      onSubmit: handleSubmit,
      enableReinitialize: true,
    });

    return (
      <form onSubmit={formik.handleSubmit} className="form !w-full mt-24">
        <div className="form-row-full auth-form-group !mb-16">
          <div>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<div style={{ marginLeft: "8px" }}></div>}
              renderInput={(props) => <input {...props} />}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid transparent",
                width: "76px",
                height: "76px",
                fontSize: "24px",
                color: "#33B469",
                fontWeight: "800",
                caretColor: "#33B469",
                backgroundColor: "#F5F5F5",
                fontFamily: `"Urbanist, "sans-serif"`,
              }}
              focusStyle={{
                border: "2px solid #33B469",
              }}
              containerStyle="k"
            />
          </div>
        </div>

        <div className="flex my-12 basis-[100%]">
          <button type="button" className="btn btn-primary w-full">
            {authenticating ? <Spinner color={Colors.white} /> : "Verify"}
          </button>
        </div>
      </form>
    );
  };

  const BankList = () => {
    const toast = useToast();

    return (
      <Stack width="100%">
        <section className="grid grid-cols-2 gap-8 my-16 w-full">
          <Stack bg="#826AF9" p="12px" textColor="white">
            <div className="flex justify-between">
              <div className="flex">
                <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-12"></figure>
                <div className="flex flex-col">
                  <h6 className="font-normal text-12 font-inter">
                    Beneficiary
                  </h6>
                  <h5 className="font-semibold text-sm font-inter">
                    Union Bank
                  </h5>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <h6 className="font-normal text-12 font-inter ">
                Account Number
              </h6>
              <h5 className="font-bold text-2xl font-urbanist">0712442650</h5>
            </div>
          </Stack>
          <Stack bg="#004085" p="12px" textColor="white">
            <div className="flex justify-between">
              <div className="flex">
                <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-12"></figure>
                <div className="flex flex-col">
                  <h6 className="font-normal text-12 font-inter">
                    Beneficiary
                  </h6>
                  <h5 className="font-semibold text-sm font-inter">
                    Union Bank
                  </h5>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <h6 className="font-normal text-12 font-inter ">
                Account Number
              </h6>
              <h5 className="font-bold text-2xl font-urbanist">0712442650</h5>
            </div>
          </Stack>
          <Stack bg="#004085" p="12px" textColor="white">
            <div className="flex justify-between">
              <div className="flex">
                <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-12"></figure>
                <div className="flex flex-col">
                  <h6 className="font-normal text-12 font-inter">
                    Beneficiary
                  </h6>
                  <h5 className="font-semibold text-sm font-inter">
                    Union Bank
                  </h5>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <h6 className="font-normal text-12 font-inter ">
                Account Number
              </h6>
              <h5 className="font-bold text-2xl font-urbanist">0712442650</h5>
            </div>
          </Stack>
          <Stack bg="#826AF9" p="12px" textColor="white">
            <div className="flex justify-between">
              <div className="flex">
                <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-12"></figure>
                <div className="flex flex-col">
                  <h6 className="font-normal text-12 font-inter">
                    Beneficiary
                  </h6>
                  <h5 className="font-semibold text-sm font-inter">
                    Union Bank
                  </h5>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <h6 className="font-normal text-12 font-inter ">
                Account Number
              </h6>
              <h5 className="font-bold text-2xl font-urbanist">0712442650</h5>
            </div>
          </Stack>
        </section>

        <div className="flex basis-[100%]">
          <button
            type="button"
            onClick={() => {
              toast({
                title: "SUCCESS",
                description: "Loan beneficiary added",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "top",
              });
              onBeneficiaryClose();
            }}
            className="btn btn-primary w-full"
          >
            {authenticating ? (
              <Spinner color={Colors.white} />
            ) : (
              "Create Mandate"
            )}
          </button>
        </div>
      </Stack>
    );
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isBeneficiaryOpen}
      onClose={onBeneficiaryClose}
      isCentered={true}
    >
      <OverlayOne />
      <ModalContent>
        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
          <div className="flex flex-col py-22 px-30">
            <h3 className="font-inter font-medium text-black text-2xl">
              Add Beneficiary
            </h3>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex mb-24">
            {step === 1 && <BenefeciaryForm />}
            {step === 2 && <BvnForm />}
            {step === 3 && <OTPForm />}
            {step === 4 && <BankList />}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddBeneficiaryModal;

const LoanSchema = Yup.object().shape({
  bvn: Yup.string().required("Your BVN is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last Name is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string().required("Phoneis required"),
  email: Yup.string().required("Email is required"),
  witnessname: Yup.string().required("Witness Name is required"),
  witnessaddress: Yup.string().required("Witness address is required"),
  witnessoccupation: Yup.string().required("Witness occupation is required"),
  // payersignature: Yup.string().required("Payer's Signature is required"),
  // witnesssignature: Yup.string().required("Witnes's Signature is required"),
});
