import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { ReactComponent as CaretIcon } from "icons/down.svg";
import { ReactComponent as HeadIcon } from "icons/head.svg";
import { ReactComponent as AddIcon } from "icons/add.svg";

const StepFour = ({ setStep }) => {
  return (
    <div className="bg-grey300 flex items-center px-150 py-20 mb-24">
      <form className="form w-full mt-24">
        <div className="w-full flex items-center !mb-16 bg-grey500 p-10">
          <figure className="p-13 bg-white border-grey400 rounded-2 mr-12">
            <HeadIcon />
          </figure>
          <input
            className="transparent__input"
            type="text"
            id="User"
            placeholder="User"
            name="user"
          ></input>

          <div>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<CaretIcon />}
                bg={Colors.grey500}
                textColor={Colors.dark200}
              >
                Initiator
              </MenuButton>
              <MenuList>
                <MenuItem>Initiator</MenuItem>
                <MenuItem>Reviewer</MenuItem>
                <MenuItem>Admin</MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>

        <div className="w-full">
          <button
            type="button"
            onClick={() => {
              setStep(0);
            }}
            className="btn btn-primary flex items-center !w-150"
          >
            <AddIcon />
            <span className="ml-4">Add New User</span>
          </button>
        </div>

        <div className="form-row-half flex my-12">
          <button
            type="button"
            onClick={() => {
              setStep(3);
            }}
            className="btn btn-grey w-full"
          >
            Back
          </button>
        </div>
        <div className="form-row-half flex my-12">
          <button
            type="button"
            onClick={() => {
              setStep(0);
            }}
            className="btn btn-primary w-full"
          >
            Send invites & Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepFour;
