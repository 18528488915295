import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "icons/home.svg";
import { ReactComponent as UserIcon } from "icons/user.svg";
import { ReactComponent as OffIcon } from "icons/off.svg";
import { ReactComponent as CaretRight } from "icons/caretright.svg";
import { ReactComponent as CaretDown } from "icons/down.svg";
import { ReactComponent as LoanIcon } from "icons/loan.svg";
import { ReactComponent as TransactionIcon } from "icons/transaction.svg";
import { ReactComponent as WalletIcon } from "icons/wallet.svg";
import { ReactComponent as AuditIcon } from "icons/audit.svg";
import { ReactComponent as HelpIcon } from "icons/help.svg";
import { ReactComponent as SettingsIcon } from "icons/setting.svg";
import { ReactComponent as UploadIcon } from "icons/upload-frame.svg";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";
import { logOut } from "store/reducers/AuthReducer";
import { useDispatch } from "react-redux";
import { ReactComponent as BankIcon } from "icons/bank.svg";
import { useGetMerchantProfileQuery } from "store/services/business";
import { useDisclosure } from "@chakra-ui/react";
import UploadLogo from "./UploadLog";
import { capitalize } from "utils/strings";

const DashboardSidebar = () => {
  const { data } = useGetMerchantProfileQuery();
  const dispatch = useDispatch();
  const navContainer = useRef();
  const nav = useRef();
  const navigate = useNavigate();
  const [showGeneral, setGeneral] = useState(true);
  const [showManage, setManage] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showNav = () => {
    navContainer.current.classList.remove("flex");
    nav.current.classList.remove("flex");
  };

  const path = window.location.pathname.split("/");

  const isVerified = data?.data?.verified ?? false;
  const logo = data?.data?.company_logo ?? null;

  return (
    <section ref={navContainer} className="sidebar--container ">
      <button
        className="flex items-center justify-center bg-white w-6 h-6 xlg:hidden absolute z-40 top-0 right-0"
        onClick={showNav}
      >
        {/* <CloseIcon /> */}
      </button>

      <section ref={nav} className="py-32 px-24 w-full">
        {logo ? (
          <div
            className="flex items-center p-4 !pr-2  bg-white w-full mb-18"
            onClick={() => {
              navigate(Path.DASHBOARD_OVERVIEW);
            }}
          >
            <figure className="h-50 w-50 overflow-hidden mr-12">
              <img className="h-full object-contain" src={logo} alt="preview" />
              {/* <LogoIcon /> */}
            </figure>

            <p className="text-dark400 font-600">
              {capitalize(data?.data?.name?.substring(0, 25))}
            </p>
          </div>
        ) : (
          <div
            className="flex items-center p-4 !pl-2  w-full mb-24"
            onClick={() => {
              navigate(Path.DASHBOARD_OVERVIEW);
            }}
          >
            <button onClick={onOpen} className="flex items-center">
              <figure className="mr-8">
                <UploadIcon />
              </figure>
              <span className="text-12">Click to upload brand icon</span>
            </button>
          </div>
        )}

        <section className="flex flex-col justify-between h-[90%] bg-white">
          <section className="flex  flex-col mb-16">
            <ul className="flex flex-col">
              <li className="mx-2">
                <Link
                  to={Path.DASHBOARD_OVERVIEW}
                  className={`sidebar__link  group ${path.includes("overview") ? "active" : ""
                    }`}
                >
                  <HomeIcon className="group-hover:fill-blue act" />
                  <span>Dashboard </span>
                </Link>
              </li>
            </ul>

            <div className="line my-12"></div>
            <button
              className="flex items-center justify-between"
              onClick={() => setGeneral(!showGeneral)}
            >
              <span className="text-[13px]">General</span>
              <figure>
                <CaretDown />
              </figure>
            </button>
            {showGeneral || path.includes("loan-beneficiary") ? (
              <ul className="flex flex-col px-8">
                <li className="mx-2 ">
                  <Link
                    to={Path.DASHBOARD_LOAN_BENEFICIARY}
                    className={`sidebar__link  group  ${isVerified ? "" : "disabled"
                      }  ${path.includes("loan-beneficiary") ? "active" : ""}`}
                  >
                    <LoanIcon />
                    <span className="ml-8">Loan Beneficiary</span>
                  </Link>
                </li>
                <li className="mx-2 ">
                  <Link
                    to={Path.DASHBOARD_TRANSACTIONS}
                    className={`sidebar__link  ${isVerified ? "" : "disabled"
                      } `}
                  >
                    <TransactionIcon />
                    <span className="ml-8">Transactions</span>
                  </Link>
                </li>
                {/* <li className="mx-2">
                  <Link
                    to={Path.DASHBOARD_SWEEP}
                    className={`sidebar__link  ${
                      isVerified ? "" : "disabled"
                    } `}
                  >
                    <AutomatedIcon />
                    <span className="ml-8">Automated Sweep</span>
                  </Link>
                </li> */}
                <li className="mx-2">
                  <Link
                    to={Path.DASHBOARD_CREDIT_CHECK}
                    className={`sidebar__link  group ${isVerified ? "" : "disabled"
                      } ${path.includes("credit-check") ? "active" : ""}`}
                  >
                    <BankIcon className="group-hover:fill-blue act" />
                    <span>Risk Score</span>
                  </Link>
                </li>
                <li className="mx-2">
                  <Link
                    to={Path.DASHBOARD_WALLET}
                    className={`sidebar__link  group  ${isVerified ? "" : "disabled"
                      }  ${path.includes("wallet") ? "active" : ""}`}
                  >
                    <WalletIcon className="group-hover:fill-blue act" />
                    <span>Wallet</span>
                  </Link>
                </li>
              </ul>
            ) : null}

            <div className="my-12"></div>
            <button
              className="flex items-center justify-between"
              onClick={() => setManage(!showManage)}
            >
              <span className="text-[13px]">Management</span>
              <figure>
                <CaretDown />
              </figure>
            </button>
            {showManage && (
              <ul className="flex flex-col px-8">

                <li className="mx-2">
                  <Link
                    to={Path.DASHBOARD_BLACKLIST}
                    className={`sidebar__link  ${isVerified ? "" : "disabled"}`}
                  >
                    <AuditIcon />
                    <span className="ml-8">Blacklist</span>
                  </Link>
                </li>
                {/* <li className="mx-2">
                  <Link
                    // to={Path.DASHBOARD_AUDIT_LOGS}
                    className={`sidebar__link   ${
                      isVerified ? "" : "disabled"
                    } `}
                  >
                    <AuditIcon />
                    <span className="ml-8">Audit logs</span>
                  </Link>
                </li> */}
                <li className="mx-2">
                  <a
                    href="https://docs.useclars.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`sidebar__link  ${isVerified ? "" : "disabled"
                      } `}
                  >
                    <HelpIcon />
                    <span className="ml-8">Documentation</span>
                  </a>
                </li>
                {/* <li className="mx-2">
                  <Link
                    to={Path.DASHBOARD_TEAM_MANAGEMENT}
                    className={`sidebar__link  group ${
                      path.includes("team-management") ? "active" : ""
                    }`}
                  >
                    <AuditIcon />
                    <span className="ml-8">Team</span>
                  </Link>
                </li> */}
                <li className="mx-2">
                  <Link
                    to={Path.DASHBOARD_SETTINGS}
                    className={`sidebar__link  group  ${isVerified ? "" : "disabled"
                      }  ${path.includes("settings") ? "active" : ""}`}
                  >
                    <SettingsIcon />
                    <span className="ml-8">Settings</span>
                  </Link>
                </li>
              </ul>
            )}
          </section>

          <section className="flex  flex-col mt-auto">
            {isVerified ? (
              <button
                onClick={() => navigate(Path.DASHBOARD_SWEEP)}
                className="rounded-4 flex items-center text-white font-normal text-smd text-12 bg-primary p-12 hover:bg-opacity-90 mb-30"
              >
                <UserIcon />
                <span className="ml-8">Get Started</span>
                <CaretRight />
              </button>
            ) : (
              <button
                onClick={() => navigate(Path.DASHBOARD_ONBOARDING)}
                className="rounded-4 flex items-center text-white font-normal text-smd text-12 bg-primary p-12 hover:bg-opacity-90 mb-30"
              >
                <UserIcon />
                <span className="ml-8">Complete Setup</span>
                <CaretRight />
              </button>
            )}

            <button
              onClick={() => dispatch(logOut())}
              className="flex items-center"
            >
              <OffIcon />
              <span className="ml-8 text-dark200 font-bold text-18">
                Log Out
              </span>
            </button>
          </section>
        </section>
      </section>

      <UploadLogo isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </section>
  );
};

export default DashboardSidebar;
