import {
  Select,
  NumberInput,
  NumberInputField,
  Skeleton,
  Stack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Colors } from "utils/constant.data";
import {
  useGetMerchantProfileQuery,
  useUpdateMerchantProfileMutation,
} from "store/services/business";
import { categories } from "utils/strings";

const MerchantProfile = () => {
  const { data, isLoading } = useGetMerchantProfileQuery();
  const [updateMerchantProfile, { isLoading: updating }] =
    useUpdateMerchantProfileMutation();
  const toast = useToast();
  const profile = data?.data ?? null;

  const handleSubmit = async (values) => {
    await updateMerchantProfile({
      ...values,
      official_phone: `+${values?.official_phone}`,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      tax_identification_number: profile?.tax_identification_number ?? "",
      license: profile?.license ?? "",
      license_category: profile?.license_category ?? "",
      website_url: profile?.website_url ?? "",
      cac_number: profile?.cac_number ?? "",
      official_phone: profile?.official_phone ?? "",
    },
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="bg-grey300 flex items-center px-150 py-20 mb-24">
      {isLoading ? (
        <Stack>
          <Skeleton height="100px" />
          <Skeleton height="120px" />
          <Skeleton height="140px" />
        </Stack>
      ) : (
        <Stack>
          <div className="flex flex-col pt-34 ">
            <h2 className="font-inter font-medium text-dark400 text-xl">
              Company Profile
            </h2>
            <p className="font-inter font-normal text-[#667085] text-sm">
              Update your personal details here.
            </p>
          </div>
          <form onSubmit={formik.handleSubmit} className="form w-full mt-24">
            <div className="form-row-full auth-form-group !mb-16">
              <label htmlFor="email">TIN</label>
              <input
                type="text"
                placeholder="TIN"
                name="tax_identification_number"
                id="tax_identification_number"
                {...formik.getFieldProps("tax_identification_number")}
              ></input>
              {formik.touched.tax_identification_number &&
              formik.errors.tax_identification_number ? (
                <div className="error">
                  {formik.errors.tax_identification_number}
                </div>
              ) : null}
            </div>

            <div className="form-row-half auth-form-group !mb-16">
              <label htmlFor="email">License</label>
              <input
                type="text"
                placeholder="License"
                name="license"
                id="license"
                {...formik.getFieldProps("license")}
              ></input>
              {formik.touched.license && formik.errors.license ? (
                <div className="error">{formik.errors.license}</div>
              ) : null}
            </div>

            <div className="form-row-half auth-form-group !mb-16">
              <label htmlFor="email">License Category</label>
              <Select
                placeholder="Select option"
                variant="filled"
                bg="#EDEDED"
                borderColor="transparent"
                borderRadius="0"
                color="#424242"
                height="60px"
                {...formik.getFieldProps("license_category")}
              >
                {categories?.map((_) => {
                  return (
                    <option key={_?.label} value={_?.value}>
                      {_?.value}
                    </option>
                  );
                })}
              </Select>
              {formik.touched.license_category &&
              formik.errors.license_category ? (
                <div className="error">{formik.errors.license_category}</div>
              ) : null}
            </div>

            <div className="form-row-half auth-form-group !mb-16">
              <label htmlFor="email">Cac Number</label>

              <NumberInput>
                <NumberInputField
                  variant="filled"
                  bg="#EDEDED"
                  borderColor="transparent"
                  borderRadius="0"
                  color="#424242"
                  placeholder="CAC Number"
                  height="60px"
                  {...formik.getFieldProps("cac_number")}
                  // onChange={() => {
                  //   formik.setFieldTouched("cac_number", true);
                  // }}
                />
              </NumberInput>

              {formik.touched.cac_number && formik.errors.cac_number ? (
                <div className="error">{formik.errors.cac_number}</div>
              ) : null}
            </div>

            <div className="auth-form-group basis-[48%]">
              <div className="flex flex-col w-full">
                <label for="lname" className="form-label">
                  Phone Number
                </label>

                <PhoneInput
                  countryCodeEditable={false}
                  country={formik.values?.country}
                  // disableDropdown={true}
                  preferredCountries={["ng"]}
                  onChange={(phone) => {
                    formik.setFieldValue("official_phone", phone);
                  }}
                />

                {formik.errors.official_phone ? (
                  <div className="text-danger mt-2 fs-12">
                    {formik.errors.official_phone}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-row-full auth-form-group !mb-16">
              <label htmlFor="email">Website URL</label>
              <input
                type="text"
                placeholder="Website"
                name="website_url"
                id="website_url"
                {...formik.getFieldProps("website_url")}
              ></input>
              {formik.touched.website_url && formik.errors.website_url ? (
                <div className="error">{formik.errors.website_url}</div>
              ) : null}
            </div>

            <div className="form-row-full flex my-12">
              <button type="submit" className="btn btn-primary w-full">
                {updating ? <Spinner color={Colors.white} /> : "Update"}
              </button>
            </div>
          </form>
        </Stack>
      )}
    </div>
  );
};

export default MerchantProfile;

const UserSchema = Yup.object().shape({
  tax_identification_number: Yup.string().required("TIN is required"),
  license: Yup.string().required("License is required"),
  license_category: Yup.string().required("License Category is required"),
  website_url: Yup.string()
    .required("Website URL is required")
    .url("Invalid URL. Please enter a valid link."),
  cac_number: Yup.string().required("CAC Number is required"),
  official_phone: Yup.string().required("Phone number is required"),
});
