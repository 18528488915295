import { Stack, Flex } from "@chakra-ui/react";
import { capitalize } from "utils/strings";
import { useState } from "react";
import { ReactComponent as ArrowUpIcon } from "icons/arrowUpMD.svg";
import { ReactComponent as ArrowDownIcon } from "icons/arrowDownMD.svg";
import { ReactComponent as CheckIcon } from "icons/greenCheck.svg";
import CreditData from "./creditData";

const CreditCheckTabTwo = ({ active }) => {
  const [analysisInfo, setAnalysisInfo] = useState(false);
  const [analysisAdvice, setAnalysisAdvice] = useState(false);

  return (
    <Stack direction="row" justifyContent="space-between" mt="4">
      <div className="flex flex-col bg-greyh100 overflow-scroll h-[90vh]">
        <Stack mt="40px">
          <CreditData credit={active?.response_data?.credit_data} />
        </Stack>

        <Stack display="none">
          <h3 className="font-inter text-grey800 text-2xl ">
            Credit Check Result
          </h3>
          <p className="font-inter text-grey800 text-base">
          Credit Report for the identification number you provided{" "}
          </p>
          <Stack>
            <section className="mt-40 mb-24 bg-[#F4F4F4] rounded-8 p-16 flex items-center">
              <figure className="w-100 h-100 bg-grey400 opacity-50 rounded-full mr-20"></figure>

              <Stack>
                <h5 className="text-[#0B0A0A] textt-2xl font-medium">
                  {active?.request_data?.idnumber}
                </h5>
                <div className="flex items-center">
                  <p className="text-[#353535] textt-base font-medium pr-10">
                    {active?.request_data?.country}
                  </p>
                  <p className="text-[#353535] textt-base fo nt-medium border-[#0B0A0A] pl-10 border-l">
                    {capitalize(active?.request_data?.check_type)}
                  </p>
                </div>
              </Stack>
            </section>

            <Flex direction={"column"} justifyContent="space-between">
              <section className="basis-[48%] border rounded-8 p-16">
                <Flex wrap="wrap" justifyContent="space-between">
                  <section className="basis-[48%] border rounded-8 p-16 mb-20">
                    <h5 className="font-inter text-sm  font-medium text-[#4F4F4F]">
                      CREDIT SCORE
                    </h5>
                    <div className="flex items-end mt-8">
                      <h1 className="text-primary font-bold text-56 mr-8">
                        {active?.response_data?.credit_score?.score}
                      </h1>
                      <p>/ {active?.response_data?.credit_score?.max_score}</p>
                    </div>
                    <p className="font-inter text-sm  font-medium text-[#4F4F4F]">
                      {active?.response_data?.credit_score?.description}
                    </p>
                  </section>

                  <section className="basis-[48%] border rounded-8 p-16 mb-20">
                    <div className="badge">CREDIT ANALYSIS</div>
                    <p className="font-inter text-sm  font-medium text-[#4F4F4F]">
                      {active?.response_data?.ai_analysis?.description}
                    </p>
                    <div className="flex items-end mt-8">
                      <h1
                        className={`${
                          Number(
                            active?.response_data?.ai_analysis?.confidence
                          ) > 0.49
                            ? "text-red-500"
                            : "text-primary"
                        } font-bold text-56 mr-8`}
                      >
                        {(
                          active?.response_data?.ai_analysis?.confidence * 100
                        )?.toFixed(2)}
                      </h1>
                      <p>%</p>
                    </div>
                    <p className="font-inter text-sm  font-medium text-[#4F4F4F]">
                      Will default :{" "}
                      <span
                        className={
                          Number(
                            active?.response_data?.ai_analysis?.confidence
                          ) > 0.49
                            ? "text-red-500"
                            : "text-primary"
                        }
                      >
                        {Number(
                          active?.response_data?.ai_analysis?.confidence
                        ) > 0.49
                          ? "Yes"
                          : "N0"}
                      </span>
                    </p>
                  </section>
                  
                  <div className="basis-[48%] mt-10">
                    <div className="flex justify-start mt-6 bg-[#EAECF0]">
                      <div className="grow">
                        <label
                          className="px-10 pt-10 text-sm font-urbanist font-bold text-slate-600"
                          for="collapse"
                        >
                          ANALYSIS
                        </label>
                        <p
                          className="font-normal font-inter text-dark-200 text-sm mt-10 text-slate-500 pl-10 pb-10"
                          style={{ lineHeight: "150%" }}
                        >
                          {
                            active?.response_data?.ai_analysis?.info?.analysis
                              ?.description
                          }
                        </p>
                      </div>
                      {!analysisInfo && (
                        <ArrowDownIcon
                          onClick={() => setAnalysisInfo(!analysisInfo)}
                          className="m-10"
                        />
                      )}
                      {analysisInfo && (
                        <ArrowUpIcon
                          onClick={() => setAnalysisInfo(!analysisInfo)}
                          className="m-10"
                        />
                      )}
                    </div>
                    {analysisInfo && (
                      <div
                        className="bg-[#F9FAFB] p-10 pb-20"
                        style={{ borderBottom: "1px solid #EAECF0" }}
                      >
                        <ol>
                          {active?.response_data?.ai_analysis?.info?.analysis?.points?.map(
                            (val, i) => (
                              <li key={i} className="flex">
                                <CheckIcon className="mr-7 mt-8" />
                                <p
                                  className="font-normal font-inter text-slate-500 text-sm mt-10"
                                  style={{ lineHeight: "150%" }}
                                >
                                  {" "}
                                  {` ${val}`}
                                </p>
                              </li>
                            )
                          )}
                        </ol>
                      </div>
                    )}
                  </div>

                  <div className=" basis-[48%] mt-10">
                    <div className="flex justify-start mt-10 bg-[#EAECF0]">
                      <div className="grow">
                        <label
                          className="px-10 py-10 text-sm font-urbanist font-bold text-slate-600"
                          for="collapse"
                        >
                          ADVICE & RECOMMENDATION
                        </label>
                        <p
                          className="font-normal font-inter text-dark-200 text-sm mt-10 text-slate-500 pl-10 pb-10"
                          style={{ lineHeight: "150%" }}
                        >
                          {
                            active?.response_data?.ai_analysis?.info?.advice
                              ?.description
                          }
                        </p>
                      </div>
                      {!analysisAdvice && (
                        <ArrowDownIcon
                          onClick={() => setAnalysisAdvice(!analysisAdvice)}
                          className="m-10"
                        />
                      )}
                      {analysisAdvice && (
                        <ArrowUpIcon
                          onClick={() => setAnalysisAdvice(!analysisAdvice)}
                          className="m-10"
                        />
                      )}
                    </div>
                    {analysisAdvice && (
                      <div
                        className="bg-[#F9FAFB] p-10 pb-20"
                        style={{ borderBottom: "1px solid #EAECF0" }}
                      >
                        <ol>
                          {active?.response_data?.ai_analysis?.info?.advice?.points?.map(
                            (val, i) => (
                              <li key={i} className="flex">
                                <CheckIcon className="mr-7 mt-8" />
                                <p
                                  className="font-normal font-inter text-slate-500 text-sm mt-10"
                                  style={{ lineHeight: "150%" }}
                                >
                                  {" "}
                                  {` ${val}`}
                                </p>
                              </li>
                            )
                          )}
                        </ol>
                      </div>
                    )}
                  </div>
                </Flex>
              </section>
            </Flex>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
};

export default CreditCheckTabTwo;
