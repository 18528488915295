import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import { useState } from "react";
import TableContainer from "components/TableContainer";
import { Skeleton, Stack, useDisclosure } from "@chakra-ui/react";
import AddBlacklist from "./componenents/AddBlacklist";
import { useGetBlacklistQuery } from "store/services/business";
import BlacklistTable from "components/tables/BlacklistTable";

const DashboardBlacklist = () => {
  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetBlacklistQuery(filter, {
    skip: skip,
  });

  const {
    isOpen: isBlacklistOpen,
    onOpen: onBlacklistOpen,
    onClose: onBlacklistClose,
  } = useDisclosure();

  return (
    <>
      <DashboardLayout>
        <section className="mx-flush">
          <h2 className="font-inter font-medium text-black text-2xl">
            Blacklist
          </h2>

          <section className="grid grid-cols-1 gap-16 my-16">
            {fetching || isFetching ? (
              <Stack>
                <Skeleton height="40px" />
                <Skeleton height="80px" />
                <Skeleton height="140px" />
              </Stack>
            ) : (
              <TableContainer
                title="Blacklist"
                count={data?.results?.length}
                handleRoute={() => onBlacklistOpen()}
                showBadge={true}
                minH={true}
              >
                {data?.results?.length > 0 ? (
                  <BlacklistTable blacklist={data?.results} />
                ) : (
                  <section className="flex items-center justify-center min-h-300">
                    <EmptyState />
                  </section>
                )}
              </TableContainer>
            )}
          </section>
        </section>
      </DashboardLayout>
      <AddBlacklist
        isBlacklistOpen={isBlacklistOpen}
        onBlacklistOpen={onBlacklistOpen}
        onBlacklistClose={onBlacklistClose}
      />
    </>
  );
};

export default DashboardBlacklist;
