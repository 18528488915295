import { Fragment } from "react";
import DashboardSidebar from "./components/Sidebar";
import DashboardHeader from "./components/Header";

const DashboardLayout = ({ children }) => {
  return (
    <Fragment>
      <section className="relative bg-border h-screen overflow-hidden flex b">
        <DashboardSidebar />
        <section className="content flex h-full bg-border">
          <section className="hidden xlg:block w-72 h-full flex-shrink-0"></section>
          <section className="h-screen flex-grow bg-border relative overflow-hidden">
            <DashboardHeader />
            <section className="overflow-x-hidden dashboard--content relative bg-grey500">
              {children}
            </section>
          </section>
        </section>
      </section>
    </Fragment>
  );
};

export default DashboardLayout;
