import { Suspense } from "react";
import { Path } from "utils/constant.data";
import { Route, Routes, Navigate } from "react-router-dom";
import { GuestRoute, AuthUserRoute } from "config/auth";
import { AuthUserRoutes, PublicRoutes } from "utils/routes";
import { Toaster } from "react-hot-toast";
import { getLocalStorage } from "utils/secure";
import SetPackage from "pages/auth/Package";

// import PageNotFound from "views/general/404";

const App = () => {

  const user = localStorage.getItem("UTMUSER")
  ? getLocalStorage("UTMUSER")
  : null;

  return (
    <> 
      <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
          }}
        />
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Routes>
          <Route path="/auth/set-package" element={<SetPackage />} />

            <Route element={<GuestRoute user={user} />}>
              <Route path="/" element={<Navigate replace to={Path.LOGIN} />} />
              {PublicRoutes.map((_data) => (
                <Route
                  key={_data.title}
                  exact
                  path={_data.path}
                  element={_data.element}
                />
              ))}
            </Route>

            <Route element={<AuthUserRoute user={user} />}>
              {AuthUserRoutes.map((data) => (
                <Route
                  key={data.title}
                  exact
                  path={data.path}
                  element={data.element}
                />
              ))}
            </Route>
            {/* <Route path="/" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} /> */}
          </Routes>
        </Suspense>
    </>
  );
};

export default App;
