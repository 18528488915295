/** @format */
import axios from "axios";
import { getLocalStorage } from "utils/secure";
// import { Path } from "utils/constant.data";

const user = localStorage.getItem("UTMUSER")
? getLocalStorage("UTMUSER")
: null;
const accessToken =  user?.access;

var headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${accessToken}`,
};

const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: headers,
});

axios_instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        window.localStorage.removeItem("UTAT");
        window.localStorage.removeItem("UTMUSER");
        // const redirectState = { from: window.location.pathname};
        // window.history.replaceState(redirectState, '',Path.LOGIN);
        // window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default axios_instance;
