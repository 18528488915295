import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  useToast,
  Spinner,
  Select,
} from "@chakra-ui/react";
import BlurOverlay from "layouts/components/BlurOverlay";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Colors } from "utils/constant.data";
import { permissions } from "utils/strings";
import { useAddMemberMutation } from "store/services/team";

const AddMember = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [addMember, { isLoading }] = useAddMemberMutation();

  const handleSubmit = async (values, { resetForm }) => {
    await addMember({
      ...values,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: "Success",
          description: "Team Member Added",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        resetForm();
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      permission: "",
    },
    validationSchema: TeamSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
    >
      <BlurOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="0">
          <Stack padding="20px" borderBottom="1px solid #E0E0E0">
            <h2 className="font-inter font-semibold text-h6">Add Member</h2>
          </Stack>
          <Stack padding="20px">
            <div className="flex items-center mb-24">
              <form
                onSubmit={formik.handleSubmit}
                className={`form w-full mt-24 ${isLoading ? "disabled" : ""}`}
              >
                <div className="auth-form-group basis-full">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    aria-describedby="Lastname"
                    placeholder="Team member email"
                    {...formik.getFieldProps("email")}
                  ></input>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div className="form-row-full auth-form-group !mb-16">
                  <label htmlFor="email">Permission</label>
                  <Select
                    placeholder="Select option"
                    variant="filled"
                    bg="#EDEDED"
                    borderColor="transparent"
                    borderRadius="0"
                    color="#424242"
                    height="60px"
                    {...formik.getFieldProps("permission")}
                  >
                    {permissions?.map((_) => {
                      return (
                        <option key={_?.label} value={_?.value}>
                          {_?.label}
                        </option>
                      );
                    })}
                  </Select>
                  {formik.touched.permission && formik.errors.permission ? (
                    <div className="error">{formik.errors.permission}</div>
                  ) : null}
                </div>

                <div className="form-row-half flex my-12"></div>
                <div className="form-row-half flex my-12">
                  <button type="submit" className={`btn btn-primary w-full`}>
                    {isLoading ? <Spinner color={Colors.white} /> : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddMember;

const freeDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "zoho.com",
  "yandex.com",
  "protonmail.com",
];

Yup.addMethod(Yup.string, "officialEmail", function () {
  return this.test(
    "is-official-email",
    "Please enter a valid official email address",
    function (value) {
      if (!value) return true;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) return false;
      const domain = value.split("@")[1].toLowerCase();
      return !freeDomains.includes(domain);
    }
  );
});

const TeamSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required").officialEmail(),
  permission: Yup.string().required("Permission is required"),
});
