import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import BlurOverlay from "layouts/components/BlurOverlay";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NumberInput, NumberInputField } from "@chakra-ui/react";
import { useFundWalletMutation } from "store/services/loan";
import { Colors } from "utils/constant.data";

const FundWallet = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [fundWallet, { isLoading: funding }] = useFundWalletMutation();


  // success : http://localhost:3000/dashboard/wallet/funding/sucesss?trxref=qodyqc1nz3&reference=qodyqc1nz3
  // failure: http://localhost:3000/dashboard/wallet/funding/sucesss?trxref=qodyqc1nz3&reference=qodyqc1nz3

  const handleSubmit = async (values, { resetForm }) => {
    await fundWallet({
      ...values,
      // callback_url: "http://localhost:3000/dashboard/wallet/funding/sucesss",
      callback_url: "https://dev.useclars.com/dashboard/wallet/funding/sucesss"
    })
      .unwrap()
      .then((data) => {
        toast({
          title: "Success",
          description: "Funding Initated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        window.open(data?.data?.url, "_blank");
        resetForm();
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: CreditSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
    >
      <BlurOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="0">
          <Stack padding="20px" borderBottom="1px solid #E0E0E0">
            <h2 className="font-inter font-semibold text-h6">Fund Wallet</h2>
          </Stack>
          <Stack padding="20px">
            <div className="flex items-center mb-24">
              <form
                onSubmit={formik.handleSubmit}
                className={`form w-full mt-24 ${funding ? "disabled" : ""}`}
              >
                <div className="form-row-full auth-form-group !mb-16">
                  <label htmlFor="email">Amount</label>
                  <NumberInput>
                    <NumberInputField
                      {...formik.getFieldProps("amount")}
                      variant="filled"
                      bg="#EDEDED"
                      borderColor="transparent"
                      borderRadius="0"
                      color="#424242"
                      placeholder="Amount"
                      height="60px"
                    />
                  </NumberInput>
                  {formik.touched.amount && formik.errors.amount ? (
                    <div className="error">{formik.errors.amount}</div>
                  ) : null}
                </div>

                <div className="form-row-half flex my-12"></div>
                <div className="form-row-half flex my-12">
                  <button type="submit" className={`btn btn-primary w-full`}>
                    {funding ? <Spinner color={Colors.white} /> : "Top Up"}
                  </button>
                </div>
              </form>
            </div>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FundWallet;

const CreditSchema = Yup.object().shape({
  amount: Yup.string().required("Amount is required"),
});
