/* eslint-disable no-unused-vars */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Select,
  useToast,
  Spinner,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useCreateSweepSettingsMutation,
  useGetAllBankAccountQuery,
} from "store/services/business";
import React from "react";

const SweepSettings = ({ isOpen, onClose }) => {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );
  const toast = useToast();
  const [createSweepSettings, { isLoading: creating }] =
    useCreateSweepSettingsMutation();
  const { data: banks } = useGetAllBankAccountQuery();

  const handleSubmit = async (values, { resetForm }) => {
    await createSweepSettings({
      ...values,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        resetForm();
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      duration: "",
      auto_sweep: "",
      bank_account: "",
    },
    validationSchema: LoanSchema,
    onSubmit: handleSubmit,
    enableReinitialize: false,
  });

  const renderTextField = (
    label,
    key,
    placeholder = "Your value",
    type = "text",
    size = "half"
  ) => {
    return (
      <div
        className={`auth-form-group ${
          size === "half" ? "basis-[48%]" : "basis-full"
        }`}
      >
        <label htmlFor={key}>{label}</label>
        <input
          type={type}
          id={key}
          aria-describedby={key}
          placeholder={placeholder}
          {...formik.getFieldProps(key)}
          className="h-60"
        ></input>
        {formik.touched[key] && formik.errors[key] ? (
          <div className="error">{formik.errors[key]}</div>
        ) : null}
      </div>
    );
  };

  const renderNumberField = (
    label,
    key,
    placeholder = "Your value",
    size = "half"
  ) => {
    return (
      <div
        className={`auth-form-group ${
          size === "half" ? "basis-[48%]" : "basis-full"
        }`}
      >
        <label htmlFor={key}>{label}</label>
        <NumberInput>
          <NumberInputField
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            placeholder={placeholder}
            height="60px"
            {...formik.getFieldProps(key)}
          />
        </NumberInput>
        {formik.touched[key] && formik.errors[key] ? (
          <div className="error">{formik.errors[key]}</div>
        ) : null}
      </div>
    );
  };

  const SelectField = React.memo(
    ({
      label,
      keyProp,
      placeholder = "Your value",
      options = [],
      size = "half",
      formik,
    }) => {
      return (
        <div
          className={`${
            size === "half" ? "basis-[48%]" : "basis-full"
          } auth-form-group`}
        >
          <label htmlFor={keyProp}>{label}</label>
          <Select
            placeholder={placeholder}
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps(keyProp)}
          >
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {formik.touched[keyProp] && formik.errors[keyProp] ? (
            <div className="error">{formik.errors[keyProp]}</div>
          ) : null}
        </div>
      );
    }
  );

  const mapBankList =
    banks?.results?.map((bank) => {
      return {
        label: bank.bank,
        value: bank.id,
      };
    }) ?? [];

  const list = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
    >
      <OverlayOne />
      <ModalContent w="100%">
        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
          <div className="flex flex-col py-22 px-30">
            <h3 className="font-inter font-medium text-black text-2xl">
              Add Bank Account
            </h3>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0">
          <Stack padding="0">
            <form
              onSubmit={formik.handleSubmit}
              className="form !w-full mt-24  px-24"
            >
              <SelectField
                label="Bank Account"
                keyProp="bank_account"
                formik={formik}
                options={mapBankList}
                placeholder="Bank Account"
              />

              <SelectField
                label="Auto Sweep"
                keyProp="auto_sweep"
                formik={formik}
                options={list}
              />

              {renderNumberField(
                "Duration(In Days)",
                "duration",
                "Duration in days",
                "full"
              )}

              <div className="flex my-12 basis-[100%] pb-16">
                <button type="submit" className="btn btn-primary w-full">
                  {creating ? <Spinner color={Colors.white} /> : "Add"}
                </button>
              </div>
            </form>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SweepSettings;

const LoanSchema = Yup.object().shape({
  duration: Yup.string().required("Duration is required"),
  auto_sweep: Yup.string().required("Auto Sweep is required"),
  bank_account: Yup.string().required("Bank Account is required"),
});
