/* eslint-disable no-unused-vars */
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Select,
  useToast,
  Spinner,
  NumberInput,
  NumberInputField,
  HStack,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAddBlacklistMutation } from "store/services/business";
import { bankList, categoryTypes, countries } from "utils/strings";
import { getBankByCode } from "utils/strings";
import React, { useState } from "react";
import axios_instance from "store/constant/axiosInstance";
import BlurOverlay from "layouts/components/BlurOverlay";

const AddBlacklist = ({ isBlacklistOpen, onBlacklistClose }) => {
 
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const [tab, setActive] = useState("single");
  const [loading, setLoading] = useState(null);
  const [addBlacklist, { isLoading: creating }] = useAddBlacklistMutation();

  const handleSubmit = async (values, { resetForm }) => {
    await addBlacklist({
      ...values,
      bank_code: values?.bank,
      bank: getBankByCode(values?.bank),
    })
      .unwrap()
      .then((data) => {
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        resetForm();
        onBlacklistClose();
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      bank: "",
      account_number: "",
      bvn: "",
      email: "",
      phone: "",
      international_passport: "",
      drivers_license: "",
      nin: "",
      social_handle: "",
      category: "",
      description: "",
      country: "",

      // bank: "423",
      // account_number: "4436614019",
      // bvn: "222222222896",
      // email: "test@test.com",
      // phone: "08173645372",
      // international_passport: "B038j39jf",
      // drivers_license: "KA0397JIK9873",
      // nin: "22222222222",
      // social_handle: "testhandler",
      // category: "debtor",
      // description: "this is just description",
      // country: "NG",
    },
    validationSchema: LoanSchema,
    onSubmit: handleSubmit,
    enableReinitialize: false,
  });

  const renderTextField = (
    label,
    key,
    placeholder = "Your value",
    type = "text",
    size = "half",
    required = false
  ) => {
    return (
      <div
        className={`auth-form-group ${
          size === "half" ? "basis-[48%]" : "basis-full"
        }`}
      >
        <label htmlFor={key}>
          {label}
          {required && <span className="text-red-700">*</span>}
        </label>
        <input
          type={type}
          id={key}
          aria-describedby={key}
          placeholder={placeholder}
          {...formik.getFieldProps(key)}
          className="h-60"
        ></input>
        {formik.touched[key] && formik.errors[key] ? (
          <div className="error">{formik.errors[key]}</div>
        ) : null}
      </div>
    );
  };

  const renderNumberField = (
    label,
    key,
    placeholder = "Your value",
    size = "half",
    required = false
  ) => {
    return (
      <div
        className={`auth-form-group ${
          size === "half" ? "basis-[48%]" : "basis-full"
        }`}
      >
        <label htmlFor={key}>
          {label} {required && <span className="text-red-700">*</span>}
        </label>
        <NumberInput>
          <NumberInputField
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            placeholder={placeholder}
            height="60px"
            {...formik.getFieldProps(key)}
          />
        </NumberInput>
        {formik.touched[key] && formik.errors[key] ? (
          <div className="error">{formik.errors[key]}</div>
        ) : null}
      </div>
    );
  };

  const SelectField = React.memo(
    ({
      label,
      keyProp,
      placeholder = "Your value",
      options = [],
      size = "half",
      formik,
      required = false,
    }) => {
      return (
        <div
          className={`${
            size === "half" ? "basis-[48%]" : "basis-full"
          } auth-form-group`}
        >
          <label htmlFor={keyProp}>
            {label}
            {required && <span className="text-red-700">*</span>}
          </label>
          <Select
            placeholder={placeholder}
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps(keyProp)}
          >
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {formik.touched[keyProp] && formik.errors[keyProp] ? (
            <div className="error">{formik.errors[keyProp]}</div>
          ) : null}
        </div>
      );
    }
  );

  const mapBankList = bankList.map((bank) => {
    return {
      label: bank.bank,
      value: bank.bank_code,
    };
  });

  const list = countries.map((bank) => {
    return {
      label: bank.country,
      value: bank.code,
    };
  });

  const handleDownload = () => {
    const downloadUrl = process.env.PUBLIC_URL + "/generated_excel_file.xlsx";
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "blaklist_template.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast({
      title: "Success",
      description: `✔️ Sample file downloaded!`,
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];

    if (file) {
      const fileType = file.type;
      if (allowedFileTypes.includes(fileType)) {
        setSelectedFile(file);
      } else if (file.size > 10 * 1024 * 1024) {
        toast({
          title: "Error Occured",
          description: "❌ File size exceeds the 2MB limit.!",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setSelectedFile(null);
      } else {
        toast({
          title: "Error Occured",
          description: "❌ Only xls files are allowed. !",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setSelectedFile(null);
      }
    }
  };

  const handleBulk = async () => {
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      // formData.append("name", generateRandomString() + selectedFile.name);
      formData.append("file", selectedFile);
      try {
        await axios_instance.post(`blacklist/bulk_upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast({
          title: "Upload Succesful",
          description: `✔️ Bulk-blacklist uploaded!`,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
        onBlacklistClose()
      } catch (error) {
        toast({
          title: "Error Occured",
          description: "❌ Error uploading file. !",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
      }
    } else {
      toast({
        title: "Error Occured",
        description: "❌ Error uploading file. !",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isBlacklistOpen}
      onClose={onBlacklistClose}
      isCentered={false}
    >
      <BlurOverlay />
      <ModalContent w="100%">
        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
          <div className="flex flex-col py-22 px-30">
            <h3 className="font-inter font-medium text-black text-2xl">
              Add to Blacklist
            </h3>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding="0">
          <HStack p="24px" justifyContent="space-between">
            <button
              onClick={() => setActive("single")}
              className={`btn ${
                tab === "single" ? "btn-primary" : "btn-grey"
              }  !basis-[48%]`}
            >
              {" "}
              Single Upload
            </button>
            <button
              onClick={() => setActive("bulk")}
              className={`btn ${
                tab === "bulk" ? "btn-primary" : "btn-grey"
              }  !basis-[48%]`}
            >
              Bulk Upload
            </button>
          </HStack>

          {tab === "single" && (
            <Stack padding="0">
              <form
                onSubmit={formik.handleSubmit}
                className="form !w-full mt-24  px-24"
              >
                {renderNumberField("BVN", "bvn", "Your BVN", "half", true)}
                {renderNumberField(
                  "Phone",
                  "phone",
                  "Phone Number",
                  "half",
                  true
                )}

                <SelectField
                  label="Category"
                  keyProp="category"
                  formik={formik}
                  options={categoryTypes}
                  required={true}
                />

                <SelectField
                  label="Bank"
                  keyProp="bank"
                  formik={formik}
                  options={mapBankList}
                />
                {renderNumberField(
                  "Account Number",
                  "account_number",
                  "Account Number"
                )}

                {renderTextField("Email", "email", "Your Email", "email")}

                {renderTextField(
                  "International Passport",
                  "international_passport",
                  "International Passport Number"
                )}

                {renderTextField(
                  "Driver's License",
                  "drivers_license",
                  "Driver's License"
                )}
                {renderNumberField("NIN", "nin", "NIN Number")}

                {renderTextField(
                  "Socail Handles",
                  "social_handle",
                  "Social handles"
                )}

                {renderTextField(
                  "Description",
                  "description",
                  "Description",
                  "text",
                  "half",
                  true
                )}

                <SelectField
                  label="Country"
                  keyProp="country"
                  formik={formik}
                  options={list}
                  required={true}
                />

                <div className="flex my-12 basis-[100%]">
                  <button type="submit" className="btn btn-primary w-full">
                    {creating ? <Spinner color={Colors.white} /> : "Add"}
                  </button>
                </div>
              </form>
            </Stack>
          )}

          {tab === "bulk" && (
            <Stack padding="24px">
              <div className="flex flex-col form-row-full auth-form-group mb-3">
                <label
                  htmlFor="progressFile"
                  className="!text-base !font-medium !mb-8"
                >
                  Upload Blacklist Sheet (Excel)
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="progressFile"
                  accept=".xls, .xlsx, .csv"
                  onChange={handleFileChange}
                />

                {selectedFile && (
                  <div className="mb-3">
                    <label htmlFor="progressFile" className="form-label">
                      Selected File : {selectedFile?.name}
                    </label>
                  </div>
                )}

                <div className="mt-20 flex justify-between">
                  <button
                    type="button"
                    className="btn btn-grey basis-[48%]"
                    onClick={handleDownload}
                  >
                    Download Sample
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary  basis-[48%]"
                    disabled={selectedFile ? false : true}
                    onClick={() => handleBulk()}
                  >
                    {loading ? (
                      <Spinner color={Colors.white} />
                    ) : (
                      "Add Bulk Blacklist"
                    )}
                  </button>
                </div>
              </div>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddBlacklist;

const LoanSchema = Yup.object().shape({
  // email: Yup.string().required("Your Emailis required"),
  // bvn: Yup.string().required("Your BVN is required"),
  // bank: Yup.string().required("Your Bank is required"),
  // account_number: Yup.string().required("Your Account Number is required"),
  // phone: Yup.string().required("Your Phone number is required"),
  // international_passport: Yup.string().required("Your Passport is required"),
  // drivers_license: Yup.string().required("Your license is required"),
  // nin: Yup.string().required("Your NIN number is required"),
  // social_handle: Yup.string().required("Your social handles are required"),
  category: Yup.string().required("Category is required"),
  description: Yup.string().required("Your description is required"),
  country: Yup.string().required("Country is required"),
});

// const NumberField = React.memo(
//   ({ label, keyProp, placeholder = "Your value", size = "half" }) => {
//     return (
//       <div
//         className={`auth-form-group ${
//           size === "half" ? "basis-[48%]" : "basis-full"
//         }`}
//       >
//         <label htmlFor={keyProp}>{label}</label>
//         <NumberInput>
//           <NumberInputField
//             variant="filled"
//             bg="#EDEDED"
//             borderColor="transparent"
//             borderRadius="0"
//             color="#424242"
//             placeholder={placeholder}
//             height="60px"
//             {...formik.getFieldProps(keyProp)}
//           />
//         </NumberInput>
//         {formik.touched[keyProp] && formik.errors[keyProp] ? (
//           <div className="error">{formik.errors[keyProp]}</div>
//         ) : null}
//       </div>
//     );
//   }
// );

// const TextField = ({
//   label,
//   keyProp,
//   placeholder = "Your value",
//   type = "text",
//   size = "half",
// }) => {
//   return (
//     <div
//       className={`auth-form-group ${
//         size === "half" ? "basis-[48%]" : "basis-full"
//       }`}
//     >
//       <label htmlFor={keyProp}>{label}</label>
//       <input
//         type={type}
//         id={keyProp}
//         aria-describedby={keyProp}
//         placeholder={placeholder}
//         {...formik.getFieldProps(keyProp)}
//         className="h-60"
//       />
//       {formik.touched[keyProp] && formik.errors[keyProp] ? (
//         <div className="error">{formik.errors[keyProp]}</div>
//       ) : null}
//     </div>
//   );
// };
// const SelectField = React.memo(
//   ({
//     label,
//     keyProp,
//     placeholder = "Your value",
//     options = [],
//     size = "half",
//     formik,
//   }) => {
//     return (
//       <div
//         className={`${
//           size === "half" ? "basis-[48%]" : "basis-full"
//         } auth-form-group`}
//       >
//         <label htmlFor={keyProp}>{label}</label>
//         <Select
//           placeholder={placeholder}
//           variant="filled"
//           bg="#EDEDED"
//           borderColor="transparent"
//           borderRadius="0"
//           color="#424242"
//           height="60px"
//           {...formik.getFieldProps(keyProp)}
//         >
//           {options?.map((option) => (
//             <option key={option.value} value={option.value}>
//               {option.label}
//             </option>
//           ))}
//         </Select>
//         {formik.touched[keyProp] && formik.errors[keyProp] ? (
//           <div className="error">{formik.errors[keyProp]}</div>
//         ) : null}
//       </div>
//     );
//   }
// );

// eslint-disable-next-line no-lone-blocks
{
  /* <SelectField
                label="Bank"
                keyProp="bankr"
                formik={formik}
                options={mapBankList}
              />

              <NumberField label="BVN" keyProp="bvn"/>

              <NumberField
                label="Account Number"
                keyProp="account_number"
              />
              <TextField label="Email" keyProp="email"  /> */
}

// Blacklist creation:
//  pleace BVN, phone, category at the top of the list,
//  and make others optional using the "*"
// const renderSelectField = (
//   label,
//   key,
//   placeholder = "Your value",
//   options = [],
//   size = "half"
// ) => {
//   return (
//     <div
//       className={`${
//         size === "half" ? "basis-[48%]" : "basis-full"
//       } auth-form-group`}
//     >
//       <label htmlFor={key}>{label}</label>
//       <Select
//         placeholder={placeholder}
//         variant="filled"
//         bg="#EDEDED"
//         borderColor="transparent"
//         borderRadius="0"
//         color="#424242"
//         height="60px"
//         {...formik.getFieldProps("gender")}
//       >
//         {options?.map((_) => {
//           return <option value={_?.value}>{_?.label}</option>;
//         })}
//       </Select>

//       {formik.touched[key] && formik.errors[key] ? (
//         <div className="error">{formik.errors[key]}</div>
//       ) : null}
//     </div>
//   );
// };
