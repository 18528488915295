import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import LoanCard from "components/LoanCard";
import { useState } from "react";
import TableContainer from "components/TableContainer";
import { Skeleton, Stack, Spinner } from "@chakra-ui/react";
import { useGetAllTransationsQuery } from "store/services/business";
import TransactionsTable from "components/tables/TransactionsTable";
import { useGetTransactionStatsQuery } from "store/services/business";
import { amountConverter } from "utils/strings";

const DashboardTransactions = () => {
  const { data: stats, isLoading } = useGetTransactionStatsQuery();

  const loanData = [
    {
      title: "Transactions Amount",
      number: `₦${amountConverter(stats?.data?.transaction_amount)}`,
      percent: "0",
      showFilter: false,
    },
    {
      title: "Transactions Volume",
      number: "0",
      percent: `₦${amountConverter(stats?.data?.total_volume)}`,
      showFilter: false,
    },
    {
      title: "All Loan Outstanding",
      number: `₦${amountConverter(stats?.data?.loan_outstanding)}`,
      percent: "0",
      showFilter: false,
    },
  ];

  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetAllTransationsQuery(filter, {
    skip: skip,
  });

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">
          Transactions
        </h2>

        {isLoading ? (
          <section className="my-16">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </section>
        ) : (
          <section className="grid grid-cols-3 gap-16 my-16">
            {loanData?.map((_) => {
              return <LoanCard data={_} key={_?.title} />;
            })}
          </section>
        )}

        <section className="grid grid-cols-1 gap-16 my-16">
          {fetching || isFetching ? (
            <Stack>
              <Skeleton height="40px" />
              <Skeleton height="80px" />
              <Skeleton height="140px" />
            </Stack>
          ) : (
            <TableContainer
              title="Transactions"
              count={data?.results?.length}
              handleRoute={() => {}}
              showBadge={true}
              showNew={false}
            >
              {data?.results?.length > 0 ? (
                <TransactionsTable transactions={data?.results} />
              ) : (
                <section className="flex items-center justify-center min-h-300">
                  <EmptyState />
                </section>
              )}
            </TableContainer>
          )}
        </section>
      </section>
    </DashboardLayout>
  );
};

export default DashboardTransactions;
