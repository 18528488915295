/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Stack } from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as MesssageIcon } from "icons/message.svg";
import { ReactComponent as NotificationIcon } from "icons/notification.svg";
import { ReactComponent as CaretIcon } from "icons/caretdown.svg";
import { ReactComponent as SettingsIcon } from "icons/settings.svg";
import { ReactComponent as AccountIcon } from "icons/account.svg";
import { ReactComponent as TeamIcon } from "icons/team.svg";
import { ReactComponent as RightIcon } from "icons/right.svg";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";
import { useDispatch } from "react-redux";
import {
  PopoverContent,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { ReactComponent as OffIcon } from "icons/off.svg";
import { getLocalStorage } from "utils/secure";
import { capitalize } from "utils/strings";
import { logOut } from "store/reducers/AuthReducer";

const DashboardHeader = ({ path }) => {
  const [searchTerm, setSearchTerm] = useState();
  const dispatch = useDispatch();
  const user = localStorage.getItem("UTMUSER")
    ? getLocalStorage("UTMUSER")
    : null;
  const name =
    `${capitalize(user?.first_name)} ${capitalize(user?.last_name)}` ?? "";

  const navigate = useNavigate();

  return (
    <header className="dashboard--header">
      <section className="basis-1/2 ">
        {/* <div className="border-1 border-dark-100 w-325 bg-white relative px-25 py-14 flex items-center">
          <button
            onClick={() => {}}
            className="flex items-center justify-center w-24 cursor-pointer mr-9"
          >
            <SearchIcon />
          </button>
          <input
            placeholder="Search Keyword"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-transparent border-none text-dark100 mt-2  font-graphik flex-grow focus:outline-none focus:border-0 placeholder-black placeholder-opacity-70"
          ></input>
        </div> */}
      </section>
      <section className="basis-1/2  flex items-center justify-end pr-340">
        <div className="border-r-1 border-r-dark-100 flex items-center">
          {/* <button className="dashboard--header--pills">
            <MesssageIcon />
          </button> */}
          <button className="dashboard--header--pills">
            <NotificationIcon />
          </button>
        </div>
        <div className=" flex items-center">
          <figure className="w-38 h-38 rounded-full bg-dark300 ml-10 mr-16"></figure>

          <Popover>
            <PopoverTrigger>
              <button className="flex items-center">
                <span className="text-dark200 text-sm font-bold font-graphik mr-12">
                  {name}
                </span>
                <CaretIcon />
              </button>
            </PopoverTrigger>
            <PopoverContent p="0">
              <PopoverArrow />
              <PopoverCloseButton />
              {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
              <PopoverBody>
                <div className="flex flex-col  font-inter text-dark100 p-0">
                  <div className="flex flex-col border-b border-dark200 p-18">
                    <h2 className="font-inter font-medium text-xl text-dark100">
                      Profile Settings
                    </h2>
                    <button className="flex items-center justify-star font-normal text-12">
                      View Profile
                    </button>
                  </div>

                  <Stack p="18px">
                    <ul className="mb-4 cursor-pointer">
                      <li
                        className="flex items-center mb-12"
                        onClick={() => navigate(Path.DASHBOARD_SETTINGS)}
                      >
                        <SettingsIcon className="mr-16" />
                        <span className="font-inter font-normal text-sm text-dark100">
                          My settings
                        </span>
                      </li>
                      <li
                        className="flex items-center mb-12"
                        onClick={() => navigate(Path.DASHBOARD_SETTINGS)}
                      >
                        <AccountIcon className="mr-16" />
                        <span className="font-inter font-normal text-sm text-dark100">
                          Manage account
                        </span>
                      </li>
                      <li className="flex items-center mb-12">
                        <TeamIcon className="mr-16" />
                        <span className="font-inter font-normal text-sm text-dark100">
                          Invite team member
                        </span>
                      </li>
                    </ul>
                    <button  onClick={() => dispatch(logOut())} className="flex items-center justify-between">
                      <div className="flex items-center">
                        <OffIcon />
                        <span className="ml-8 text-dark200 font-bold text-18">
                          Log Out
                        </span>
                      </div>
                      <RightIcon />
                    </button>
                  </Stack>
                </div>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          {/* <PopoverContent
            top={{ base: "-25px", lg: "-5px" }}
            border="none"
            borderTop="1px solid #cecece"
            w={{ base: "100vw", lg: "450px" }}
            h={{ base: "calc(100vh - 100px)", lg: "initial" }}
            overflowY="scroll"
            pos="relative"
            borderRadius={{ lg: "16px" }}
            bg="white"
            padding="24px"
            className="box-shadow"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex flex-col gap-24 h-full pt-40 lg:pt-0 ">
              <p>Select a flight</p>
            </div>
          </PopoverContent> */}
        </div>
      </section>
    </header>
  );
};

export default DashboardHeader;
