/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import AuthLayout from "layouts/auth";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { ReactComponent as SuccessIcon } from "icons/succes.svg";
import { ReactComponent as MarkIcon } from "icons/mark.svg";
import { ReactComponent as UnMarkIcon } from "icons/unmark.svg";
import { Path } from "utils/constant.data";
import { ReactComponent as EyeIcon } from "icons/eye.svg";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useFinalizeUserEmailMutation } from "store/services/authAPI";
import { useToast, Spinner, HStack } from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { login } from "store/reducers/AuthReducer";
import { Select } from "@chakra-ui/react";
import { riskOnly, riskLoan, Sectors } from "utils/strings";
import axios_instance from "store/constant/axiosInstance";

const ConfirmAccountPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isShown, setIsSHown] = useState(false);
  const [packages, sePackages] = useState([]);
  const [settingPackage, setPackageLoading] = useState(false);
  const [auth, setAuth] = useState(null);
  const [chosen, setChosen] = useState(null);
  const toast = useToast();

  const [otp, setOtp] = useState();
  const [succes, setSuccess] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showPackages, setShow] = useState(false);

  const [finalizeUserEmail, { isLoading: authenticating }] =
    useFinalizeUserEmailMutation();

  const initializedUser = location?.state?.initializedUser ?? null;

  const handleSubmit = async (values) => {
    await finalizeUserEmail({
      ...values,
      phone: `+${values?.phone}`,
      session_id: initializedUser?.session_id,
      otp: Number(otp) ?? null
      // dob: format(values?.dob, "yyyy-MM-dd"),
    })
      .unwrap()
      .then((data) => {
        sePackages([...data?.data?.packages]);
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setAuth({ ...data?.data });
        setConfirmed(true);
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: initializedUser?.email,
      firstname: "",
      lastname: "",
      business_name: "",
      business_sector: "",
      phone: "",
      dob: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

 

  const handleChange = (code) => setOtp(code);

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const handleSetPackage = async () => {
    setPackageLoading(true);
    try {
      const data = await axios_instance.post(
        `merchant/register/setpackage/`,
        {
          package_id: chosen,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token?.access}`,
          },
        }
      );

      toast({
        title: data?.message,
        description: data?.detail,
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      setPackageLoading(false);

      dispatch(
        login({
          ...auth,
          source: "register",
        })
      );
    } catch (err) {
      toast({
        title: "Error Occured",
        description: err?.data?.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      setPackageLoading(false);
    }
  };

  const AccountSuccess = (
    <section className="w-full flex justify-center  flex-col h-full p-178">
      <figure className="mb-24">
        <SuccessIcon />
      </figure>
      <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-24">
        Account created Successfully!
      </h1>
      <h1 className="text-p text-dark200 font-graphik">
        Congratulations! Your account has been created successfully. Get started
        now and unlock a world of possibilities with our platform.
      </h1>
      <div className="flex my-32">
        <button
          type="button"
          onClick={() => {
            setShow(true);
          }}
          className="btn btn-primary w-full"
        >
          Continue
        </button>
      </div>
    </section>
  );

  const AccountForm = (
    <section className="w-fullflex justify-center  flex-col h-full p-160">
      <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-4">
        Start your CLARS Journey
      </h1>
      <h1 className="text-p text-dark200 font-graphik">
        Join the growing network of loan institutions on CLARS and experience
        the benefits of a seamless loan recovery process.
      </h1>

      <form onSubmit={formik.handleSubmit} className="form !w-full mt-24">
        <div className="auth-form-group basis-[100%]">
          <label htmlFor="email">Your email</label>
          <input
            type="email"
            className="disabled"
            id="email"
            aria-describedby="Email"
            placeholder="Enter your Email"
            {...formik.getFieldProps("email")}
          ></input>
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="Dirstname">First Name</label>
          <input
            type="text"
            id="Firstname"
            aria-describedby="Firstname"
            placeholder="Enter your Firstname"
            {...formik.getFieldProps("firstname")}
          ></input>
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="error">{formik.errors.firstname}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Last Name</label>
          <input
            type="text"
            id="Lastname"
            aria-describedby="Lastname"
            placeholder="Enter your Lastname"
            {...formik.getFieldProps("lastname")}
          ></input>
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="error">{formik.errors.lastname}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="Dirstname">Business Name</label>
          <input
            type="text"
            id="business_name"
            aria-describedby="business_name"
            placeholder="Enter your business name"
            {...formik.getFieldProps("business_name")}
          ></input>
          {formik.touched.business_name && formik.errors.business_name ? (
            <div className="error">{formik.errors.business_name}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="email">Business Sector</label>
          {/* <input
            type="text"
            id="business_sector"
            aria-describedby="business_sector"
            placeholder="Enter your business sector"
            {...formik.getFieldProps("business_sector")}
          ></input> */}

          <Select
            placeholder="Choose Type"
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps("business_sector")}
          >
            {Sectors?.map((_) => {
              return <option value={_?.value}>{_?.label}</option>;
            })}
          </Select>
          {formik.touched.business_sector && formik.errors.business_sector ? (
            <div className="error">{formik.errors.business_sector}</div>
          ) : null}
        </div>

        <div className=" auth-form-group basis-[48%]">
          <div className="flex flex-col w-full">
            <label for="lname" className="form-label">
              Phone Number
            </label>

            <PhoneInput
              countryCodeEditable={false}
              country={formik.values?.country}
              // disableDropdown={true}
              preferredCountries={["ng"]}
              onChange={(phone) => {
                formik.setFieldValue("phone", phone);
              }}
            />

            {formik.errors.phone ? (
              <div className="text-danger mt-2 fs-12">
                {formik.errors.phone}
              </div>
            ) : null}
          </div>
        </div>

        {/* <div className="auth-form-group basis-[48%]">
          <label for="lname" className="form-label">
            Date Of Birth
          </label>

          <DatePicker
            selected={chosenDate}
            dateFormat="dd/MM/YYYY"
            icon={<EyeIcon />}
            onChange={(date) => {
              formik.setFieldTouched("dob", true);
              formik.setFieldValue("dob", date);
              setChosenDate(date);
            }}
          />

          {formik.errors.dob ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.dob}
            </div>
          ) : null}
        </div> */}

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="password">Password</label>
          <div className="password__container">
            <input
              type={isShown ? "text" : "password"}
              id="exampleInputEmail1"
              aria-describedby="Password"
              placeholder="Enter your password"
              {...formik.getFieldProps("password")}
            />
            <button type="button" onClick={togglePassword}>
              <div className="icon">
                <EyeIcon />
                {/* {isShown ? <EyeIcon /> : <i className="ti ti-eye fs-4"></i>} */}
              </div>
            </button>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="error">{formik.errors.password}</div>
          ) : null}
        </div>

        <div className="auth-form-group basis-[48%]">
          <label htmlFor="password">Confirm Password</label>
          <div className="password__container">
            <input
              type={isShown ? "text" : "password"}
              id="confirm_password"
              aria-describedby="Password"
              placeholder="Ree-nter your password"
              {...formik.getFieldProps("confirm_password")}
            />
            <button type="button" onClick={togglePassword}>
              <div className="icon">
                <EyeIcon />
              </div>
            </button>
          </div>
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="error">{formik.errors.confirm_password}</div>
          ) : null}
        </div>

        <div className="flex my-12 basis-[100%]">
          <button type="submit" className="btn btn-primary w-full">
            {authenticating ? <Spinner color={Colors.white} /> : "Get Started"}
          </button>
        </div>

        <p className="text-center  font-display text-[#696F79] font-normal text-base mt-40">
          Do you have account?
          <Link
            to={Path.LOGIN}
            className="text-primary pl-6 font-medium"
            href="ff"
          >
            Sign In
          </Link>
        </p>
      </form>
    </section>
  );

  const AccountOtp = (
    <section className="w-full flex justify-center  flex-col h-full p-178">
      <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-4">
        OTP Authentication
      </h1>
      <HStack>
        <h1 className="text-p text-dark200 font-graphik">
          We sent you an OTP authentication code to your email
        </h1>
        {/* <p className="text-purple100 font-bold text-base font-graphik mt-4 ">
        email
      </p> */}
      </HStack>

      <form className="auth__form w-full mt-24">
        <div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<div style={{ marginLeft: "8px" }}></div>}
            renderInput={(props) => <input {...props} />}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid transparent",
              width: "76px",
              height: "76px",
              fontSize: "24px",
              color: "#33B469",
              fontWeight: "800",
              caretColor: "#33B469",
              backgroundColor: "#F5F5F5",
              fontFamily: `"Urbanist, "sans-serif"`,
            }}
            focusStyle={{
              border: "2px solid #33B469",
            }}
            containerStyle="k"
          />
        </div>
        <div className="flex my-32">
          <button
            type="button"
            onClick={() => {
              setSuccess(true);
            }}
            className="btn btn-primary w-full"
          >
            Continue
          </button>
        </div>

        <p className="font-display text-[#696F79] font-normal text-base">
          Didn’t receive the code?
          <Link href="/auth/register">
            <button className="text-purple100 pl-6 font-medium" href="ff">
              Resend Code
            </button>
          </Link>
        </p>
      </form>
    </section>
  );

  const AccountPackage = (
    <section className="w-full flex justify-center  flex-col h-full p-120">
      <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-2">
        Select a package
      </h1>
      <h1 className="text-p text-[#888888] font-graphik">
        Choose the package that best suits your needs
      </h1>

      <section className="flex mt-24 justify-between">
        {packages &&
          packages?.map((_) => {
            return (
              <div key={_?.id} className="package__card">
                <div className="h-50">
                  <h5
                    className={
                      chosen === _?.id
                        ? "font-bold !text-primary"
                        : "font-semibold text-[#010E0E]"
                    }
                  >
                    {_?.name}
                  </h5>
                </div>

                {_?.id === "5d6922d9-2f61-4208-b965-e6fd8fedb33d"
                  ? riskOnly.map((item) => {
                      return (
                        <div key={item?.title} className="flex items-center">
                          {item.mark ? <MarkIcon /> : <UnMarkIcon />}
                          <p>{item?.title}</p>
                        </div>
                      );
                    })
                  : riskLoan.map((_item) => {
                      return (
                        <div key={_item?.title} className="flex items-center">
                          {_item.mark ? <MarkIcon /> : <UnMarkIcon />}
                          <p>{_item?.title}</p>
                        </div>
                      );
                    })}

                <button
                  type="button"
                  onClick={() => {
                    setChosen(_?.id);
                  }}
                  className={`btn btn-primary  w-full mt-24 ${
                    chosen === _?.id ? "btn-disabled" : ""
                  } `}
                >
                  Select
                </button>
              </div>
            );
          })}
      </section>

      <div className="flex my-32">
        <button
          onClick={() => {
            handleSetPackage();
          }}
          className={`btn btn-primary ${chosen ? "" : "btn-disabled"} w-full`}
        >
          {settingPackage ? <Spinner color={Colors.white} /> : " Continue"}
        </button>
      </div>
    </section>
  );

  return (
    <AuthLayout>
      {succes
        ? confirmed
          ? showPackages
            ? AccountPackage
            : AccountSuccess
          : AccountForm
        : AccountOtp}
    </AuthLayout>
  );
};

export default ConfirmAccountPage;

const UserSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  business_name: Yup.string().required("Business Name is required"),
  business_sector: Yup.string().required("Business sector is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm Password is required"),
});
