/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import AuthLayout from "layouts/auth";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { ReactComponent as SuccessIcon } from "icons/succes.svg";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";
import { HStack } from "@chakra-ui/react";

const ConfirmResetPage = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const [otp, setOtp] = useState(344);
  const [succes, setSuccess] = useState(true);
  const [fill] = useState(true);

  const handleChange = (code) => setOtp(code);

  return (
    <AuthLayout>
      {succes ? (
        fill ? (
          <section className="w-full flex justify-center  flex-col h-full p-178">
            <figure className="mb-24">
              <SuccessIcon />
            </figure>
            <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-24">
              Password Reset Successfully!
            </h1>
            <h1 className="text-p text-dark200 font-graphik">
              Your password has been reset successfully, Click the “Continue” to
              login magically into your account.
            </h1>
            <div className="flex my-32">
              <button
                type="button"
                onClick={() => {
                  navigate(Path.LOGIN);
                }}
                className="btn btn-primary w-full"
              >
                Continue
              </button>
            </div>
          </section>
        ) : (
          <section className="w-fullflex justify-center  flex-col h-full p-178">
            <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-4">
              Set new password
            </h1>
            <h1 className="text-p text-dark200 font-graphik">
              Must be less than 8 character
            </h1>

            <form className="auth__form w-full mt-24">
              <div className="auth-form-group">
                <label htmlFor="email">New Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  id="password"
                ></input>
                {/* <p className="error">error</p> */}
              </div>

              <div className="auth-form-group">
                <label htmlFor="email">Confirm Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  id="password"
                ></input>
                {/* <p className="error">error</p> */}
              </div>

              <div className="flex my-12">
                <button
                  type="button"
                  onClick={() => {}}
                  className="btn btn-primary w-full"
                >
                  Login
                </button>
              </div>

              <p className="font-normal font-graphik text-dark200 text-sm mt-24">
                <Link to={Path.FORGOT_PASSWORD} className="text-blue" href="ff">
                  Forgot password?
                </Link>
              </p>

              <p className="text-center  font-display text-[#696F79] font-normal text-base mt-120">
                Don’t you have account?
                <Link
                  to={Path.REGISTER}
                  className="text-primary pl-6 font-medium"
                >
                  Sign Up
                </Link>
              </p>
            </form>
          </section>
        )
      ) : (
        <section className="w-full flex justify-center  flex-col h-full p-178">
          <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-4">
            OTP Authentication
          </h1>
          <HStack>
            <h1 className="text-p text-dark200 font-graphik">
              We sent you an OTP code to reset your password via your email
            </h1>
            {/* <p className="text-purple100 font-bold text-base font-graphik mt-4 ">
             
            </p> */}
          </HStack>

          <form className="auth__form w-full mt-24">
            <div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<div style={{ marginLeft: "8px" }}></div>}
                renderInput={(props) => <input {...props} />}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid transparent",
                  width: "76px",
                  height: "76px",
                  fontSize: "24px",
                  color: "#33B469",
                  fontWeight: "800",
                  caretColor: "#33B469",
                  backgroundColor: "#F5F5F5",
                  fontFamily: `"Urbanist, "sans-serif"`,
                }}
                focusStyle={{
                  border: "2px solid #33B469",
                }}
                containerStyle="k"
              />
            </div>
            <div className="flex my-32">
              <button
                type="button"
                onClick={() => {
                  setSuccess(true);
                }}
                className="btn btn-primary w-full"
              >
                Continue
              </button>
            </div>

            <p className="font-display text-[#696F79] font-normal text-base">
              Didn’t receive the code?
              <Link href="/auth/register">
                <button className="text-purple100 pl-6 font-medium" href="ff">
                  Resend Code
                </button>
              </Link>
            </p>
          </form>
        </section>
      )}
    </AuthLayout>
  );
};

export default ConfirmResetPage;
