import DashboardLayout from "layouts/dashboard";
import { Stack, Switch, HStack, Skeleton } from "@chakra-ui/react";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { ReactComponent as ShieldIcon } from "icons/shield.svg";
import { ReactComponent as ExportIcon } from "icons/export.svg";
import LoanListTable from "./LoanListTable";
import { Badge } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ReactComponent as EyeIcon } from "icons/eye.svg";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { Spinner } from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Path } from "utils/constant.data";
import { useNavigate, useLocation } from "react-router-dom";
import { capitalize } from "utils/strings";
import EmptyState from "components/EmptyState";
import { useGetSingleBeneficiaryQuery } from "store/services/loan";

const LoanList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const [fetch, setFetch] = useState(false);

  useEffect(() => {
    if (!state?.loanDetails) {
      navigate(`${Path.DASHBOARD_LOAN_BENEFICIARY}`);
    }
  }, [navigate, state]);

  const loan = state?.loanDetails ?? null;
  const { data, isLoading, isFetching } = useGetSingleBeneficiaryQuery(
    loan?.id,
    {
      skip: fetch,
    }
  );

  const loanDetails = data?.data ?? null;


  const name =
    `${capitalize(loanDetails?.first_name)} ${capitalize(
      loanDetails?.last_name
    )}` ?? "";

  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );

  const [isShown, setIsSHown] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSweepOpen,
    onOpen: onSweepOpen,
    onClose: onSweepClose,
  } = useDisclosure();

  const handleSubmit = async (values, { resetForm }) => { };

  const formik = useFormik({
    initialValues: {
      country: "",
      check_type: "",
      idnumber: "",
    },
    validationSchema: CreditSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const BankCard = ({ details, bg = "#826AF9" }) => {
    return (
      <Stack bg={bg} p="12px" textColor="white">
        <div className="flex justify-between">
          <div className="flex">
            <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-12"></figure>
            <div className="flex flex-col">
              <h6 className="font-normal text-12 font-inter">Beneficiary</h6>
              <h5 className="font-semibold text-sm font-inter">
                {" "}
                {details.bankname}
              </h5>
            </div>
          </div>
          <div className="flex flex-col  items-end">
            <h6 className="font-semibold text-sm font-inter mb-4">
              Direct Debit
            </h6>
            <div className="flex items-center">
              <p className="mr-4 font-normal text-12 font-inter">
                {" "}
                {details?.is_active ? "ON" : "OFF"}
              </p>
              <Switch size="sm" />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <h6 className="font-normal text-12 font-inter ">Account Number</h6>
          <h5 className="font-bold text-2xl font-urbanist">
            {details.accountnumber}
          </h5>
        </div>
      </Stack>
    );
  };

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const details = [
    {
      label: "First Name",
      value: loanDetails?.first_name,
    },
    {
      label: "Last Name",
      value: loanDetails?.last_name,
    },

    {
      label: "Gender",
      value: loanDetails?.gender ?? "Male",
    },
    {
      label: "Date of Birth",
      value: loanDetails?.dob,
    },
    {
      label: "BVN",
      value: loanDetails?.bvn,
    },
    {
      label: "Status",
      value: loanDetails?.is_active ? "Active" : "Not Active",
      badge: true,
    },
  ];

  useEffect(() => {
    if (loanDetails) {
      setFetch(true);
    }
  }, [loanDetails]);

  return (
    <DashboardLayout>
      <section className="mx-flush">
        {loanDetails && (
          <h2 className="font-inter font-medium text-black text-2xl">
            {name}’s Loans
          </h2>
        )}

        {isLoading || isFetching ? (
          <Stack w="100%" minH="20rem">
            <Skeleton height="120px" />
            <Skeleton height="130px" />
            <Skeleton height="140px" />
          </Stack>
        ) : (
          loanDetails && (
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing="24px"
              marginTop="24px"
            >
              <Stack w="30%" h="40rem" bg="white" padding="28px">
                <Stack w="100%">
                  <Stack>
                    <button
                      onClick={() => navigate(-1)}
                      className="w-250 flex items-center  text-grey700"
                    >
                      {" "}
                      <BackIcon />
                      <span className="ml-8">Back to Overview</span>
                    </button>
                    <h2 className="font-inter font-semibold text-h6">
                      {name}’s Profile
                    </h2>
                  </Stack>
                  <Stack justifyContent="center" alignItems="center" mt="24px">
                    <figure className="w-78 h-78 rounded-full bg-slate-500 mb-10"></figure>
                    <h5 className="font-inter font-semibold text-h6 text-blue200">
                      {name}
                    </h5>
                    <h6 className="font-inter font-semibold text-12 text-[#425466]">
                      <span className="text-[#75818D] text-12">User ID:</span> #{" "}
                      {loanDetails?.id?.substring(0, 6)}
                    </h6>
                  </Stack>
                  <Stack mt="24px">
                    {/* <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                  <h6 className="font-inter font-normal text-sm">
                    Email Address
                  </h6>
                  <h6 className="font-inter font-normal text-sm">
                    {loanDetails?.email ? loanDetails?.email : "Null"}
                  </h6>
                </div>
                <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                  <h6 className="font-inter font-normal text-sm">
                    Phone number
                  </h6>
                  <h6 className="font-inter font-normal text-sm">
                    {user?.phone}
                  </h6>
                </div> */}

                    {details?.map((_) => {
                      return (
                        <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                          <h6 className="font-inter font-normal text-sm text-grey950">
                            {_?.label}
                          </h6>
                          <h6 className="font-inter font-normal text-sm text-[#181830]">
                            {_?.value}
                          </h6>
                        </div>
                      );
                    })}
                  </Stack>
                </Stack>
              </Stack>

              <Stack w="70%" bg="white" padding="28px">
                <div className="flex items-center justify-between mb-17">
                  <HStack flexBasis="40%" justifyContent="flex-start">
                    <h5>
                      Loan Beneficiary
                      <Badge
                        marginLeft="8px"
                        backgroundColor="#F9F5FF"
                        borderRadius="16px"
                        padding="8px"
                        color="#6941C6"
                      >
                        {loanDetails?.mandate?.length} Beneficiary
                      </Badge>
                    </h5>
                  </HStack>

                  {loanDetails?.mandate?.length > 0 && (
                    <div className="basis-1/2 flex-shrink-0 flex items-center justify-end">
                      <button
                        onClick={() => onSweepOpen()}
                        className="btn btn-primary !px-20 mr-16"
                      >
                        Sweep Account
                      </button>
                      <button
                        onClick={() => onOpen()}
                        className="btn btn-primary !px-20 !flex !items-center "
                      >
                        Export <ExportIcon className="ml-8" />
                      </button>
                    </div>
                  )}
                </div>

                <Stack w="100%" minH="20rem">
                  {loanDetails?.mandates?.length > 0 ? (
                    <section className="grid grid-cols-3 gap-16 my-16">
                      {loanDetails?.mandates?.map((_) => {
                        return <BankCard details={_} />;
                      })}
                    </section>
                  ) : (
                    <section className="grid grid-cols-1 gap-16 my-16">
                      <EmptyState title="No Mandate" />
                    </section>
                  )}

                  {/* <BankCard bg="#004085" /> */}

                  <LoanListTable
                    setFetch={setFetch}
                    loanDetails={loanDetails}
                  />
                </Stack>
              </Stack>
            </Stack>
          )
        )}

        <Modal
          closeOnOverlayClick={false}
          size="xl"
          isOpen={isSweepOpen}
          onClose={onSweepClose}
          isCentered={true}
        >
          <OverlayOne />
          <ModalContent>
            <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
              <div className="flex flex-col py-22 px-30">
                <h3 className="font-inter font-medium text-black text-2xl">
                  Authorize Automatic Sweep
                </h3>
              </div>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className=" flex items-center mb-24">
                <form
                  onSubmit={formik.handleSubmit}
                  className={`form w-full mt-24 ${true ? "disabled" : ""}`}
                >
                  <div className="form-row-full auth-form-group !mb-16 !flex !items-center !justify-center">
                    <figure className="bg-grey500 w-180 h-180 rounded-full flex items-center justify-center">
                      <ShieldIcon />
                    </figure>
                  </div>
                  <div className="form-row-full auth-form-group !mb-16">
                    <label htmlFor="password">Password</label>
                    <div className="password__container">
                      <input
                        type={isShown ? "text" : "password"}
                        id="exampleInputEmail1"
                        aria-describedby="Password"
                        placeholder="Enter your password"
                        {...formik.getFieldProps("password")}
                      />
                      <button type="button" onClick={togglePassword}>
                        <div className="icon">
                          <EyeIcon />
                          {/* {isShown ? <EyeIcon /> : <i className="ti ti-eye fs-4"></i>} */}
                        </div>
                      </button>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="error">{formik.errors.password}</div>
                    ) : null}
                  </div>

                  <div className="form-row-full flex my-12">
                    <button type="submit" className={`btn btn-primary w-full`}>
                      {!true ? <Spinner color={Colors.white} /> : "Continue"}
                    </button>
                  </div>
                </form>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          closeOnOverlayClick={false}
          size="xl"
          isOpen={isOpen}
          onClose={onClose}
          isCentered={true}
        >
          <OverlayOne />
          <ModalContent w="100%" maxW="760px">
            <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
              <div className="flex flex-col py-22 px-30">
                <h3 className="font-inter font-normal text-black text-h6">
                  Loan Number L12345
                </h3>
              </div>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack pb="32px">
                <section className="grid grid-cols-4 gap-16 my-16">
                  <Stack gap="0" mb="10px">
                    <h5 className="text-dark500 font-normal font-inter  text-12">
                      Total Loan
                    </h5>
                    <h5 className="text-dark500 font-bold text-h4">
                      ₦550,000.00
                    </h5>
                  </Stack>
                  <Stack gap="0" mb="10px">
                    <h5 className="text-dark500 font-normal font-inter  text-12">
                      Total Loan
                    </h5>
                    <h5 className="text-dark500 font-bold text-h4">
                      ₦550,000.00
                    </h5>
                  </Stack>
                  <Stack gap="0" mb="10px">
                    <h5 className="text-dark500 font-normal font-inter  text-12">
                      Total Loan
                    </h5>
                    <h5 className="text-dark500 font-bold text-h4">
                      ₦550,000.00
                    </h5>
                  </Stack>
                  <Stack gap="0" mb="10px">
                    <h5 className="text-dark500 font-normal font-inter  text-12">
                      Total Loan
                    </h5>
                    <h5 className="text-dark500 font-bold text-h4">
                      ₦550,000.00
                    </h5>
                  </Stack>
                </section>

                <h5 className="text-dark500 font-normal font-inter  text-12">
                  Loan Swept Form
                </h5>

                <section className="grid grid-cols-3 gap-16 my-16">
                  <Stack gap="0" mb="10px" bg={Colors.grey500} padding="22px">
                    <HStack mb="24px">
                      <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-6"></figure>

                      <Stack gap="0" mb="10px">
                        <h6 className="font-normal text-12 font-inter">
                          Beneficiary
                        </h6>
                        <h5 className="font-semibold text-sm font-inter">
                          Union Bank
                        </h5>
                      </Stack>
                    </HStack>

                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Account Amount
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦550,000.00
                      </h5>
                    </Stack>

                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Swept Amount
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦550,000.00
                      </h5>
                    </Stack>
                  </Stack>
                  <Stack gap="0" mb="10px" bg={Colors.grey500} padding="22px">
                    <HStack mb="24px">
                      <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-6"></figure>

                      <Stack gap="0" mb="10px">
                        <h6 className="font-normal text-12 font-inter">
                          Beneficiary
                        </h6>
                        <h5 className="font-semibold text-sm font-inter">
                          Union Bank
                        </h5>
                      </Stack>
                    </HStack>

                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Account Amount
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦550,000.00
                      </h5>
                    </Stack>

                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Swept Amount
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦550,000.00
                      </h5>
                    </Stack>
                  </Stack>
                  <Stack gap="0" mb="10px" bg={Colors.grey500} padding="22px">
                    <HStack mb="24px">
                      <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-6"></figure>

                      <Stack gap="0" mb="10px">
                        <h6 className="font-normal text-12 font-inter">
                          Beneficiary
                        </h6>
                        <h5 className="font-semibold text-sm font-inter">
                          Union Bank
                        </h5>
                      </Stack>
                    </HStack>

                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Account Amount
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦550,000.00
                      </h5>
                    </Stack>

                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Swept Amount
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦550,000.00
                      </h5>
                    </Stack>
                  </Stack>
                </section>
                <section className="flex items-center justify-between">
                  <button
                    type="button"
                    onClick={() => {
                      onClose();
                    }}
                    className="btn btn-grey !w-[48%]"
                  >
                    Close
                  </button>
                  <button
                    onClick={() => {
                      onClose();
                    }}
                    className="btn btn-primary !w-[48%] flex items-center justify-center"
                  >
                    Export
                  </button>
                </section>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </section>
    </DashboardLayout>
  );
};

export default LoanList;

const CreditSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  check_type: Yup.string().required("Check type is required"),
  idnumber: Yup.string().required("ID is required"),
});
