import {
  Select,
  NumberInput,
  NumberInputField,
  Skeleton,
  Stack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Colors } from "utils/constant.data";
import {
  useGetMerchantProfileQuery,
  useUpdateMerchantProfileMutation,
} from "store/services/business";

const StepOne = ({ setStep }) => {
  const { data, isLoading } = useGetMerchantProfileQuery();
  const [updateMerchantProfile, { isLoading: updating }] =
    useUpdateMerchantProfileMutation();
  const toast = useToast();
  const profile = data?.data ?? null;


  //   address: ""
  // auto_sweep: false
  // cac_number: ""
  // company_logo: ""
  // country: ""
  // created_at: "2024-07-09T11:18:28.085008Z"
  // currency: "NGN"
  // id: "5323855c-7a01-47f5-b18d-4ace10ef915a"
  // is_active: true
  // license: ""
  // license_category: ""
  // name: "ola"
  // official_email: "blushadadlisne@fthcapital.com"
  // official_phone: "+2348123434567"
  // sector: "ola"
  // tax_identification_number: ""
  // updated_at: "2024-07-09T11:18:28.084979Z"
  // verified: false
  // wallet_balance: "0.00"
  // website_url: ""

  const handleSubmit = async (values) => {
    await updateMerchantProfile({
      ...values,
      official_phone: `+${values?.official_phone}`,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        setStep(2);
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      tax_identification_number: profile?.tax_identification_number ?? "",
      license: profile?.license ?? "",
      license_category: profile?.license_category ?? "",
      website_url: profile?.website_url ?? "",
      cac_number: profile?.cac_number ?? "",
      official_phone: profile?.official_phone ?? "",
    },
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="bg-grey300 flex items-center px-150 py-20 mb-24">
      {isLoading ? (
        <Stack>
          <Skeleton height="100px" />
          <Skeleton height="120px" />
          <Skeleton height="140px" />
        </Stack>
      ) : (
        <form onSubmit={formik.handleSubmit} className="form w-full mt-24">
          <div className="form-row-full auth-form-group !mb-16">
            <label htmlFor="email">TIN</label>
            <input
              type="text"
              placeholder="TIN"
              name="tax_identification_number"
              id="tax_identification_number"
              {...formik.getFieldProps("tax_identification_number")}
            ></input>
            {formik.touched.tax_identification_number &&
            formik.errors.tax_identification_number ? (
              <div className="error">
                {formik.errors.tax_identification_number}
              </div>
            ) : null}
          </div>

          <div className="form-row-half auth-form-group !mb-16">
            <label htmlFor="email">License</label>
            <input
              type="text"
              placeholder="License"
              name="license"
              id="license"
              {...formik.getFieldProps("license")}
            ></input>
            {formik.touched.license && formik.errors.license ? (
              <div className="error">{formik.errors.license}</div>
            ) : null}
          </div>

          <div className="form-row-half auth-form-group !mb-16">
            <label htmlFor="email">License Category</label>
            <Select
              placeholder="Select option"
              variant="filled"
              bg="#EDEDED"
              borderColor="transparent"
              borderRadius="0"
              color="#424242"
              height="60px"
              {...formik.getFieldProps("license_category")}
            >
              <option value="Payment">Payment</option>
              <option value="Credit">Credit</option>
            </Select>
            {formik.touched.license_category &&
            formik.errors.license_category ? (
              <div className="error">{formik.errors.license_category}</div>
            ) : null}
          </div>

          <div className="form-row-half auth-form-group !mb-16">
            <label htmlFor="email">Cac Number</label>

            <NumberInput>
              <NumberInputField
                variant="filled"
                bg="#EDEDED"
                borderColor="transparent"
                borderRadius="0"
                color="#424242"
                placeholder="CAC Number"
                height="60px"
                {...formik.getFieldProps("cac_number")}
                // onChange={() => {
                //   formik.setFieldTouched("cac_number", true);
                // }}
              />
            </NumberInput>

            {formik.touched.cac_number && formik.errors.cac_number ? (
              <div className="error">{formik.errors.cac_number}</div>
            ) : null}
          </div>

          <div className="auth-form-group basis-[48%]">
            <div className="flex flex-col w-full">
              <label for="lname" className="form-label">
                Phone Number
              </label>

              <PhoneInput
                countryCodeEditable={false}
                country={formik.values?.country}
                // disableDropdown={true}
                preferredCountries={["ng"]}
                onChange={(phone) => {
                  formik.setFieldValue("official_phone", phone);
                }}
              />

              {formik.errors.official_phone ? (
                <div className="text-danger mt-2 fs-12">
                  {formik.errors.official_phone}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-row-full auth-form-group !mb-16">
            <label htmlFor="email">Website URL</label>
            <input
              type="text"
              placeholder="Website"
              name="website_url"
              id="website_url"
              {...formik.getFieldProps("website_url")}
            ></input>
            {formik.touched.website_url && formik.errors.website_url ? (
              <div className="error">{formik.errors.website_url}</div>
            ) : null}
          </div>

          <div className="form-row-half flex my-12">
            <button
              type="button"
              onClick={() => {
                setStep(0);
              }}
              className="btn btn-grey w-full"
            >
              Back
            </button>
          </div>

          <div className="form-row-half flex my-12">
            <button type="submit" className="btn btn-primary w-full">
              {updating ? <Spinner color={Colors.white} /> : "Update"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default StepOne;

const UserSchema = Yup.object().shape({
  tax_identification_number: Yup.string().required("TIN is required"),
  license: Yup.string().required("License is required"),
  license_category: Yup.string().required("License Category is required"),
  website_url: Yup.string()
    .required("Website URL is required")
    .url("Invalid URL. Please enter a valid link."),
  cac_number: Yup.string().required("CAC Number is required"),
  official_phone: Yup.string().required("Phone number is required"),
});
