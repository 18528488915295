import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import { useState } from "react";
import TableContainer from "components/TableContainer";
import {
  useDisclosure,
  Stack,
  HStack,
  Switch,
  Skeleton,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
} from "@chakra-ui/react";
import AddBlacklist from "./componenents/AddSweetAccount";
import {
  useGetSweepSettingsQuery,
  useGetSweepTransactionsQuery,
} from "store/services/business";
import LoanCard from "components/LoanCard";
import TransactionsTable from "components/tables/TransactionsTable";
import { amountConverter } from "utils/strings";
import { ReactComponent as AutoIcon } from "icons/auto.svg";
import { ReactComponent as DurationIcon } from "icons/duration.svg";

const DashboardSweep = () => {
  const [skip] = useState(false);
  const [filter] = useState(`page=1`);

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetSweepTransactionsQuery(filter, {
    skip: skip,
  });

  const { data: sweepSettings, isLoading: fetchingSeetings } =
    useGetSweepSettingsQuery();

  const { isOpen, onOpen, onClose } = useDisclosure();



  const details = sweepSettings?.data ?? null;

  return (
    <>
      <DashboardLayout>
        <section className="mx-flush">
          <h2 className="font-inter font-medium text-black text-2xl">
            Automated Sweep
          </h2>

          {fetchingSeetings ? (
            <section className="grid grid-cols-3 gap-16 my-16 sweep-container">
              <Spinner size="lg" />
            </section>
          ) : (
            <section className="grid grid-cols-3 gap-16 my-16 sweep-container">
              <section className="sweep__card">
                <HStack
                  h="60%"
                  bg="red"
                  justifyContent="space-between"
                  padding="19px"
                >
                  <Stack gap="0">
                    <p className="font-medium font-inter text-lg">
                      {" "}
                      Auto sweep account
                    </p>
                    <h3 className="font-normal font-inter text-12">
                      {details?.bank_account?.bank}
                    </h3>
                  </Stack>

                  <Popover>
                    <PopoverTrigger>
                      <button className="w-48 h-48 bg-white bg-opacity-20">
                        ....
                      </button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>
                        <h3 className="font-inter font-medium text-black text-sm">
                          Add Bank Account
                        </h3>
                      </PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Button onClick={() => onOpen()} colorScheme="green">
                          Add Account
                        </Button>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </HStack>
                <HStack
                  bg="black"
                  justifyContent="space-between"
                  padding="19px"
                  h="40%"
                >
                  <Stack gap="0">
                    <p className="font-normal font-inter text-12">
                      {" "}
                      Beneficiary
                    </p>
                    <h6 className="font-normal font-inter text-sm">
                      {details?.bank_account?.bank}
                    </h6>
                  </Stack>
                  <Stack gap="0" alignItems="flex-end">
                    <p className="font-normal font-inter text-12">
                      {details?.bank_account?.bank}
                    </p>
                    <h3 className="font-normal font-inter text-2xl">
                      {details?.bank_account?.account_number}
                    </h3>
                  </Stack>
                </HStack>
              </section>

              <LoanCard
                data={{
                  title: "All Loan sweep",
                  percent: "0",
                  showFilter: false,
                  number: `₦${amountConverter(
                    sweepSettings?.data?.total_sweep_amount ?? 0
                  )}`,
                }}
              />

              <section className="sweep__settings">
                <h5 className="mb-18">Settings</h5>
                <Stack gap="0" mb="1rem">
                  <HStack gap="0">
                    <figure className="bg-pink100 w-48 h-48 rounded-4 mr-4 flex items-center justify-center">
                      <AutoIcon />
                    </figure>
                    <Stack
                      w="100%"
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack gap="0">
                        <h6>Enable Auto Sweep</h6>
                        <p>Turn ON auto sweep</p>
                      </Stack>
                      <Stack>
                        <Switch
                          colorScheme="green"
                          defaultChecked={sweepSettings?.data?.auto_sweep}
                          onChange={(e) => {}}
                        />
                      </Stack>
                    </Stack>
                  </HStack>
                </Stack>
                <Stack gap="0">
                  <HStack gap="0">
                    <figure className="bg-pink100 w-48 h-48 rounded-4 mr-4 flex items-center justify-center">
                      <DurationIcon />
                    </figure>
                    <Stack
                      w="100%"
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack gap="0">
                        <h6>Sweep duration</h6>
                        <p>Sweep after 30 days</p>
                      </Stack>
                      <Stack>
                        <Switch />
                      </Stack>
                    </Stack>
                  </HStack>
                </Stack>
              </section>
            </section>
          )}

          <section className="grid grid-cols-1 gap-16 my-24">
            {fetching || isFetching ? (
              <Stack>
                <Skeleton height="40px" />
                <Skeleton height="80px" />
                <Skeleton height="140px" />
              </Stack>
            ) : (
              <TableContainer
                title="Sweep transaction"
                count={data?.results?.length}
                handleRoute={() => {}}
                showBadge={true}
                minH={true}
                showNew={false}
              >
                {data?.results?.length > 0 ? (
                  <TransactionsTable transactions={data?.results} />
                ) : (
                  <section className="flex items-center justify-center min-h-300">
                    <EmptyState />
                  </section>
                )}
              </TableContainer>
            )}
          </section>
        </section>
      </DashboardLayout>
      <AddBlacklist isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

export default DashboardSweep;
