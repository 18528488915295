import { Select } from "@chakra-ui/react";
import {
  useAddBankAccountMutation,
  useValidateBankAccountMutation,
} from "store/services/business";
import { useToast, Spinner } from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import * as Yup from "yup";
import { useFormik } from "formik";
import { bankList, getBankByCode } from "utils/strings";
import { NumberInput, NumberInputField } from "@chakra-ui/react";

const StepTwo = ({ setStep }) => {
  const [addBankAccount, { isLoading: addingBankAccount }] =
    useAddBankAccountMutation();
  const [validateBankAccount, { isLoading: validatingBankAccount }] =
    useValidateBankAccountMutation();
  const toast = useToast();

  const handleSubmit = async (values, { resetForm }) => {
    await addBankAccount({
      ...values,
      bank: getBankByCode(values?.bank_code),
    })
      .unwrap()
      .then((data) => {
        toast({
          title: "Success",
          description: "Bank account added",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        resetForm();
        setStep(0);
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const validate = async () => {
    await validateBankAccount({
      bank_code: formik.values?.bank_code,
      bank: getBankByCode(formik.values?.bank_code),
      account_number: formik.values?.account_number?.trim(),
    })
      .unwrap()
      .then(async (data) => {
        toast({
          title: "Success",
          description: "Bank account validated",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        formik.setFieldValue("account_name", data?.data?.account_name);
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      bank_code: "",
      account_number: "",
      account_name: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const disabled =
    formik?.values?.bank_code?.length > 0 &&
    formik?.values?.account_number?.length > 0
      ? false
      : true;

  return (
    <div className="bg-grey300 flex items-center px-150 py-20 mb-24">
      <form
        onSubmit={formik.handleSubmit}
        className={`form w-full mt-24 ${addingBankAccount ? "disabled" : ""}`}
      >
        <div className="form-row-full auth-form-group !mb-16">
          <label htmlFor="email">Bank Name</label>

          <Select
            placeholder="Select option"
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps("bank_code")}
          >
            <option value="option1">Choose </option>
            {bankList?.map((_) => {
              return <option value={_?.bank_code}>{_?.bank}</option>;
            })}
          </Select>

          {formik.touched.bank_code && formik.errors.bank_code ? (
            <div className="error">{formik.errors.bank_code}</div>
          ) : null}
        </div>

        <div className="form-row-full auth-form-group !mb-16">
          <label htmlFor="email">Company Account Number</label>

          <NumberInput>
            <NumberInputField
              {...formik.getFieldProps("account_number")}
              variant="filled"
              bg="#EDEDED"
              borderColor="transparent"
              borderRadius="0"
              color="#424242"
              placeholder="Account Number"
              height="60px"
            />
          </NumberInput>

          {formik.touched.account_number && formik.errors.account_number ? (
            <div className="error">{formik.errors.account_number}</div>
          ) : null}
        </div>

        <div className="form-row-full auth-form-group !mb-16">
          <label htmlFor="email" className="!flex items-center">
            Account Name{" "}
            {validatingBankAccount && (
              <Spinner className="ml-8 mb-2" color={Colors.green} />
            )}
          </label>

          <input
            type="text"
            id="account_name"
            aria-describedby="account_name"
            placeholder={`${
              validatingBankAccount ? "Getting" : ""
            } Account name`}
            {...formik.getFieldProps("account_name")}
            className="h-60"
            disabled
          ></input>

          {formik.touched.account_name && formik.errors.account_name ? (
            <div className="error">{formik.errors.account_name}</div>
          ) : null}
        </div>

        <div className="form-row-half flex my-12">
          <button
            type="button"
            onClick={() => {
              setStep(1);
            }}
            className="btn btn-grey w-full"
          >
            Back
          </button>
        </div>
        <div className="form-row-half flex my-12">
          {formik.values?.account_name?.length > 0 ? (
            <button type="submit" className={`btn btn-primary w-full`}>
              {addingBankAccount ? (
                <Spinner color={Colors.white} />
              ) : (
                "Continue"
              )}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => validate()}
              className={`btn btn-primary ${
                disabled ? "btn-disabled" : ""
              } w-full`}
            >
              {validatingBankAccount ? "Validating  Account..." : "Validate"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default StepTwo;

const LoginSchema = Yup.object().shape({
  bank_code: Yup.string().required("Bank is required"),
  account_number: Yup.string().required("Account Number is required"),
});
