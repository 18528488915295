/* eslint-disable no-unused-vars */
import AuthLayout from "layouts/auth";
import { useState } from "react";
import { ReactComponent as MarkIcon } from "icons/mark.svg";
import { ReactComponent as UnMarkIcon } from "icons/unmark.svg";
import { Path } from "utils/constant.data";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useSaveUserPackageMutation } from "store/services/authAPI";
import { useToast, Spinner, HStack } from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { login } from "store/reducers/AuthReducer";
import { riskOnly, riskLoan, Sectors } from "utils/strings";

const SetPackage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // window.localStorage.getItem(packages);
  // const [packages, sePackages] = useState([]);
  const [packages, sePackages] = useState([
    {
      id: "5d6922d9-2f61-4208-b965-e6fd8fedb33d",
      updated_at: "2024-08-14T04:28:49.060554Z",
      created_at: "2024-08-14T04:28:49.060588Z",
      name: "Risk analysis",
      description: "Risk analysis",
    },
    {
      id: "b96d9868-2894-41f9-9dae-980f2485bedf",
      updated_at: "2024-08-14T04:28:49.067128Z",
      created_at: "2024-08-14T04:28:49.067171Z",
      name: "All (Risk analysis + Loan collection)",
      description: "All (Risk analysis + Loan collection)",
    },
  ]);
  const [chosen, setChosen] = useState(null);
  const toast = useToast();

  const [saveUserPackage, { isLoading: settingPackage }] =
  useSaveUserPackageMutation();

  const handlePackage = async () => {
    window.localStorage.removeItem("UTMAT");
    window.localStorage.removeItem("UTMUSER");

    await saveUserPackage({
      package_id: chosen,
    })
      .unwrap()
      .then((data) => {
        toast({
          title: data?.message,
          description: data?.detail,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        // dispatch(
        //   login({
        //     ...data?.data,
        //     source: "register",
        //   })
        // );
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <AuthLayout>
      <section className="w-full flex justify-center  flex-col h-full p-160">
        <h1 className="text-h2 font-urbanist font-bold  text-dark100 mb-2">
          Select a package
        </h1>
        <h1 className="text-p text-[#888888] font-graphik">
          Choose the package that best suits your needs
        </h1>

        <section className="flex mt-24 justify-between">
          {packages &&
            packages?.map((_) => {
              return (
                <div key={_?.id} className="package__card">
                  <div className="h-50">
                    <h5
                      className={
                        chosen === _?.id
                          ? "font-bold !text-primary"
                          : "font-semibold text-[#010E0E]"
                      }
                    >
                      {_?.name}
                    </h5>
                  </div>

                  {_?.id === "5d6922d9-2f61-4208-b965-e6fd8fedb33d"
                    ? riskOnly.map((item) => {
                        return (
                          <div key={item?.title} className="flex items-center">
                            {item.mark ? <MarkIcon /> : <UnMarkIcon />}
                            <p>{item?.title}</p>
                          </div>
                        );
                      })
                    : riskLoan.map((_item) => {
                        return (
                          <div key={_item?.title} className="flex items-center">
                            {_item.mark ? <MarkIcon /> : <UnMarkIcon />}
                            <p>{_item?.title}</p>
                          </div>
                        );
                      })}

                  <button
                    type="button"
                    onClick={() => {
                      setChosen(_?.id);
                    }}
                    className={`btn btn-primary  w-full mt-24 ${
                      chosen === _?.id ? "btn-disabled" : ""
                    } `}
                  >
                    Select
                  </button>
                </div>
              );
            })}
        </section>

        <div className="flex my-32">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handlePackage();
            }}
            className={`btn btn-primary ${chosen ? "" : "btn-disabled"} w-full`}
          >
            {settingPackage ? <Spinner color={Colors.white} /> : " Continue"}
          </button>
        </div>
      </section>
    </AuthLayout>
  );
};

export default SetPackage;

// const [packages, sePackages] = useState([
//   {
//     id: "5d6922d9-2f61-4208-b965-e6fd8fedb33d",
//     updated_at: "2024-08-14T04:28:49.060554Z",
//     created_at: "2024-08-14T04:28:49.060588Z",
//     name: "Risk analysis",
//     description: "Risk analysis",
//   },
//   {
//     id: "b96d9868-2894-41f9-9dae-980f2485bedf",
//     updated_at: "2024-08-14T04:28:49.067128Z",
//     created_at: "2024-08-14T04:28:49.067171Z",
//     name: "All (Risk analysis + Loan collection)",
//     description: "All (Risk analysis + Loan collection)",
//   },
// ]);
