import "react-multi-carousel/lib/styles.css";
import AuthCarousel from "./components/authCarousel";

const AuthLayout = ({ children }) => {
 

  return (
    <section className="flex min-h-screen">
      <section className="basis-1/2 bg-dark100 flex-shrink-0 overflow-hidden">
        <AuthCarousel />
      </section>
      <section className="basis-1/2 flex-shrink-0 overflow-hidden">
        {children}
      </section>
    </section>
  );
};

export default AuthLayout;
