import {
  Stack,
  HStack,
  VStack,
  Switch,
  Skeleton,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import DashboardLayout from "layouts/dashboard";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { ReactComponent as ArrowRight } from "icons/arrowright.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "utils/constant.data";
import { useState } from "react";
import {
  useGetSingleBeneficiaryQuery,
  useInitiateMandateMutation,
} from "store/services/loan";
import { capitalize, amountConverter } from "utils/strings";
import { Colors } from "utils/constant.data";
import { format } from "date-fns";
import AddLoanModal from "./componenents/AddLoan";

const LoanDetails = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [fetch] = useState(false);
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading } = useGetSingleBeneficiaryQuery(urlParams?.loanId, {
    skip: fetch,
  });

  const [initiateMandate, { isLoading: creating }] =
    useInitiateMandateMutation();

  const loanDetails = data?.data ?? null;

  // console.log(" loanDetails", loanDetails?.id);

  const name =
    `${capitalize(loanDetails?.first_name)} ${capitalize(
      loanDetails?.last_name
    )}` ?? "";

  const details = [
    {
      label: "First Name",
      value: loanDetails?.first_name,
    },
    {
      label: "Last Name",
      value: loanDetails?.last_name,
    },

    {
      label: "Gender",
      value: loanDetails?.gender ?? "Male",
    },
    {
      label: "Date of Birth",
      value: loanDetails?.dob,
    },
    {
      label: "BVN",
      value: loanDetails?.bvn,
    },
    {
      label: "Status",
      value: loanDetails?.is_active ? "Active" : "Not Active",
      badge: true,
    },
    {
      label: "Created At",
      value: loanDetails?.created_at
        ? format(loanDetails?.created_at, "yyyy-MM-dd")
        : "Null",
    },
  ];

  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">
          Loan Beneficiary
        </h2>

        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="24px"
          marginTop="24px"
        >
          <Stack w="75%" bg="white" padding="28px">
            {isLoading ? (
              <Stack w="100%" minH="20rem">
                <Skeleton height="120px" />
                <Skeleton height="130px" />
                <Skeleton height="140px" />
              </Stack>
            ) : (
              <Stack w="100%" minH="20rem">
                <Stack>
                  <button
                    onClick={() => navigate(Path.DASHBOARD_LOAN_BENEFICIARY)}
                    className="w-250 flex items-center  text-grey700"
                  >
                    {" "}
                    <BackIcon />
                    <span className="ml-8">Back to Overview</span>
                  </button>
                  <h2 className="font-inter font-semibold text-h6">
                    {`${name}`}'s Profile
                  </h2>
                </Stack>

                <section className="grid grid-cols-3 gap-16 my-16">
                  <Stack>
                    <figure className="w-78 h-78 rounded-full bg-slate-500 mb-10"></figure>
                    <h5 className="font-inter font-semibold text-h6 text-blue200">
                      {name}
                    </h5>
                    <h6 className="font-inter font-semibold text-h6 text-[#425466]">
                      <span className="text-[#75818D]">User ID:</span> #
                      {loanDetails?.merchant?.substring(0, 6)}
                    </h6>
                    <button
                      onClick={() => {
                        onOpen();
                      }}
                      className={`btn btn-primary !w-170 mt-8 flex items-center justify-center ${loanDetails?.loans?.length > 0 ? "" : "btn-disabled"
                        }  `}
                    >
                      Add Loan
                    </button>
                  </Stack>
                  <Stack bg="#FBFBFD" padding="16px">
                    <HStack>
                      <VStack alignItems="flex-start" gap="0">
                        <h5 className="text-dark500 font-normal font-inter  text-12">
                          Number of Loans
                        </h5>
                        <h4 className="text-dark500 font-bold text-h4">
                          {/* {getRandomCharacters(loanDetails?.id)} */}

                          {loanDetails?.loans?.length}
                        </h4>
                      </VStack>
                      <VStack alignItems="flex-start" gap="0" marginLeft="30px">
                        <h5 className="text-dark500 font-normal font-inter  text-12">
                          Pending
                        </h5>
                        <h4 className="text-dark500 font-bold text-h4">
                          {loanDetails?.loans?.filter(
                            (_) => _?.status.toLowerCase() === "pending"
                          )?.length ?? 0}
                        </h4>
                      </VStack>
                    </HStack>
                    <button
                      onClick={() => {
                        navigate(`${Path.LOAN_LIST}/${loanDetails?.id}`, {
                          state: {
                            loanDetails,
                          },
                        });
                      }}
                      className={`btn btn-primary !w-170 mt-8 flex items-center justify-center ${loanDetails?.loans?.length > 0 ? "" : "btn-disabled"
                        }  `}
                    >
                      View Loans <ArrowRight />
                    </button>
                  </Stack>
                  <Stack bg="#FBFBFD" padding="16px" gap="0">
                    <Stack gap="0" mb="10px">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Total Loan
                      </h5>
                      <h5 className="text-dark500 font-bold text-h4">
                        ₦{amountConverter(loanDetails?.total_loan_amount)}
                      </h5>
                    </Stack>
                    <Stack gap="0">
                      <h5 className="text-dark500 font-normal font-inter  text-12">
                        Total Paid
                      </h5>

                      <h5 className="text-dark500 font-bold text-h4">
                        ₦{amountConverter(loanDetails?.total_paid_amount)}
                      </h5>
                    </Stack>
                  </Stack>
                </section>
                <Stack mt="24px">
                  {details?.map((_) => {
                    return (
                      <div className="flex flex-row justify-between items-center border-b pt-4 pb-12 text-grey950">
                        <h6 className="font-inter font-normal text-sm text-grey950">
                          {_?.label}
                        </h6>
                        <h6 className="font-inter font-normal text-sm text-[#181830]">
                          {_?.value}
                        </h6>
                      </div>
                    );
                  })}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack w="25%" minH="40rem">
            {isLoading ? (
              <Stack w="100%" minH="20rem">
                <Skeleton height="20px" />
                <Skeleton height="30px" />
                <Skeleton height="40px" />
              </Stack>
            ) : (
              <Stack w="100%">
                <h4 className="font-inter font-medium text-black text-lg">
                  Beneficiary Linked Accounts
                </h4>
                {loanDetails?.mandates?.length > 0 ? (
                  loanDetails?.mandates?.map((_) => {
                    return (
                      <Stack
                        bg="#826AF9"
                        p="12px"
                        textColor="white"
                        key={_?.id}
                      >
                        <div className="flex justify-between">
                          <div className="flex">
                            <figure className="w-45 h-45 rounded-8 bg-gray-500 mr-12"></figure>
                            <div className="flex flex-col">
                              <h6 className="font-normal text-12 font-inter">
                                Beneficiary
                              </h6>
                              <h5 className="font-semibold text-sm font-inter">
                                {_?.bankname}
                              </h5>
                            </div>
                          </div>
                          <div className="flex flex-col  items-end">
                            <h6 className="font-semibold text-sm font-inter mb-4">
                              Direct Debit
                            </h6>
                            <div className="flex items-center">
                              <p className="mr-4 font-normal text-12 font-inter">
                                {_?.is_active ? "ON" : "OFF"}
                              </p>
                              <Switch size="sm" />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <h6 className="font-normal text-12 font-inter ">
                            Account Number
                          </h6>
                          <h5 className="font-bold text-2xl font-urbanist">
                            {_?.accountnumber}
                          </h5>
                        </div>
                      </Stack>
                    );
                  })
                ) : (
                  <Stack pt="12px" textColor="white">
                    <button
                      onClick={async () => {
                        await initiateMandate({
                          id: loanDetails?.id,
                        })
                          .unwrap()
                          .then((data) => {
                            toast({
                              title: data?.message,
                              description: data?.detail,
                              status: "success",
                              duration: 9000,
                              isClosable: true,
                              position: "top",
                            });
                            window.open(data?.data?.url, "_blank");
                          })
                          .catch((error) => {
                            toast({
                              title: "Error Occured",
                              description: error?.data?.detail,
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                              position: "top",
                            });
                          });
                      }}
                      className="btn btn-primary"
                      disabled={creating ? true : false}
                    >
                      {creating ? (
                        <Spinner color={Colors.white} />
                      ) : (
                        "Create Mandate"
                      )}
                    </button>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </section>

      <AddLoanModal isOpen={isOpen} onClose={onClose} id={loanDetails?.id} />
    </DashboardLayout>
  );
};

export default LoanDetails;
