import DashboardLayout from "layouts/dashboard";
import EmptyState from "components/EmptyState";
import TableContainer from "components/TableContainer";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Badge,
  HStack,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { ReactComponent as CheckIcon } from "icons/check.svg";

const DashboardAuditLogs = () => {
  return (
    <DashboardLayout>
      <section className="mx-flush">
        <h2 className="font-inter font-medium text-black text-2xl">
          Audit Logs
        </h2>
        <section className="grid grid-cols-3 gap-16 my-16"></section>

        <Stack direction="row" gap="32px" >
          <Stack width="70%">
            <TableContainer title="User Activities" showBadge={false}>
              {!true ? (
                <section className="flex items-center justify-center min-h-300">
                  <EmptyState />
                </section>
              ) : (
                <Table variant="simple" bgColor={Colors.white} mt="2rem">
                  <Thead className="dashboard__table__header">
                    <Tr>
                      <Th>Transaction ID</Th>
                      <Th>Email address</Th>
                      <Th>Amount</Th>
                      <Th>Status</Th>
                      <Th>Date of Entry</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="dashboard__table__body">
                    <Tr>
                      <Td width="15%">
                        <Stack direction="row" alignItems="center">
                          <h6 className="!font-bold">#3066</h6>
                        </Stack>
                      </Td>
                      <Td>
                        {" "}
                        <h6> olivia@untitledui.com</h6>
                      </Td>
                      <Td>
                        <h6>₦45,000.00</h6>
                      </Td>
                      <Td>
                        <Badge
                          textColor="#027A48"
                          backgroundColor="#ECFDF3"
                          borderRadius="16px"
                          p="p-8"
                        >
                          <div className="flex items-center">
                            <CheckIcon /> <span className="ml-6">Paid</span>
                          </div>
                        </Badge>
                      </Td>
                      <Td>
                        <h6>Jan 6, 2023</h6>
                      </Td>
                      <Td>
                        <button
                          onClick={() => {}}
                          className="cursor-pointer text-primary font-bold"
                        >
                          View
                        </button>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              )}
            </TableContainer>
          </Stack>

          <Stack backgroundColor="white" width="30%" padding="18px">
            <h4>User Account Entries</h4>
            <Stack>
              <HStack alignItems="center">
                <HStack>
                  <figure></figure>
                  <Stack>
                    <h6> Olivia Rhye </h6>
                    <p>6 hours ago</p>
                  </Stack>
                </HStack>
                <Badge>Admin</Badge>
              </HStack>
            </Stack>
          </Stack>
        </Stack>
      </section>
    </DashboardLayout>
  );
};

export default DashboardAuditLogs;
