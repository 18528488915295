import { Stack, HStack, Text } from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import { ReactComponent as TickCircle } from "icons/tick-circle.svg";
import { ReactComponent as TickInfo } from "icons/tick-info.svg";

const CreditCheckTabOne = ({ checkData }) => {
  const {
    response_data: {
      ai_analysis: {
        info: {
          analysis: {
            points: analysisPoints = [],
            description: analysisDescription = "",
          } = {},
          advice: {
            description: adviceDescription = "",
            points: advicePoints = [],
          } = {},
        } = {},
        will_default: willDefault,
        description,
        confidence,
      } = {},
    } = {},
  } = checkData ?? {};

  return (
    <Stack direction="row" justifyContent="space-between" mt="4">
      <div className="basis-1/2">
        <Stack>
          <Stack
            bgColor="white"
            border={`1px solid ${Colors.grey650}`}
            rounded="8"
            py="8"
            px="8"
            justifyContent="center"
            alignItems="center"
            h="400px"
          >
            <Text className="font-medium text-xl font-inter">CREDIT SCORE</Text>
            <div className="relative mb-8">
              <div class="half-circle relative">
                <Stack
                  className="absolute left-0 bottom-0  w-full flex justify-center items-center"
                  gap="0"
                >
                  <Text className="font-bold text-[40px] text-black -mb-10">
                    {checkData?.response_data?.credit_score?.score}
                  </Text>
                  <Text className="font-bold text-sm text-[#667085]">
                    /  {checkData?.response_data?.credit_score?.max_score}
                  </Text>
                </Stack>
              </div>
            </div>

            <Text
              align="center"
              className="font-inter font-normal text-sm  text-grey800 text-center"
            >
              This score provides valuable insights into user credit health,
              aiding in your financial decisions.
            </Text>
          </Stack>
          <Stack mt="4" gap="0">
            <Text className="text-sm text-[#1E1E1E] font-medium">
              Analysis Breakdown
            </Text>
            <Text className="text-12 text-[#667085]">
              {analysisDescription}
            </Text>
            <Stack mt="2">
              {analysisPoints?.map((_) => {
                return (
                  <HStack alignItems="center" kry={_}>
                    <figure className="flex items-center justify-center border-1 bg-grey-500 w-120 h-44 rounded-4 mr-4">
                      <TickCircle className="w-24 h-24" />
                    </figure>
                    <Text className="text-sm text-[#667085]">{_}</Text>
                  </HStack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </div>
      <div className="basis-1/2">
        <Stack>
          <Stack
            bgColor="white"
            border={`1px solid ${Colors.grey650}`}
            rounded="8"
            py="8"
            px="8"
            justifyContent="center"
            alignItems="center"
            h="400px"
          >
            <Text className="font-medium text-xl font-inter mb-16">
              CREDIT ANALYSIS
            </Text>
            <div
              className={`w-165 h-165 rounded-full bg-white flex items-center justify-center border-20  ${
                confidence > 0.49 ? "border-red-500" : "border-primary"
              } mb-5`}
            >
              <p className="font-700 text-4xl">{confidence * 100}%</p>
            </div>
            <p className="font-inter font-normal text-sm  text-grey800 text-center">
              Will default?{" "}
              <span
                className={`${
                  willDefault ? "text-red-500" : "text-primary"
                }  font-bold`}
              >
                Yes
              </span>
            </p>
            <p className="font-inter font-normal text-sm  text-grey800 text-center">
              {description}
            </p>
          </Stack>
          <Stack mt="4" gap="0">
            <Text className="text-sm text-[#1E1E1E] font-medium">
              Advice & Recommendation
            </Text>
            <Text className="text-12 text-[#667085]">{adviceDescription}:</Text>
            <Stack mt="2">
              {advicePoints?.map((_) => {
                return (
                  <HStack key={_} alignItems="center">
                    <figure className="flex items-center justify-center border-1 bg-grey-500 w-120 h-44 rounded-4 mr-4">
                      <TickInfo className="w-24 h-24" />
                    </figure>
                    <Text className="text-sm text-[#667085]">{_}</Text>
                  </HStack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
};

export default CreditCheckTabOne;
