// import { ReactComponent as FilterIcon } from "icons/filter.svg";
// import { ReactComponent as FindIcon } from "icons/find.svg";

const TableCard = ({ title, children }) => {
  return (
    <section className="table__card">
      <div className="flex items-center justify-between mb-17">
        <h5 className="!basis-[40%] flex-shrink-0 ">{title}</h5>

        <div className="basis-1/2 flex-shrink-0 flex items-center justify-end">
          {/* <div className="search__content">
            <FindIcon className="mr-12" />
            <input
              type="text"
              id="User"
              placeholder="Search"
              name="user"
            ></input>
          </div>
          <button className="btn btn-grey !text-dark200 flex items-center !px-22">
            <FilterIcon className="mr-16" />
            Filter
          </button> */}
        </div>
      </div>
      {children}
    </section>
  );
};

export default TableCard;
