import { Select, Stack } from "@chakra-ui/react";
import { ReactComponent as TrendIcon } from "icons/trend.svg";

const LoanCard = ({ data }) => {
  return (
    <section className="loan__card">
      <div className="flex items-center justify-between mb-17">
        <h5>{data?.title}</h5>
        {data?.showFilter && (
          <div className=" basis-[30%] flex-shrink-0 bg-transparent">
            <Select
              placeholder="Select option"
              variant="filled"
              bg="#EDEDED"
              borderColor="transparent"
              borderRadius="0"
              color="#424242"
              height="60px"
            >
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
          </div>
        )}
      </div>
      <Stack>
        <h2>{data?.number}</h2>
        <p>
          <TrendIcon className="-ml-4 mr-4" />
          <span>{data?.percent}%</span>
        </p>
      </Stack>
    </section>
  );
};

export default LoanCard;
