import Chart from "react-apexcharts";
import { amountConverter } from "utils/strings";

const GraphCard = ({
  title = "Outstanding Loans",
  type = "line",
  graphData,
}) => {
  // console.log("graphData?",title, graphData?.chart_data?.data);

  const data = graphData?.chart_data?.data
    ? {
        options: {
          chart: {
            id: "basic-bar",
          },
          xaxis: {
            categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
          },
          // yaxis: {
          //   categories: [1, 2,4],
          // },
          colors: ["#826AF9", "#FFE700", "#FF6C40", "#E91E63", "#FF9800"],
          stroke: {
            curve: "smooth",
            width: 2,
          },
          // annotations: {
          //   yaxis: [
          //     {
          //       y: 8800,
          //       borderColor: "#00E396",
          //       label: {
          //         borderColor: "#00E396",
          //         style: {
          //           color: "#fff",
          //           background: "#00E396",
          //         },
          //         text: "Y-axis annotation on 8800",
          //       },
          //     },
          //   ],
          // },
        },
        series: [
          {
            name: title,
            data: [...graphData?.chart_data?.data],
          },
          // {
          //   name: "series-3",
          //   data: [0, 300, 400, 405, 508, 489, 609, 770, 981],
          // },
          // {
          //   name: "",
          //   data: [0, 305, 590, 940, 367, 429, 616, 710, 851],
          // },
        ],
      }
    : null;

  var bar = graphData?.chart_data?.data
    ? {
        series: [
          {
            name: "Inflation",
            data: type === "bar" ? [...graphData?.chart_data?.data] : [],
            // data:  [],
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
          },
          plotOptions: {
            bar: {
              borderRadius: 12,
              dataLabels: {
                position: "bottom",
              },

              // borderColor: "#775DD0",
              // offsetY: 10,
              // style: {
              //   color: "#fff",
              //   background: "#775DD0",
              // },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            position: "top",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
          title: {
            text: "Monthly Inflation in Argentina, 2002",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444",
            },
          },
        },
      }
    : null;

  return (
    graphData && (
      <section className="graph__card">
        <div className="flex items-center justify-between mb-17">
          <h5>{title}</h5>
          <h4>₦{amountConverter(graphData?.total_amount)}</h4>
        </div>
        <div className="overflow-hidden h-full w-full">
          {type === "bar" && (
            <Chart
              options={bar?.options}
              series={bar?.series}
              annotations={data?.annotations}
              type="bar"
              width="500"
            />
          )}

          {type === "line" && (
            <Chart
              options={data?.options}
              series={data?.series}
              type="line"
              width="500"
            />
          )}
        </div>
      </section>
    )
  );
};

export default GraphCard;
