import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Select,
  useToast,
  Textarea,
  Input,
} from "@chakra-ui/react";
import { Colors } from "utils/constant.data";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ReactComponent as EyeIcon } from "icons/eye.svg";
import DatePicker from "react-datepicker";
import { Plans, LoanTerms } from "utils/strings";
import { useCreateLoanMutation } from "store/services/loan";
import { format } from "date-fns";

const AddLoanModal = ({ isOpen, onClose, id }) => {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px)" />
  );

  function formatNumberWithCommas(value) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const toast = useToast();

  const BenefeciaryForm = () => {
    const [chosenDate, setChosenDate] = useState({
      start_date: null,
      end_date: null,
    });
    const [loanAmount, setLoanAmount] = useState("");
    const [interestRate, setInterestRate] = useState("");
    const [totalAmount, setTotalAmount] = useState("");
    const [createBenefeciary, { isLoading: creating }] = useCreateLoanMutation();

    const handleSubmit = async (values, { resetForm }) => {
      await createBenefeciary({
        ...values,
        id,
        start_date: format(values?.start_date, "yyyy-MM-dd"),
        end_date: format(values?.end_date, "yyyy-MM-dd"),
        plan: values?.plan?.toLowerCase(),
        interest_rate: interestRate,
        additional_info: {},
      })
        .unwrap()
        .then((data) => {
          toast({
            title: data?.message,
            description: data?.detail,
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          resetForm();
          onClose();
          window.location.reload();
        })
        .catch((error) => {
          toast({
            title: "Error Occurred",
            description: error?.data?.detail,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        });
    };
    useEffect(() => {
      const loanAmountValue = parseFloat(loanAmount.replace(/,/g, ""));
      if (!isNaN(loanAmountValue) && loanAmountValue > 0) {
        const calculatedAmount = loanAmountValue + (loanAmountValue * interestRate) / 100;
        setTotalAmount(formatNumberWithCommas(calculatedAmount.toFixed(2)));
      } else {
        setTotalAmount("0");
      }
    }, [loanAmount, interestRate]);

    const formik = useFormik({
      initialValues: {
        start_date: chosenDate.start_date,
        end_date: chosenDate.end_date,
        loan_amount: formatNumberWithCommas(loanAmount),
        plan: "",
        loan_term: "",
        loan_purpose: "",
        additional_info: {},
        interest_rate: interestRate.toString(),
      },
      validationSchema: LoanSchema,
      onSubmit: handleSubmit,
      enableReinitialize: true,
    });
    const handleDateChange = (date, field) => {
      const formattedDate = format(date, "yyyy-MM-dd");
      setChosenDate((prev) => ({ ...prev, [field]: date }));
      formik.setFieldValue(field, formattedDate);
      formik.setFieldTouched(field, true);
    };
    return (
      <form onSubmit={formik.handleSubmit} className="form !w-full mt-24">
        {/* loan start */}
        <div className="auth-form-group basis-[48%]">
          <label htmlFor="lname" className="form-label">
            Loan Start Date
          </label>
          <DatePicker
            selected={chosenDate?.start_date}
            dateFormat="dd/MM/yyyy"
            icon={<EyeIcon />}
            showYearDropdown
            scrollableYearDropdown
            yearItemNumber={100}
            showMonthDropdown
            onChange={(date) => handleDateChange(date, "start_date")}
          />
          {formik.touched.start_date && formik.errors.start_date ? (
            <div className="error">{formik.errors.start_date}</div>
          ) : null}
        </div>
        {/* loan end */}
        <div className="auth-form-group basis-[48%]">
          <label htmlFor="lname" className="form-label">
            Loan End Date
          </label>
          <DatePicker
            selected={chosenDate?.end_date}
            dateFormat="dd/MM/yyyy"
            icon={<EyeIcon />}
            showYearDropdown
            showMonthDropdown
            onChange={(date) => handleDateChange(date, "end_date")}
          />
          {formik.touched.end_date && formik.errors.end_date ? (
            <div className="error">{formik.errors.end_date}</div>
          ) : null}
        </div>
        {/*loan amount */}
        <div className="form-row-half auth-form-group !mb-16">
          <label htmlFor="loan_amount">Loan Amount</label>
          <Input
            variant="filled"
            bg="#EDEDED"
            placeholder="Loan Amount"
            height="60px"
            value={loanAmount}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/,/g, "");
              if (!isNaN(inputValue)) {
                setLoanAmount(formatNumberWithCommas(inputValue));
                formik.setFieldValue("loan_amount", inputValue);
              }
              formik.setFieldTouched("loan_amount", true);
            }}
          />
          {formik.touched.loan_amount && formik.errors.loan_amount ? (
            <div className="error">{formik.errors.loan_amount}</div>
          ) : null}
        </div>
        {/* plan */}
        <div className="form-row-half auth-form-group !mb-16">
          <label htmlFor="email">Plan</label>
          <Select
            placeholder="Choose Plan"
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps("plan")}
          >
            {Plans?.map((_) => (
              <option key={_?.value} value={_?.value}>
                {_?.label}
              </option>
            ))}
          </Select>
          {formik.touched.plan && formik.errors.plan ? (
            <div className="error">{formik.errors.plan}</div>
          ) : null}
        </div>
        {/* loan term */}
        <div className="form-row-half auth-form-group !mb-16">
          <label htmlFor="loan_term">Loan Term</label>
          <Select
            placeholder="Choose Loan Term"
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps("loan_term")}
          >
            {LoanTerms.map((term) => (
              <option key={term.value} value={term.value}>{term.label}</option>
            ))}
          </Select>
          {formik.touched.loan_term && formik.errors.loan_term && (
            <div className="error">{formik.errors.loan_term}</div>
          )}
        </div>
        {/* interest rate */}

        <div className="form-row-half auth-form-group !mb-16">
          <label>Interest Rate (%)</label>
          <Input
            variant="filled"
            placeholder="Interest Rate"
            value={formik.values.interest_rate}
            onChange={(e) => {
              const rate = e.target.value;
              if (rate === "" || (!isNaN(rate) && Number(rate) > 0)) {
                setInterestRate(rate);
                formik.setFieldValue("interest_rate", rate);
              }
            }}
          />


          {formik.touched.interest_rate && formik.errors.interest_rate && (
            <div className="error">{formik.errors.interest_rate}</div>
          )}
        </div>

        {/* Total Amount */}
        <div className="form-row-half auth-form-group !mb-16">
          <label>Total Amount</label>
          <Input variant="filled" value={totalAmount || 0} isReadOnly />
        </div>

        <div className="form-row-full auth-form-group !mb-16">
          <label htmlFor="Loan Purpose">Loan Purpose</label>
          <Textarea
            resize="none"
            placeholder="Loan Purpose"
            variant="filled"
            bg="#EDEDED"
            borderColor="transparent"
            borderRadius="0"
            color="#424242"
            height="60px"
            {...formik.getFieldProps("loan_purpose")}
          />
          {formik.touched.loan_purpose && formik.errors.loan_purpose ? (
            <div className="error">{formik.errors.loan_purpose}</div>
          ) : null}
        </div>

        <div className="flex my-12 basis-[100%]">
          <button type="submit" className="btn btn-primary w-full">
            {creating ? <Spinner color={Colors.white} /> : "Add"}
          </button>
        </div>
      </form>
    );
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <OverlayOne />
      <ModalContent>
        <ModalHeader borderBottom="1px solid #E0E0E0" padding="0">
          <div className="flex flex-col py-22 px-30">
            <h3 className="font-inter font-medium text-black text-2xl">
              Add Loan
            </h3>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={4}>
          <BenefeciaryForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default AddLoanModal;


const LoanSchema = Yup.object().shape({
  start_date: Yup.string().required("Start Date is required"),
  end_date: Yup.string().required("End Date is required"),
  loan_amount: Yup.string().required("Loan Amount is required"),
  plan: Yup.string().required("Repayment Plan is required"),
  loan_term: Yup.string().required("Loan Term is required"),
  loan_purpose: Yup.string().required("Loan Purpose is required"),
  interest_rate: Yup.number()
    .required("Interest rate is required")
    .min(0.01, "Interest rate must be greater than zero"),
});
