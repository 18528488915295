import { Table, Thead, Tbody, Tr, Th, Td, Flex } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import EmptyState from "components/EmptyState";
import { formatString } from "utils/strings";
import { ReactComponent as ArrowDownIcon } from "icons/arrowDownMD.svg";
import { ReactComponent as Caret } from "icons/caret.svg";
import { ReactComponent as InfoIcon } from "icons/info-2.svg";
import { useState } from "react";

const CreditData = ({ credit }) => {
  const updateStateFromData = (data) => {
    const initialState = {};

    data &&
      data?.forEach((item) => {
        const key = Object.keys(item)[0];
        initialState[key] = false;
      });

    return initialState;
  };

  const [showObj, setShowObj] = useState(updateStateFromData(credit));

  return (
    <section className="flex flex-wrap justify-between">
      {/* <p className="mb-8"> ShowObj:: {JSON.stringify(showObj)}</p> */}
      {credit.map((section) => {
        const sectionHeading = Object.keys(section)[0];
        const data = section[sectionHeading];

        return (
          <Stack mb="26px" className="basis-[48%] flex-grow-0 flex-shrink-0">
            <Flex justifyContent="space-between">
              <h4 className="font-bold flex items-center ">
                {" "}
                <span className="mr-3">{formatString(sectionHeading)}</span>
                <InfoIcon />
              </h4>
              <button>
                {showObj[sectionHeading] ? (
                  <ArrowDownIcon
                    onClick={() => {
                      setShowObj({
                        ...showObj,
                        [sectionHeading]: false,
                      });
                    }}
                    className="m-10"
                  />
                ) : (
                  <Caret
                    onClick={() => {
                      setShowObj({
                        ...showObj,
                        [sectionHeading]: true,
                      });
                    }}
                    className="m-10"
                  />
                )}
              </button>
            </Flex>
            {showObj[sectionHeading] && (
              <Table
                className="credit__table"
                variant="simple"
                bgColor="#F9FAFB"
              >
                <Thead className="credit__table__header">
                  <Tr>
                    <Th>Key</Th>
                    <Th>Value</Th>
                  </Tr>
                </Thead>
                <Tbody className="credit__table__body">
                  {data?.length > 0 ? (
                    data?.map((_) => {
                      return Object.entries(_).map(([key, value]) => {
                        return (
                          <Tr>
                            <Td>
                              <h6>{formatString(key)}</h6>
                            </Td>
                            <Td>
                              <h6>{value ? value : "Null"}</h6>
                            </Td>
                          </Tr>
                        );
                      });
                    })
                  ) : (
                    <section className="flex items-center justify-center w-full min-h-300 translate-x-[20%]">
                      <EmptyState title="No Data" />
                    </section>
                  )}
                </Tbody>
              </Table>
            )}
          </Stack>
        );
      })}
    </section>
  );
};

export default CreditData;
