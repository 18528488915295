import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { ReactComponent as ImportIcon } from "icons/import.svg";
import { ReactComponent as CaretIcon } from "icons/down.svg";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as FileIcon } from "icons/file.svg";
import { Colors } from "utils/constant.data";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";

const StepThree = ({ setStep }) => {
  return (
    <div className="bg-grey300 flex items-center px-150 py-20 mb-24">
      <form className="form w-full mt-24">
        <div className="form-row-full flex my-24">
          <Accordion
            backgroundColor={Colors.blue200}
            color={Colors.white}
            border={0}
            borderRadius="12px"
            padding="20px"
            allowToggle
          >
            <AccordionItem border={0}>
              <h2>
                <AccordionButton border={0}>
                  <InfoIcon className="mr-8" />
                  <Box as="span" flex="1" textAlign="left">
                    View File Upload Requirement
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <section>
                  <p>
                    To successfully upload your data, please ensure that you
                    follow these guidelines:
                  </p>
                  <p>
                   <span className="font-bold">1. File Format:</span> We currently support the following file formats
                    for data upload: CSV, Excel (XLS, XLSX), JSON, XML, PDF,
                    DOC, and DOCX.
                  </p>
                  <p>
                  <span className="font-bold">2. Column Arrangements:</span> Please make sure that the columns in
                    your data file are arranged in the correct order and match
                    the required format. Here is an example of the expected
                    column arrangement: Column 1: CAC Number Column 2: Tax
                    Identification Number (TIN) Column 3: License Number Column
                    4: License Category Column 5: "Others"
                  </p>

                  <p>
                    Column Titles: Ensure that the column titles accurately
                    represent the data in each column. This will help us process
                    and map the data correctly. For example, the column titles
                    should be labeled as follows: Column 1: "CAC Number" Column
                    2: "Tax Identification Number (TIN)" Column 3: "License
                    Number" Column 4: "License Category" Column 5: "Others"
                  </p>
                </section>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>

        <div className="form-row-full flex my-24 items-center">
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={<ImportIcon />}
              rightIcon={<CaretIcon />}
              bg={Colors.grey500}
              textColor={Colors.primary}
            >
              Download File format
            </MenuButton>
            <MenuList>
              <MenuItem>CSV</MenuItem>
              <MenuItem>Excel</MenuItem>
              <MenuItem>XLS</MenuItem>
            </MenuList>
          </Menu>
        </div>

        <div className="form-row-full flex my-24">
          <div className="upload__area">
            <input type="file"></input>

            <div className="pointer-events-none absolute  w-full h-full left-0 top-0 flex flex-col items-center justify-center">
              <FileIcon />
              <p className="text-12 text-center font-inter w-[50%]">
                <span className="text-blue100 underline cursor-pointer font-bold">
                  Click to upload
                </span>{" "}
                or drag and drop CSV, XLS, XLSX, JSON, XML, PDF Max. 150 MB
              </p>
            </div>
          </div>
        </div>

        <div className="form-row-half flex my-12">
          <button
            type="button"
            onClick={() => {
              setStep(1);
            }}
            className="btn btn-grey w-full"
          >
            Back
          </button>
        </div>
        <div className="form-row-half flex my-12">
          <button
            type="button"
            onClick={() => {
              setStep(2);
            }}
            className="btn btn-primary w-full"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepThree;
